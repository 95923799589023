import {
  Card, Spacings
} from '@skf-internal/ui-components-react-legacy';
import FormAccountSettings from 'components/FormAccountSettings';
import SLoader from 'components/SLoader';
import TemplateSection from 'components/TemplateSection';
import TemplateView from 'components/TemplateView';
import {
  DEFAULT_EMAIL_FREQUENCY, emailFrequencyItems, languageItems
} from 'helpers/constants';
import { AuthShape } from 'helpers/propTypes';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from 'redux/modules/auth';
import * as intlActions from 'redux/modules/intl';
import { getCurrentLanguageCode } from 'storage/localStorage';

function mapState(state) {
  const { auth } = state;
  return {
    auth,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...authActions, ...intlActions }, dispatch)
  };
}

const AccountSettingsView = ({ actions, auth }) => {
  useEffect(() => {
    if (!auth.loaded) {
      actions.getAccount();
    }
  }, []);

  const handleSubmit = values => {
    return actions.savePreferences(values);
  };

  if (auth.loading) {
    return <SLoader />;
  }

  const initialState = {
    language: getCurrentLanguageCode(),
    emailFrequency: auth.emailFrequency || DEFAULT_EMAIL_FREQUENCY,
  };

  return (
    <TemplateView heading={<FormattedMessage defaultMessage="Account settings" id="Af5zIY" />}>
      <Card padding={`${Spacings.Sm} ${Spacings.Xl}`} margin={`${Spacings.Xl} 0`}>
        <TemplateSection>
          <FormAccountSettings initialState={initialState} languageItems={languageItems} emailFrequencyItems={emailFrequencyItems} onSubmit={handleSubmit} />
        </TemplateSection>
      </Card>
    </TemplateView>
  );
};

AccountSettingsView.propTypes = {
  actions: PropTypes.shape({
    getAccount: PropTypes.func,
    savePreferences: PropTypes.func,
    loadTranslations: PropTypes.func,
  }),
  auth: AuthShape,
};

export default connect(mapState, mapDispatch)(AccountSettingsView);
