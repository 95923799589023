import React from 'react';
import PropTypes from 'prop-types';
import { string } from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Flexbox,
  Grid,
  GridItem,
  Spacings,
} from '@skf-internal/ui-components-react-legacy';

import PromptForm from 'components/PromptForm';
import ControlError from 'components/ControlError';
import ControlButton from 'components/ControlButton';
import ControlTextField from 'components/ControlTextField';
import ControlValidationError from 'components/ControlValidationError';

const MIN_LENGTH_SYSTEM = 3;

const FormOrganization = ({ initialState, onSubmit }) => {
  const intl = useIntl();

  const validationSchema = {
    // Field-level validators
    system: {
      isNotLongEnough: value => string().min(MIN_LENGTH_SYSTEM).isValidSync(value),
    },
    '': {
      // Form-level validator
      hasChanges: values => {
        return Object.keys(values).some(key => values[key] !== initialState[key]);
      },
    }
  };

  return (
    <PromptForm
      model="organizationDetails"
      onSubmit={onSubmit}
      initialState={initialState}
      validators={validationSchema}
    >
      {isSubmitting => (
        <>
          <Grid>
            <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
              <ControlTextField
                feLabel={intl.formatMessage({
                  defaultMessage: 'System',
                  id: '+CwN9C'
                })}
                id="system"
                model=".system"
                placeholder="Stockholm - Windfarm 02"
                hint={intl.formatMessage({
                  defaultMessage: 'A system is a collection of assets.', id: 'DRehCb'
                })}
              />
              <ControlError
                model=".system"
                messages={{
                  isNotLongEnough: () => (
                    <ControlValidationError
                      validator="isNotLongEnough"
                      values={{ field: 'System', length: MIN_LENGTH_SYSTEM }}
                    />
                  ),
                }}
              />
            </GridItem>
            <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
              <Flexbox feJustifyContent="flex-end" feGap={Spacings.Md} feAlignItems="center">
                <ControlButton model="organizationDetails" id="submit" type="submit" disabled={isSubmitting || { valid: false }}>
                  <FormattedMessage defaultMessage="Save changes" id="X0ha1a" />
                </ControlButton>
              </Flexbox>
            </GridItem>
          </Grid>
        </>
      )}
    </PromptForm>
  );
};

FormOrganization.propTypes = {
  onSubmit: PropTypes.func,
  initialState: PropTypes.shape({
    system: PropTypes.string,
  })
};

FormOrganization.defaultProps = {
  initialState: {
    system: '',
  }
};

export default FormOrganization;
