const LOAD = 'myrina/csv-templates/LOAD';

const LOAD_ASSETS_TEMPLATE_SUCCESS = 'myrina/csv-templates/LOAD_ASSETS_TEMPLATE_SUCCESS';
const LOAD_ASSETS_TEMPLATE_FAIL = 'myrina/csv-templates/LOAD_ASSETS_TEMPLATE_FAIL';

const LOAD_DEVICES_TEMPLATE_SUCCESS = 'myrina/csv-templates/LOAD_DEVICES_TEMPLATE_SUCCESS';
const LOAD_DEVICES_TEMPLATE_FAIL = 'myrina/csv-templates/LOAD_DEVICES_TEMPLATE_FAIL';

const LOAD_DEVICEKEYS_TEMPLATE_SUCCESS = 'myrina/csv-templates/LOAD_DEVICEKEYS_TEMPLATE_SUCCESS';
const LOAD_DEVICEKEYS_TEMPLATE_FAIL = 'myrina/csv-templates/LOAD_DEVICEKEYS_TEMPLATE_FAIL';

const initialState = {
  loaded: false,
  loading: true,
  assetsTemplate: undefined,
  devicesTemplate: undefined,
  deviceKeysTemplate: undefined,
};

export default function csvTemplates(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_ASSETS_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        assetsTemplate: action.result.url,
      };
    }
    case LOAD_DEVICES_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        devicesTemplate: action.result.url,
      };
    }
    case LOAD_DEVICEKEYS_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        deviceKeysTemplate: action.result.url,
      };
    }
    case LOAD_ASSETS_TEMPLATE_FAIL:
    case LOAD_DEVICES_TEMPLATE_FAIL:
    case LOAD_DEVICEKEYS_TEMPLATE_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}

const types = {
  assetsTemplate: [LOAD, LOAD_ASSETS_TEMPLATE_SUCCESS, LOAD_ASSETS_TEMPLATE_FAIL],
  devicesTemplate: [LOAD, LOAD_DEVICES_TEMPLATE_SUCCESS, LOAD_DEVICES_TEMPLATE_FAIL],
  deviceKeysTemplate: [LOAD, LOAD_DEVICEKEYS_TEMPLATE_SUCCESS, LOAD_DEVICEKEYS_TEMPLATE_FAIL],
};

function getSignedCsvTemplateUrl(blobPath, key) {
  return {
    types: types[key],
    promise: client => {
      return client
        .get(`/customer/csv-template-url?blob=${blobPath}`);
    }
  };
}

export function getSignedAssetsCsvTemplateUrl() {
  return getSignedCsvTemplateUrl('assetscsvtemplate.csv', 'assetsTemplate');
}

export function getSignedDevicesCsvTemplateUrl() {
  return getSignedCsvTemplateUrl('devicescsvtemplate.csv', 'devicesTemplate');
}

export function getSignedDeviceKeysCsvTemplateUrl() {
  return getSignedCsvTemplateUrl('devicekeyscsvtemplate.csv', 'deviceKeysTemplate');
}
