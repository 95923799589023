import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ControlValidationError = ({ validator, values }) => {
  switch (validator) {
    case 'hasBeenInvited':
      return (
        <FormattedMessage
          defaultMessage="{value} has already been invited to the organization."
          id="dm5bz0"
          values={values}
        />
      );
    case 'isAlreadyAdded':
      return (
        <FormattedMessage
          defaultMessage="{value} has already been added."
          id="i6/FfT"
          values={values}
        />
      );
    case 'isNotLongEnough':
      return (
        <FormattedMessage
          defaultMessage="{field} needs to be {length} characters or longer."
          id="dQ3zVd"
          values={values}
        />
      );
    case 'isNotShortEnough':
      return (
        <FormattedMessage
          defaultMessage="{field} needs to be {length} characters or shorter."
          id="5zEobH"
          values={values}
        />
      );
    case 'isNotValid':
      return (
        <FormattedMessage
          defaultMessage="{value} is not a valid {thing}."
          id="qJ4Yfn"
          values={values}
        />
      );
    case 'isNotValidExample':
      return (
        <FormattedMessage
          defaultMessage='{value} is not a valid {thing}. The {thing} should look like "{example}".'
          id="s59jct"
          values={values}
        />
      );
    case 'isRequired':
      return (
        <FormattedMessage
          defaultMessage="Field is required."
          id="Ih37+s"
        />
      );
    default:
      return null;
  }
};

ControlValidationError.propTypes = {
  validator: PropTypes.string,
  values: PropTypes.object,
};

export default ControlValidationError;
