const REDIRECT = 'myrina/redirect/REDIRECT';
const REDIRECT_SUCCESS = 'myrina/redirect/REDIRECT_SUCCESS';

export const FINISH_LOGIN = 'myrina/auth/FINISH_LOGIN';
export const FINISH_AUTH = 'myrina/auth/FINISH_AUTH';
export const FINISH_LOGIN_ADMIN_SUPER = 'myrina/auth/admin-super/FINISH_LOGIN';

const initialState = {
  redirectTo: '',
};

export default function redirect(state = initialState, action = {}) {
  switch (action.type) {
    case REDIRECT:
      return {
        ...state,
        redirectTo: action.payload.address,
      };
    case REDIRECT_SUCCESS:
      return {
        ...state,
        redirectTo: '',
      };
    case FINISH_LOGIN:
      return {
        ...state,
        redirectTo: '/dashboard',
      };
    case FINISH_LOGIN_ADMIN_SUPER:
      return {
        ...state,
        redirectTo: '/admin-super/dashboard',
      };
    case FINISH_AUTH:
    default:
      return state;
  }
}

export function redirectTo(address) {
  return {
    type: REDIRECT,
    payload: {
      address,
    },
  };
}

export function completeRedirect() {
  // after redirection if a dialog was opened it adds
  // styling that disables interractions in the view
  document.body.style.cssText = '';
  return {
    type: REDIRECT_SUCCESS,
    payload: {
    },
  };
}
