import {
  Breakpoints,
  Button,
  Confirm,
  Dialog,
  Flexbox,
  Heading, Search, Spacer,
  Spacings,
  Switch,
  Text
} from '@skf-internal/ui-components-react-legacy';
import ActionButtonAdd from 'components/ActionButtonAdd';
import DialogContentUploadAssets from 'components/DialogContentUploadAssets';
import SLoader from 'components/SLoader';
import TableAssets from 'components/TableAssets';
import TemplateSection from 'components/TemplateSection';
import {
  ALERT_LEVELS, APP_ID, ROUTE_DASHBOARD_ASSETS
} from 'helpers/constants';
import { AssetShape, DeviceShape } from 'helpers/propTypes';
import useDevicesWithData from 'hooks/useDevicesWithData';
import useFuse from 'hooks/useFuse';
import PropTypes from 'prop-types';
import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route, Switch as RouterSwitch, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as assetActions from 'redux/modules/asset';
import * as csvTemplateActions from 'redux/modules/csv-template';
import * as dashboardActions from 'redux/modules/dashboard';
import * as deviceActions from 'redux/modules/device';

function mapState(state) {
  const {
    asset: { assets, loaded: assetsLoaded, loading: assetsLoading },
    device: { devices, loaded: devicesLoaded, loading: devicesLoading },
    csvTemplate: { assetsTemplate },
    dashboard: { showAllAssets },
    ui: { width },
  } = state;

  return {
    assets,
    assetsLoaded,
    assetsLoading,
    devices,
    devicesLoaded,
    devicesLoading,
    assetsTemplate,
    showAllAssets,
    width,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...assetActions,
        ...deviceActions,
        ...dashboardActions,
        ...csvTemplateActions,
      },
      dispatch
    ),
  };
}

const DashboardAssetsView = ({
  actions,
  assets,
  assetsLoaded,
  assetsLoading,
  devices,
  devicesLoaded,
  devicesLoading,
  assetsTemplate,
  showAllAssets,
  width,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const [devicesWithData, deviceStatusCount] = useDevicesWithData(devices);
  const [showUploadAssetsDialog, setShowUploadAssetsDialog] = useState(false);
  const assetsUploadFileInput = useRef(null);

  useEffect(() => {
    if (!assetsLoaded) {
      actions.loadAssets();
    }

    if (!devicesLoaded) {
      actions.loadDevices();
    }

    if (!assetsTemplate) {
      actions.getSignedAssetsCsvTemplateUrl();
    }
  }, []);

  // Set alertLevel to asset
  // Use the most critical (numerically biggest) alert level
  const assetsWithData = useMemo(() => {
    const filteredAssets = showAllAssets
      ? assets
      : assets.filter(a => devicesWithData.find(d => d.assetTag === a.assetTag));

    return filteredAssets.map(asset => {
      const assetDevices = devicesWithData.filter(
        device => device.assetTag === asset.assetTag
      );

      const assetGroupState = {
        ASSET_GROUP_SIZE: assetDevices.length,
        DEVICE_OK: 0,
        DEVICE_OFF: 0,
        WARNING: 0,
        ALERT: 0,
        DEVICE_NO_CONNECTION: 0
      };
      for (let i = 0; i < assetDevices.length; i += 1) {
        const device = assetDevices[i];
        if (device.alertLevel === ALERT_LEVELS.DEVICE_OK) {
          assetGroupState.DEVICE_OK += 1;
        }
        if (device.alertLevel === ALERT_LEVELS.DEVICE_OFF) {
          assetGroupState.DEVICE_OFF += 1;
        }
        if (device.alertLevel === ALERT_LEVELS.WARNING) {
          assetGroupState.WARNING += 1;
        }
        if (device.alertLevel === ALERT_LEVELS.ALERT) {
          assetGroupState.ALERT += 1;
        }
        if (device.alertLevel === ALERT_LEVELS.DEVICE_NO_CONNECTION) {
          assetGroupState.DEVICE_NO_CONNECTION += 1;
        }
      }

      return {
        ...asset,
        assetGroupState,
      };
    });
  }, [assets, showAllAssets, devicesWithData, deviceStatusCount]);

  const { hits, onSearch, onReset } = useFuse(assetsWithData, {
    keys: ['assetTag', 'assetName'],
    matchAllOnEmptyQuery: true,
    shouldSort: false,
    threshold: 0,
    ignoreLocation: true
  });

  const handleAssetRowClick = assetTag => {
    history.push(`${ROUTE_DASHBOARD_ASSETS}/${encodeURIComponent(assetTag)}`);
  };

  const handleToggleShowAllAssets = () => {
    actions.changeShowAllAssets(!showAllAssets);
  };

  const handleUploadAssetsDialogOpen = () => {
    setShowUploadAssetsDialog(true);
  };

  const handleUploadAssetsDialogClose = () => {
    setShowUploadAssetsDialog(false);
  };

  const handleUploadAssetsDialogCancel = () => {
    setShowUploadAssetsDialog(false);
  };

  const handleAssetUpload = () => {
    const reader = new FileReader();

    reader.onload = data => {
      actions.uploadAssets(data.target.result);
    };

    if (assetsUploadFileInput.current.files.length > 0) {
      reader.readAsText(assetsUploadFileInput.current.files[0]);
      setShowUploadAssetsDialog(false);
    }
  };

  if (assetsLoading || devicesLoading) {
    return <SLoader />;
  }

  return (
    <RouterSwitch>
      <Route exact path={ROUTE_DASHBOARD_ASSETS}>
        <Dialog
          feAppId={APP_ID}
          feDisplayCloseButton={true}
          feOnClose={() => {
            handleUploadAssetsDialogClose();
          }}
          open={showUploadAssetsDialog}
          title={(
            <FormattedMessage
              defaultMessage="Upload {thing}"
              id="XS9yXk"
              values={{
                thing: (
                  <FormattedMessage defaultMessage="assets" id="G359MS" />
                ),
              }}
            />
          )}
        >
          <Confirm
            feButtons={{
              cancel: {
                children: (
                  <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
                ),
                onClick: handleUploadAssetsDialogCancel,
              },
              confirm: {
                children: (
                  <FormattedMessage defaultMessage="Upload" id="p4N05H" />
                ),
                onClick: handleAssetUpload,
              },
            }}
          >
            <DialogContentUploadAssets
              assetsTemplateUrl={assetsTemplate}
              fileInputRef={assetsUploadFileInput}
            />
          </Confirm>
        </Dialog>
        <TemplateSection>
          <Heading as="h2"><FormattedMessage defaultMessage="Assets" id="d1uESJ" /></Heading>
          <Spacer feSpacing={Spacings.Md} />
          <Flexbox feGap={Spacings.Md} feFlexDirection="column">
            <Text>
              <FormattedMessage
                defaultMessage="Assets have a collection of Devices. In this view you can monitor and manage them and navigate to the resources that have been associated to them."
                id="oZCtr0"
              />
            </Text>
          </Flexbox>
        </TemplateSection>
        <TemplateSection>
          <Heading as="h3">
            <FormattedMessage
              defaultMessage="{thing} table"
              values={{
                thing: (
                  <FormattedMessage defaultMessage="Assets" id="d1uESJ" />
                ),
              }}
              id="CZDCWN"
            />
          </Heading>
          <Spacer feSpacing={Spacings.Md} />
          <Flexbox>
            <Search
              onChange={onSearch}
              feResetButton={{
                onClick: onReset
              }}
              placeholder={(intl.formatMessage({ defaultMessage: 'Search...', id: '0BUTMv' }))}
              feHint={intl.formatMessage(
                {
                  defaultMessage: 'Search by {list}',
                  id: 'UlWvQl',
                },
                {
                  list: intl.formatList(
                    [
                      intl.formatMessage({
                        defaultMessage: 'Tag',
                        id: '18HJlm',
                      }),
                      intl.formatMessage({
                        defaultMessage: 'Name',
                        id: 'HAlOn1',
                      }),
                    ],
                    { type: 'conjunction' }
                  ),
                }
              )}
              feLabel="Search by Tag and Name"
              feHideLabel
            />
          </Flexbox>
          <Spacer feSpacing={Spacings.Md} />
          <Flexbox
            feGap={Spacings.Md}
            feJustifyContent="space-between"
            feFlexDirection={width >= Breakpoints.Tablet ? 'row' : 'column'}
            feAlignItems={width >= Breakpoints.Tablet ? 'center' : 'flex-start'}
          >
            <Flexbox>
              <Flexbox feGap={Spacings.Md}>
                <Text>
                  <FormattedMessage
                    defaultMessage="Include assets without registered devices"
                    id="KBxolf"
                  />
                </Text>
                <Switch
                  defaultChecked={showAllAssets}
                  onChange={() => handleToggleShowAllAssets()}
                  feSize="sm"
                  feLabel="Toggle show/hide assets"
                  feHideLabel
                />
              </Flexbox>
            </Flexbox>
            <Flexbox
              feGap={Spacings.Md}
              feJustifyContent="flex-end"
              feAlignItems="center"
            >
              <Button
                onClick={handleUploadAssetsDialogOpen}
                type="button"
                feSize="sm"
              >
                <FormattedMessage
                  defaultMessage="Upload assets (CSV)"
                  id="QntG1U"
                />
              </Button>
              <ActionButtonAdd
                to={`${ROUTE_DASHBOARD_ASSETS}/add-asset`}
                text={
                  <FormattedMessage defaultMessage="Add asset" id="JIzSgd" />
                }
              />
            </Flexbox>
          </Flexbox>
        </TemplateSection>
        <TableAssets
          assets={hits.map(hit => hit.item)}
          onTableBodyRowClick={handleAssetRowClick}
        />
      </Route>
    </RouterSwitch>
  );
};

DashboardAssetsView.propTypes = {
  actions: PropTypes.shape({
    loadAssets: PropTypes.func.isRequired,
    loadDevices: PropTypes.func.isRequired,
    uploadAssets: PropTypes.func.isRequired,
    uploadDeviceKeys: PropTypes.func.isRequired,
    changeShowAllAssets: PropTypes.func.isRequired,
    getSignedAssetsCsvTemplateUrl: PropTypes.func.isRequired,
  }),
  assets: PropTypes.arrayOf(AssetShape),
  assetsLoaded: PropTypes.bool,
  assetsLoading: PropTypes.bool,
  devices: PropTypes.arrayOf(DeviceShape),
  devicesLoaded: PropTypes.bool,
  devicesLoading: PropTypes.bool,
  assetsTemplate: PropTypes.string,
  showAllAssets: PropTypes.bool,
  width: PropTypes.number,
};

export default connect(mapState, mapDispatch)(DashboardAssetsView);
