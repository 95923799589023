import {
  Table,
  Text
} from '@skf-internal/ui-components-react-legacy';
import ActionLinkDelete from 'components/ActionLinkDelete';
import DeleteDialog from 'components/DeleteDialog';
import DialogContentDeleteLubricant from 'components/DialogContentDeleteLubricant';
import SLoader from 'components/SLoader';
import { intlLubricantType } from 'helpers/intlMethods';
import { DeviceShape, LubricantShape } from 'helpers/propTypes';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const TableLubricants = ({
  loading, organizationId, devices, lubricants, onDeleteConfirmClick
}) => {
  const intl = useIntl();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteLubricant, setDeleteLubricant] = useState(null);

  const handleDeleteClick = lubricant => {
    setDeleteLubricant(lubricant);
    setShowDeleteDialog(true);
  };

  const handleDeleteCancel = () => {
    setDeleteLubricant(undefined);
    setShowDeleteDialog(false);
  };

  const handleDeleteConfirm = () => {
    if (deleteLubricant) {
      onDeleteConfirmClick(deleteLubricant.denomination);
    }
  };

  const renderTableLubricantsBody = () => {
    return lubricants.map(lubricant => {
      const disabledIndex = [
        lubricant.organizationId !== organizationId,
        devices.some(d => d.deviceConfig.lubricant === lubricant.denomination)
      ].findIndex(condition => condition);

      const titles = [
        intl.formatMessage({
          defaultMessage: 'Cannot delete a lubricant that is created by another organization.',
          id: 'ayDNhD',
        }),
        intl.formatMessage({
          defaultMessage: 'You cannot delete a lubricant that is being used. Please delete the devices that use the lubricant first.',
          id: 'n5NSS1',
        })
      ];

      return (
        {
          cells: [
            {
              children: lubricant.manufacturerName,
            },
            {
              children: lubricant.denomination,
            },
            {
              children: intlLubricantType(intl, lubricant.lubricantType),
            },
            {
              children: (
                <Text
                  style={{
                    display: 'inline-block',
                    minWidth: '200px', // TODO: remove arbitrary number
                    whiteSpace: 'pre-line'
                  }}
                >
                  {lubricant.description || '--'}
                </Text>
              ),
            },
            {
              children: (
                <ActionLinkDelete
                  onClick={() => {
                    handleDeleteClick(lubricant);
                  }}
                  disabled={disabledIndex >= 0}
                  title={disabledIndex >= 0
                    ? titles[disabledIndex]
                    : ''}
                  icon
                />
              )
            }
          ]
        }
      );
    });
  };

  const lubricantsTableHead = [
    {
      cells: [
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Manufacturer" id="VI/+Qx" />,
          scope: 'col',
          sortable: {}
        },
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Name" id="HAlOn1" />,
          scope: 'col',
          sortable: {}
        },
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Type" id="+U6ozc" />,
          scope: 'col',
          sortable: {}
        },
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Description" id="Q8Qw5B" />,
          scope: 'col',
          sortable: {}
        },
        {
          as: 'th',
          children: '',
          scope: 'col',
        }
      ]
    }
  ];

  if (loading) {
    return (
      <SLoader />
    );
  }

  if (!lubricants || lubricants.length === 0) {
    return (
      <Text>
        <FormattedMessage
          defaultMessage="No {thing} or failed to load {thing}."
          id="EemuAN"
          values={{
            thing: <FormattedMessage
              defaultMessage="lubricants"
              id="RMAJvN"
            />
          }}
        />
      </Text>
    );
  }

  return (
    <>
      <DeleteDialog
        open={showDeleteDialog}
        onCancel={handleDeleteCancel}
        onDelete={handleDeleteConfirm}
        title={(
          intl.formatMessage({
            defaultMessage: 'Delete {thing}',
            id: '23BjSJ',
          },
          {
            thing: intl.formatMessage({ defaultMessage: 'lubricant', id: 'muUpZ3' })
          })
        )}
      >
        <DialogContentDeleteLubricant
          lubricant={deleteLubricant}
        />

      </DeleteDialog>
      <Table
        caption="Manufacturer lubricants table"
        feCompact={true}
        feBody={renderTableLubricantsBody()}
        feHead={lubricantsTableHead}
        feType="static"
      />
    </>
  );
};

TableLubricants.defaultProps = {
  loading: false,
};

TableLubricants.propTypes = {
  loading: PropTypes.bool,
  organizationId: PropTypes.string,
  devices: PropTypes.arrayOf(DeviceShape),
  lubricants: PropTypes.arrayOf(LubricantShape),
  onDeleteConfirmClick: PropTypes.func.isRequired,
};

export default TableLubricants;
