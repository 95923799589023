import {
  getAccessToken, getIdToken, getRefreshToken
} from '../modules/auth';

const keyPrefix = 'skf-sso-identity';
const idTokenKey = `${keyPrefix}.idToken`;
const accessTokenKey = `${keyPrefix}.accessToken`;
const refreshTokenKey = `${keyPrefix}.refreshToken`;

export function getAuthTokensFromLocalStorage() {
  const idToken = localStorage.getItem(idTokenKey);
  const accessToken = localStorage.getItem(accessTokenKey);
  const refreshToken = localStorage.getItem(refreshTokenKey);

  if (!idToken || !accessToken || !refreshToken) {
    return null;
  }

  return { idToken, accessToken, refreshToken };
}

function updateStorage(key, value) {
  const storedValue = localStorage.getItem(key);
  if (value && value !== storedValue) {
    localStorage.setItem(key, value);
  }
}

export default function storageMiddleware() {
  return ({ getState }) => {
    return next => action => {
      const result = next(action);
      const state = getState();

      try {
        updateStorage(idTokenKey, getIdToken(state));
        updateStorage(accessTokenKey, getAccessToken(state));
        updateStorage(refreshTokenKey, getRefreshToken(state));
      }
      catch (err) {
        console.error('Failed to save to localStorage', err);
      }

      return result;
    };
  };
}
