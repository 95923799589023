import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Colors,
  Flexbox,
  Icon,
  Icons,
  IconSizes,
  Spacings
} from '@skf-internal/ui-components-react-legacy';

import { Children } from 'helpers/propTypes';

const styles = {
  notification: {
    color: Colors.Black,
    backgroundColor: 'rgb(231 238 250)',
    border: '1px solid rgb(52 107 219)',
  },
  success: {
    color: Colors.Black,
    backgroundColor: 'rgb(205 231 217)',
    border: '1px solid rgb(101 181 138)',
  },
  error: {
    color: Colors.Black,
    backgroundColor: 'rgb(255 242 229)',
    border: '1px solid rgb(254 182 110)',
  },
};

const icons = {
  notification: <Icon feIcon={Icons.InfoCircleFilled} feSize={IconSizes.Lg} />,
  success: <Icon feIcon={Icons.CheckCircle} feSize={IconSizes.Lg} />,
  error: <Icon feIcon={Icons.WarningDiamond} feSize={IconSizes.Lg} />,
};

const TemplatePersistingNotification = ({ feType, icon, children }) => {
  return (
    <Card style={styles[feType]}>
      <Flexbox feGap={Spacings.Md} feAlignItems="center">
        {icon && icons[feType]}
        {children}
      </Flexbox>
    </Card>
  );
};

TemplatePersistingNotification.defaultProps = {
  feType: 'notification',
  icon: true,
};

TemplatePersistingNotification.propTypes = {
  feType: PropTypes.oneOf(['notification', 'success', 'error']),
  icon: PropTypes.bool,
  children: Children,
};

export default TemplatePersistingNotification;
