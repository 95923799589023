import {
  Spacer,
  Spacings
} from '@skf-internal/ui-components-react-legacy';
import SLoader from 'components/SLoader';
import TemplateDashboard from 'components/TemplateDashboard';
import TemplatePersistingNotification from 'components/TemplatePersistingNotification';
import TemplateView from 'components/TemplateView';
import LCPUpdateNotification from 'containers/LCPUpdateNotification';
import { DeviceShape, GatewayShape } from 'helpers/propTypes';
import useDevicesWithData from 'hooks/useDevicesWithData';
import useGatewayWithStatusData from 'hooks/useGatewaysWithStatusData';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from 'redux/modules/auth';
import * as deviceActions from 'redux/modules/device';
import * as gatewayActions from 'redux/modules/gateway';

function mapState(state) {
  const {
    ui: { width },
    device: { devices, loading: loadingDevices, loaded: loadedDevices },
    gateway: { gateways, loading: loadingGateways, loaded: loadedGateways },
    auth: { loading: loadingAuth, loaded: loadedAuth, showLCPUpdateBanner },
  } = state;

  return {
    loadingAuth,
    loadedAuth,
    devices,
    loadingDevices,
    loadedDevices,
    gateways,
    loadingGateways,
    loadedGateways,
    width,
    showLCPUpdateBanner
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...authActions, ...deviceActions, ...gatewayActions }, dispatch)
  };
}

const DashboardView = ({
  actions,
  loadingAuth,
  loadedAuth,
  devices,
  loadingDevices,
  loadedDevices,
  gateways,
  loadingGateways,
  loadedGateways,
  showLCPUpdateBanner,
  width,
}) => {
  const [devicesWithData, deviceStatusCount] = useDevicesWithData(devices);
  const gatewaysStatusCount = useGatewayWithStatusData(gateways);

  useEffect(() => {
    if (!loadedDevices) {
      actions.loadDevices();
    }

    if (!loadedAuth) {
      actions.getAccount();
    }

    if (!loadedGateways) {
      actions.loadDevices();
    }

    // Add timer to update devices list every 5 minutes
    const fiveMinutes = 5 * 60 * 1000;
    const refreshInterval = setInterval(() => actions.loadDevices(), fiveMinutes);

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  const tooManyLubricators = useMemo(() => {
    return devicesWithData.length > 1500;
  }, [devicesWithData]);

  if (loadingAuth || loadingDevices || loadingGateways) {
    return <SLoader/>;
  }

  return (
    <TemplateView heading={<FormattedMessage defaultMessage="Dashboard" id="hzSNj4" />}>
      {showLCPUpdateBanner === true && (
      <>
        <LCPUpdateNotification />
        <Spacer feSpacing={Spacings.Md} />
      </>
      )}
      {tooManyLubricators && (
        <>
          <TemplatePersistingNotification feType="notification">
            <FormattedMessage defaultMessage="Too many lubricators!" id="t462X+"/>
          </TemplatePersistingNotification>
          <Spacer feSpacing={Spacings.Md} />
        </>
      )}
      <TemplateDashboard width={width} devices={devicesWithData} gatewaysStatusCount={gatewaysStatusCount} statusCount={deviceStatusCount} />
    </TemplateView>
  );
};

DashboardView.propTypes = {
  actions: PropTypes.shape({
    getAccount: PropTypes.func.isRequired,
    loadDevices: PropTypes.func.isRequired,
  }),
  loadingAuth: PropTypes.bool,
  loadedAuth: PropTypes.bool,
  devices: PropTypes.arrayOf(DeviceShape),
  loadingDevices: PropTypes.bool,
  loadedDevices: PropTypes.bool,
  gateways: PropTypes.arrayOf(GatewayShape),
  loadingGateways: PropTypes.bool,
  loadedGateways: PropTypes.bool,
  width: PropTypes.number,
  showLCPUpdateBanner: PropTypes.bool
};

export default connect(mapState, mapDispatch)(DashboardView);
