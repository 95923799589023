import {
  Flexbox,
  Grid,
  GridItem,
  Spacings
} from '@skf-internal/ui-components-react-legacy';
import ControlButton from 'components/ControlButton';
import ControlError from 'components/ControlError';
import ControlSelectField from 'components/ControlSelectField';
import ControlValidationError from 'components/ControlValidationError';
import PromptForm from 'components/PromptForm';
import { DEFAULT_EMAIL_FREQUENCY, DEFAULT_LANGUAGE_CODE } from 'helpers/constants';
import { intlEmailFrequency } from 'helpers/intlMethods';
import { ControlSelectFieldItemShape } from 'helpers/propTypes';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { string } from 'yup';

const FormAccountSettings = ({
  initialState, languageItems, emailFrequencyItems, onSubmit
}) => {
  const intl = useIntl();
  const validationSchema = {
    // Field-level validators
    language: {
      isRequired: value => string().required().isValidSync(value),
    },
    emailFrequency: {
      isRequired: value => string().required().isValidSync(value),
    },
    '': {
      // Form-level validator
      hasChanges: values => {
        return Object.keys(values).some(key => values[key] !== initialState[key]);
      },
    }
  };

  emailFrequencyItems = emailFrequencyItems.map(frequency => ({
    value: frequency.value,
    label: intlEmailFrequency(intl, frequency.value),
  }));

  return (
    <PromptForm
      model="preferences"
      onSubmit={onSubmit}
      initialState={initialState}
      validators={validationSchema}
    >
      {isSubmitting => (
        <>
          <Grid>
            <GridItem feColspan={{ desktop: 6, tablet: 6, mobile: 12 }}>
              <ControlSelectField
                feLabel={intl.formatMessage({
                  defaultMessage: 'Language',
                  id: 'y1Z3or'
                })}
                model=".language"
                id="language"
                items={languageItems}
                required
              />
              <ControlError
                model=".language"
                messages={{
                  isRequired: <ControlValidationError validator="isRequired" />,
                }}
              />
            </GridItem>
            <GridItem feColspan={{ desktop: 6, tablet: 6, mobile: 12 }}>
              <ControlSelectField
                feLabel={intl.formatMessage({
                  defaultMessage: 'Notifications Email frequency',
                  id: 'jsExOB'
                })}
                model=".emailFrequency"
                id="emailFrequency"
                items={emailFrequencyItems}
                required
              />
              <ControlError
                model=".emailFrequency"
                messages={{
                  isRequired: <ControlValidationError validator="isRequired" />,
                }}
              />
            </GridItem>
            <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
              <Flexbox feJustifyContent="flex-end" feGap={Spacings.Md}>
                <ControlButton model="preferences" id="submit" type="submit" disabled={isSubmitting || { valid: false }}>
                  <FormattedMessage defaultMessage="Save changes" id="X0ha1a" />
                </ControlButton>
              </Flexbox>
            </GridItem>
          </Grid>
        </>
      )}
    </PromptForm>
  );
};

FormAccountSettings.defaultProps = {
  initialState: {
    language: DEFAULT_LANGUAGE_CODE,
    emailFrequency: DEFAULT_EMAIL_FREQUENCY,
  },
};

FormAccountSettings.propTypes = {
  initialState: PropTypes.shape({
    language: PropTypes.string,
    emailFrequency: PropTypes.string,
  }),
  languageItems: PropTypes.arrayOf(ControlSelectFieldItemShape).isRequired,
  emailFrequencyItems: PropTypes.arrayOf(ControlSelectFieldItemShape).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FormAccountSettings;
