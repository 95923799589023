import {
  Breakpoints,
  Flexbox,
  Heading, Search, Spacer,
  Spacings,
  Text
} from '@skf-internal/ui-components-react-legacy';
import ActionButtonAdd from 'components/ActionButtonAdd';
import SLoader from 'components/SLoader';
import TableGateway from 'components/TableGateway';
import TemplateSection from 'components/TemplateSection';
import AddGatewayView from 'containers/AddGatewayView';
import { ROUTE_DASHBOARD_GATEWAYS } from 'helpers/constants';
import { GatewayShape } from 'helpers/propTypes';
import useFuse from 'hooks/useFuse';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  FormattedMessage, useIntl
} from 'react-intl';
import { connect } from 'react-redux';
import {
  Route, Switch as RouterSwitch
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as gatewayActions from 'redux/modules/gateway';

function mapState(state) {
  const {
    gateway: { gateways, loading, loaded },
    ui: { width },
  } = state;

  return {
    gateways,
    loading,
    loaded,
    width,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...gatewayActions }, dispatch)
  };
}

const DashboardGatewaysView = ({
  actions, gateways, loading, loaded, width,
}) => {
  const intl = useIntl();
  const { hits, onSearch, onReset } = useFuse(gateways, {
    keys: ['name', 'gatewayId'],
    matchAllOnEmptyQuery: true,
    shouldSort: false,
    threshold: 0,
    ignoreLocation: true
  });

  useEffect(() => {
    if (!loaded) {
      actions.loadGateways();
    }
  }, []);

  const handleDeleteConfirmClick = deleteGateway => {
    actions.deleteGateway(deleteGateway);
  };

  if (loading) {
    return <SLoader />;
  }

  return (
    <RouterSwitch>
      <Route exact path={ROUTE_DASHBOARD_GATEWAYS}>
        <TemplateSection>
          <Heading as="h2">
            <FormattedMessage
              defaultMessage="Gateways"
              id="jtdQHR"
            />
          </Heading>
          <Spacer feSpacing={Spacings.Md} />
          <Flexbox feGap={Spacings.Md} feFlexDirection="column">
            <Text>
              <FormattedMessage
                defaultMessage="Gateways are networking hardware that transmit Devices' data to the Cloud. In this view you can monitor and manage them."
                id="l2/W1W"
              />
            </Text>
          </Flexbox>
        </TemplateSection>
        <TemplateSection>
          <Heading as="h3">
            <FormattedMessage
              defaultMessage="{thing} table"
              values={{
                thing: <FormattedMessage
                  defaultMessage="Gateways"
                  id="jtdQHR"
                />
              }}
              id="CZDCWN"
            />
          </Heading>
          <Spacer feSpacing={Spacings.Md} />
          <Flexbox
            feGap={Spacings.Md}
            feJustifyContent="space-between"
            feFlexDirection={width >= Breakpoints.Tablet ? 'row' : 'column'}
            feAlignItems={width >= Breakpoints.Tablet ? 'center' : 'flex-start'}
          >
            <Flexbox>
              <Search
                onChange={onSearch}
                feResetButton={{
                  onClick: onReset
                }}
                placeholder={(intl.formatMessage({ defaultMessage: 'Search...', id: '0BUTMv' }))}
                feHint={(
                  intl.formatMessage(
                    {
                      defaultMessage: 'Search by {list}',
                      id: 'UlWvQl',
                    },
                    {
                      list: intl.formatList([
                        intl.formatMessage({
                          defaultMessage: 'Name', id: 'HAlOn1',
                        }),
                        intl.formatMessage({
                          defaultMessage: 'ID', id: 'qlcuNQ',
                        }),
                      ], { type: 'conjunction' })
                    }
                  )
                )}
                feLabel="Search by Name and ID"
                feHideLabel
              />
            </Flexbox>
            <Flexbox
              feGap={Spacings.Md}
              feJustifyContent="flex-end"
              feAlignItems="center"
            >
              <ActionButtonAdd
                to={`${ROUTE_DASHBOARD_GATEWAYS}/add-gateway`}
                text={<FormattedMessage defaultMessage="Add gateway" id="iJlyMg" />}
              />
            </Flexbox>
          </Flexbox>
        </TemplateSection>
        <TableGateway intl={intl} gateways={hits.map(hit => hit.item)} onDeleteConfirmClick={handleDeleteConfirmClick} />
      </Route>
      <Route exact path={`${ROUTE_DASHBOARD_GATEWAYS}/add-gateway`} component={AddGatewayView} />
    </RouterSwitch>
  );
};

DashboardGatewaysView.propTypes = {
  actions: PropTypes.shape({
    changeActiveGateway: PropTypes.func,
    loadGateways: PropTypes.func,
    deleteGateway: PropTypes.func,
  }),
  gateways: PropTypes.arrayOf(GatewayShape),
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  width: PropTypes.number,
};

// Strings didn't update on language change. Added option { pure: false } to work around it.
export default connect(mapState, mapDispatch)(DashboardGatewaysView);
