import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/it';
import '@formatjs/intl-pluralrules/locale-data/pt';
import '@formatjs/intl-pluralrules/locale-data/ru';
import '@formatjs/intl-pluralrules/locale-data/zh';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/es';
import '@formatjs/intl-relativetimeformat/locale-data/fr';
import '@formatjs/intl-relativetimeformat/locale-data/it';
import '@formatjs/intl-relativetimeformat/locale-data/pt';
import '@formatjs/intl-relativetimeformat/locale-data/ru';
import '@formatjs/intl-relativetimeformat/locale-data/zh';
import '@formatjs/intl-relativetimeformat/polyfill';
import { asCapitalizedOptionItem, asOptionItem } from 'helpers/selectOptions';

/**
 * TODO: Get rid of all this crap...
 *
 * @deprecated Use Ferris colors instead!
 */
export const colors = {
  /* Ferris Colors */
  white: 'rgb(255,255,255)',
  black: 'rgb(0, 0, 0)',
  blackBrand: 'rgb(39, 51, 66)',
  blueBase: 'rgb(132, 169, 233)',
  blueDark: 'rgb(79, 132, 224)',
  blueDarker: 'rgb(51, 85, 145)',
  brandBase: 'rgb(15, 88, 214)',
  brandDark: 'rgb(12, 74, 182)',
  brandDarker: 'rgb(9, 57, 139)',
  grayBase: 'rgb(213, 216, 220)',
  grayBasedark: 'rgb(199, 202, 208)',
  grayBaselight: 'rgb(235, 236, 238)',
  grayDark: 'rgb(170, 175, 184)',
  grayDarker: 'rgb(128, 136, 149)',
  grayDarkest: 'rgb(103, 111, 124)',
  grayLight: 'rgb(235, 236, 238)',
  grayLighter: 'rgb(246, 246, 246)',
  grayLightest: 'rgb(248, 248, 248)',
  greenBase: 'rgb(4, 137, 66)',
  greenDark: 'rgb(3, 116, 56)',
  greenDarker: 'rgb(2, 89, 42)',
  redBase: 'rgb(204, 0, 0)',
  redDark: 'rgb(173, 0, 0)',
  redDarker: 'rgb(132, 0, 0)',
  yellowBase: 'rgb(255, 228, 138);',
  yellowDark: 'rgb(255, 215, 79)',
  yellowDarker: 'rgb(255, 202, 21)',
  // All of these are deprecated also
  gray: 'rgba(101,92,102,1)',
  darkestGray: 'rgba(74,89,107,1)', // SKF Clay
  darkGray: 'rgba(95,95,100,1)', // SKF Stone
  lightGray: 'rgba(132,141,151,1)', // SKF Gray
  lighterGray: 'rgba(220,224,225,1)', // SKF Steel
  lightestGray: 'rgba(235,240,240,1)', // SKF Cloud
  modalOverlay: 'rgba(220,224,225,0.8)', // SKF Steel 80% opacity
  blue: 'rgba(15,88,214,1)', // SKF Blue
  lightBlue: 'rgba(1,1,1,1)', // This is black...
  red: 'rgba(235,32,42,1)', // SKF Red
  green: 'rgba(136,192,8,1)', // SKF Green
  yellow: 'rgba(255,214,21,1)', // SKF Yellow
  orange: 'rgba(255,128,4,1)', // SKF Orange
  purple: 'rgba(120,30,148,1)', // SKF Purple
};

// /////////////////////
// DOM Element selectors
// /////////////////////

export const APP_ID = 'root';

// //////
// Routes
// //////

export const ROUTE_BASE = '/';
export const ROUTE_ACCOUNT_SETTINGS = '/account-settings';
export const ROUTE_ORGANIZATION = '/organization';
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_DASHBOARD_ASSETS_AND_DEVICES = `${ROUTE_DASHBOARD}/assets-and-devices`;
export const ROUTE_DASHBOARD_ASSETS = `${ROUTE_DASHBOARD_ASSETS_AND_DEVICES}/assets`;
export const ROUTE_DASHBOARD_DEVICES = `${ROUTE_DASHBOARD_ASSETS_AND_DEVICES}/devices`;
export const ROUTE_DASHBOARD_GATEWAYS = `${ROUTE_DASHBOARD}/gateways`;
export const ROUTE_DASHBOARD_LUBRICANTS_AND_MANUFACTURERS = `${ROUTE_DASHBOARD}/lubricants-and-manufacturers`;
export const ROUTE_DASHBOARD_LUBRICANTS = `${ROUTE_DASHBOARD_LUBRICANTS_AND_MANUFACTURERS}/lubricants`;
export const ROUTE_DASHBOARD_MANUFACTURERS = `${ROUTE_DASHBOARD_LUBRICANTS_AND_MANUFACTURERS}/lubricant-manufacturers`;

// ///////
// Storage
// ///////

export const LOCAL_STORAGE_KEYS = {
  userLocale: 'userLocale',
};

// /////////
// Languages
// /////////

export const LANGUAGE_CODES = {
  'de-DE': 'de-DE',
  'en-US': 'en-US',
  'pt-PT': 'pt-PT',
  'fr-FR': 'fr-FR',
  // 'it-IT': 'it-IT', // NOT SUPPORTED YET
  // 'ru-RU': 'ru-RU', // NOT SUPPORTED YET
  'es-ES': 'es-ES',
  // 'zh-CN': 'zh-CN', // NOT SUPPORTED YET
};

export const LANGUAGE_NAMES = {
  'de-DE': 'Deutsch',
  'en-US': 'English',
  'pt-PT': 'Português',
  'fr-FR': 'Français',
  'it-IT': 'Italiano',
  'ru-RU': 'Русский',
  'es-ES': 'Español',
  'zh-CN': '汉语',
};

export const DEFAULT_LANGUAGE_CODE = LANGUAGE_CODES['en-US'];

export const languageItems = Object.keys(LANGUAGE_CODES).map(key => ({
  value: LANGUAGE_CODES[key],
  label: LANGUAGE_NAMES[key],
}));

// ///////
// Locales
// ///////

export const LOCALES = {
  'de-DE': 'de',
  'en-US': 'en',
  'pt-PT': 'pt',
  'fr-FR': 'fr',
  'it-IT': 'it',
  'ru-RU': 'ru',
  'es-ES': 'es',
  'zh-CN': 'zh',
};

export const LOCALE_CODES = {
  de: 'de-DE',
  en: 'en-US',
  pt: 'pt-PT',
  fr: 'fr-FR',
  it: 'it-IT',
  ru: 'ru-RU',
  es: 'es-ES',
  zh: 'zh-CN',
};

// /////////////////
// Email frequencies
// /////////////////

export const EMAIL_FREQUENCIES = {
  NEVER: 'NEVER',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

export const EMAIL_FREQUENCIES_INTL = [
  {
    value: EMAIL_FREQUENCIES.NEVER,
    intl: {
      id: 'du1laW',
      defaultMessage: 'Never',
    },
  },
  {
    value: EMAIL_FREQUENCIES.DAILY,
    intl: {
      id: 'zxvhnE',
      defaultMessage: 'Daily',
    },
  },
  {
    value: EMAIL_FREQUENCIES.WEEKLY,
    intl: {
      id: '/clOBU',
      defaultMessage: 'Weekly',
    },
  },
  {
    value: EMAIL_FREQUENCIES.MONTHLY,
    intl: {
      id: 'wYsv4Z',
      defaultMessage: 'Monthly',
    },
  },
];

export const DEFAULT_EMAIL_FREQUENCY = EMAIL_FREQUENCIES.NEVER;

export const emailFrequencyItems = Object.keys(EMAIL_FREQUENCIES).map(key => asCapitalizedOptionItem({ key }, 'key', 'key'));

// ///////
// Devices
// ///////

export const MAX_UPLOAD_COUNT_DEVICES = 50;

// //////////
// Lubricants
// //////////

export const LUBRICANT_TYPES = {
  GREASE: 'grease',
  OIL: 'oil',
};

export const LUBRICANT_TYPES_INTL = [
  {
    value: LUBRICANT_TYPES.GREASE,
    intl: {
      id: 'Q0vS/t',
      defaultMessage: 'Grease',
    },
  },
  {
    value: LUBRICANT_TYPES.OIL,
    intl: {
      id: 'lYwk2j',
      defaultMessage: 'Oil',
    },
  },
];

export const lubricantTypeItems = Object.values(LUBRICANT_TYPES).map(value => asCapitalizedOptionItem({ value }, 'value', 'value'));

// TODO: Get rid of all this crap and load from database
export const lubricantValues = [
  'LGWA 2',
  'LGEM 2',
  'LGHB 2',
  'LGHQ 2', // LGHQ 2 replaced LGHP 2 on 2020-06-08
  'LGFP 2',
  'LGWM 2',
  'LGFQ 2',
  'LHMT 68',
  'LHHT 265',
  'LHFP 150',
  'other',
];

export const ORGANIZATION_ID_SKF = 'SKF';
export const LUBRICANT_MANUFACTURER_NAME_SKF = 'SKF';
export const LUBRICANT_MANUFACTURER_CLEANNAME_SKF = LUBRICANT_MANUFACTURER_NAME_SKF.replace(' ', '') // replace spaces
  .replace(/[^a-zA-Z0-9]/g, '') // replace special characters
  .toUpperCase(); // convert to UPPERCASE

export const LUBRICANT_MANUFACTURERS = [
  {
    name: LUBRICANT_MANUFACTURER_NAME_SKF,
    cleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    createdByOrg: ORGANIZATION_ID_SKF,
  },
];

export const LUBRICANTS = [
  {
    denomination: 'LGMT 2',
    lubricantType: 'grease',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: 'General purpose industrial and automotive NLGI 2 grease',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LGMT 3',
    lubricantType: 'grease',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: 'General purpose industrial and automotive NLGI 3 grease',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LGEP 2',
    lubricantType: 'grease',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: 'High load, extreme pressure grease',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LGGB 2',
    lubricantType: 'grease',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: 'Biodegradable grease',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LGWM 1',
    lubricantType: 'grease',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: 'Extreme pressure low temperature grease',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LGWM 2',
    lubricantType: 'grease',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: 'High load, wide temperature grease',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LGWA 2',
    lubricantType: 'grease',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: 'High load, extreme pressure, wide temperature range grease',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LGEM 2',
    lubricantType: 'grease',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: 'High viscosity grease with solid lubricants',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LGEV 2',
    lubricantType: 'grease',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: 'Extremely high viscosity grease with solid lubricants',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LGHB 2',
    lubricantType: 'grease',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: 'High load, high temperature, high viscosity grease',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LGHP 2',
    lubricantType: 'grease',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description:
      'High performance, high temperature grease (deprecated, use LGHQ 2)',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LGHQ 2',
    lubricantType: 'grease',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: 'Electric motor grease',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LGFG 2',
    lubricantType: 'grease',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: 'General purpose food grade grease',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LGFP 2',
    lubricantType: 'grease',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: 'General purpose food grade grease',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LGFQ 2',
    lubricantType: 'grease',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: 'High load food grade grease',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LHMT 68',
    lubricantType: 'oil',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: '--',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LHHT 265',
    lubricantType: 'oil',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: '--',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'LHFP 150',
    lubricantType: 'oil',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    description: '--',
    organizationId: ORGANIZATION_ID_SKF,
  },
  {
    denomination: 'other', // Exists only for historical reasons
    lubricantType: 'grease',
    manufacturerCleanName: LUBRICANT_MANUFACTURER_CLEANNAME_SKF,
    manufacturerName: LUBRICANT_MANUFACTURER_NAME_SKF,
    organizationId: ORGANIZATION_ID_SKF,
    description:
      'Only for lubricants that are unbranded or custom. Otherwise, create a lubricant using the Add lubricant feature.',
  },
];

// //////////
// Cartridges
// //////////

export const CARTRIDGES = [125, 250];

export const cartridgeItems = CARTRIDGES.map(cartridge => asOptionItem({ cartridge }, 'cartridge', 'cartridge'));

// //////////////
// Empty settings
// //////////////

export const DEMO_MODE = 999;

// ////////
// Gateways
// ////////

export const GATEWAY_STATUSES = {
  OFFLINE: 0,
  ONLINE: 1,
};

// //////////////////////////
// Alerts and device statuses
// //////////////////////////

/**
 * Colors corresponding to an alert level.
 */
export const ALERT_COLORS = {
  DEVICE_OK: colors.green,
  DEVICE_OFF: colors.darkGray,
  WARNING: colors.yellow,
  ALERT: colors.red,
};

/**
 * These are alarm numbers for ordering.
 * See `ALARM_NUMBERS` for real alarm numbers
 * stored in the backend.
 */
export const ALERT_LEVELS = {
  DEVICE_OK: 0,
  DEVICE_OFF: 1,
  WARNING: 2,
  ALERT: 3,
  DEVICE_NO_CONNECTION: 4,
};

/**
 * Alarm numbers are a numeric representation
 * of the device's current status (`DEVICE_STATUSES`).
 *
 * - 0 = OFF,
 * - 1 = OK,
 * - 2 = Empty Refill,
 * - 3 = Battery Low,
 * - 4 = Battery Out,
 * - 5 = High Pressure Warning,
 * - 6 = High Pressure Alarm,
 * - 7 = Internal Alarm
 */
export const ALARM_NUMBERS = {
  DEVICE_OFF: 0,
  DEVICE_OK: 1,
  EMPTY_ALARM: 2,
  LOW_BATTERY: 3,
  EMPTY_BATTERY: 4,
  HIGH_PRESSURE_WARNING: 5,
  HIGH_PRESSURE_ALARM: 6,
  INTERNAL_ALARM: 7,
  END_OF_DEMO: 8,
  NO_CONNECTION: 998,
};

export const LENS_SYNC_STATUS = {
  OK: 'ok',
  PENDING: 'pending',
  FAIL: 'fail',
};
export const LENS_ERROR_TYPE = {
  GATEWAY_RESERVED_ERROR: 'GatewayReservedError',
  DEVICE_RESERVED_ERROR: 'DeviceReservedError',
};

/**
 * Device statuses are human readable
 * version of `ALARM_NUMBERS`.
 *
 * - 0 = OFF,
 * - 1 = OK,
 * - 2 = Empty Refill,
 * - 3 = Battery Low,
 * - 4 = Battery Out,
 * - 5 = High Pressure Warning,
 * - 6 = High Pressure Alarm,
 * - 7 = Internal Alarm
 */
export const DEVICE_STATUSES = {
  DEVICE_OFF: 'OFF',
  DEVICE_OK: 'OK',
  EMPTY_ALARM: 'Empty alarm',
  LOW_BATTERY: 'Low battery',
  EMPTY_BATTERY: 'Empty battery',
  HIGH_PRESSURE_WARNING: 'High pressure warning',
  HIGH_PRESSURE_ALARM: 'High pressure alarm',
  INTERNAL_ALARM: 'Internal alarm',
  END_OF_DEMO: 'End of demo',
  NO_CONNECTION: 'No connection',
  DEFAULT: 'Waiting for connection',
};

export const SIGNAL_STRENGTH_THRESHOLDS = {
  EXCELLENT: {
    signalStrength: -50,
    rssi: -100,
    lsnr: -5
  },
  GOOD: {
    signalStrength: -60,
    rssi: -107,
    lsnr: -10
  },
  FAIR: {
    signalStrength: -70,
    rssi: -106,
    lsnr: -14
  },
  WEAK: {
    signalStrength: -100,
    rssi: -115,
    lsnr: -15
  },
};

export const NO_SIGNAL = -101;

/**
 * Device type refering to the product version being a TLDDv1 or TLDDv2
 */

export const DeviceType = {
  LUBRICATOR_V1: 'lubricatorv1',
  LUBRICATOR_V2: 'lubricatorv2',
};

/**
 * Common style for the Dashboard KPI cards to make them fit the space evenly
 */

export const CARD_STYLE = {
  height: '100%',
  placeContent: 'center'
};
