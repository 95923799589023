import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  IconColors,
  Icons,
} from '@skf-internal/ui-components-react-legacy';

import { ALERT_LEVELS } from 'helpers/constants';

const IconStatus = ({ level }) => {
  switch (level) {
    case ALERT_LEVELS.ALERT:
      return (
        <Icon feIcon={Icons.WarningDiamond} feColor={IconColors.Red} />
      );
    case ALERT_LEVELS.WARNING:
      return (
        <Icon feIcon={Icons.Warning} feColor={IconColors.Yellow} />
      );
    case ALERT_LEVELS.DEVICE_OK:
      return (
        <Icon feIcon={Icons.CheckCircle} feColor={IconColors.Green} />
      );
    case ALERT_LEVELS.DEVICE_NO_CONNECTION:
      return (
        <Icon feIcon={Icons.Stop} />
      );
    default:
      return (
        <Icon feIcon={Icons.PowerOff} />
      );
  }
};

IconStatus.propTypes = {
  level: PropTypes.number,
};

export default IconStatus;
