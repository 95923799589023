/* eslint-disable react/no-unused-prop-types */
import {
  FontSizes,
  Header,
  Icons,
  Link,
} from '@skf-internal/ui-components-react-legacy';
import Notifications from 'components/Notifications';
import SLoader from 'components/SLoader';
import TemplateFooter from 'components/TemplateFooter';
import AccountSettingsView from 'containers/AccountSettingsView';
import AdminSuperDashboardView from 'containers/AdminSuperDashboardView/AdminSuperDashboardView';
import AdminSuperDashboardViewDetails from 'containers/AdminSuperDashboardViewDetails/AdminSuperDashboardViewDetails';
import AdminSuperDashboardExportHistory from 'containers/AdminSuperExportHistory/AdminSuperDashboardExportHistory';
import AdminSuperDashboardExportHistoryDownload from 'containers/AdminSuperExportHistoryDownload/AdminSuperDashboardExportHistoryDownload';
import AdminSuperDashboardOrganization from 'containers/AdminSuperOrganization/AdminSuperDashboardOrganization';
import AdminSuperDashboardOrganizations from 'containers/AdminSuperOrganizations/AdminSuperDashboardOrganizations';
import AdminSuperDashboardOrganizationsDevices from 'containers/AdminSuperOrganizationsDevices/AdminSuperDashboardOrganizationsDevices';
import AdminSuperDashboardOrganizationsGateways from 'containers/AdminSuperOrganizationsGateways/AdminSuperDashboardOrganizationsGateways';
import AdminSuperDashboardOrganizationsUsers from 'containers/AdminSuperOrganizationsUsers/AdminSuperDashboardOrganizationsUsers';
import AuthHandlerView from 'containers/AuthHandlerView';
import DashboardView from 'containers/DashboardView';
import OrganizationView from 'containers/OrganizationView';
import {
  ROUTE_ACCOUNT_SETTINGS,
  ROUTE_BASE,
  ROUTE_DASHBOARD,
  ROUTE_DASHBOARD_ASSETS_AND_DEVICES,
  ROUTE_ORGANIZATION,
} from 'helpers/constants';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as authActions from 'redux/modules/auth';
import * as intlActions from 'redux/modules/intl';
import * as redirectActions from 'redux/modules/redirect';
import * as uiActions from 'redux/modules/ui';
import styled from 'styled-components';
import NewUserAfterCutoffDateView from 'containers/NewUserAfterCutoffDateView';
import InOrbitOrganizationView from 'containers/InOrbitOrganizationView';

const AppContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex: 1 1 0%;
  flex-direction: column;
  font-size: ${FontSizes.Md};
`;

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { authenticated } = rest;

  const render = props => {
    // Authenticated
    if (authenticated) {
      return <RouteComponent {...props} />;
    }
    // Anonymous
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location },
        }}
      />
    );
  };

  return <Route {...rest} render={render} />;
};

function mapState(state) {
  const {
    intl: {
      translations,
      loading: intlLoading,
      loaded: intlLoaded,
      locale,
      language,
    },
    redirect: { redirectTo },
    auth: {
      authenticated, loading: authLoading, loaded: authLoaded, inOrbit, isUserAfterCutoff
    },
    notifications,
  } = state;

  // Set moment locale
  moment.locale(locale);

  return {
    locale,
    language,
    translations,
    intlLoading,
    intlLoaded,
    redirectTo,
    authenticated,
    authLoading,
    authLoaded,
    notifications,
    inOrbit,
    isUserAfterCutoff
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...intlActions,
        ...redirectActions,
        ...authActions,
        ...uiActions,
      },
      dispatch
    ),
  };
}

class App extends Component {
  constructor(props) {
    super(props);

    this.handleResize = debounce(() => {
      props.actions.screenResize(window.innerWidth);
    }, 100);
  }

  componentDidMount() {
    if (!this.props.intlLoaded) {
      this.props.actions.loadTranslations(this.props.language);
    }

    // set the initial width
    this.props.actions.screenResize(window.innerWidth);

    // Add event listener
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate() {
    // On initial page load we have not finished auth / login
    // so we need to fetch the account information only after
    // the initial load.
    if (this.props.authenticated && !this.props.authLoaded) {
      this.props.actions.getAccount();
    }

    if (
      this.props.redirectTo !== ''
      && window.location.pathname === this.props.redirectTo
    ) {
      this.props.actions.completeRedirect();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  redirect(redirectTo) {
    if (redirectTo.indexOf('http') === 0) {
      window.location = redirectTo;
      return <SLoader />;
    }

    return (
      <Router>
        <Redirect to={redirectTo} />
      </Router>
    );
  }

  render() {
    if (this.props.intlLoading || this.props.authLoading) {
      return <SLoader />;
    }

    if (this.props.redirectTo !== '') {
      return this.redirect(this.props.redirectTo);
    }

    if (this.props.inOrbit || this.props.isUserAfterCutoff) {
      return (
        <IntlProvider
          locale={this.props.locale || 'en'}
          messages={this.props.translations}
          textComponent={React.Fragment}
          defaultLocale="en"
        >
          {
          this.props.inOrbit
            ? <InOrbitOrganizationView />
            : this.props.isUserAfterCutoff && <NewUserAfterCutoffDateView />
          }
        </IntlProvider>
      );
    }

    const { notifications } = this.props;
    return (
      <>
        <div className="App" style={{ display: 'flex' }}>
          <Notifications notifications={notifications} />
          <IntlProvider
            locale={this.props.locale || 'en'}
            messages={this.props.translations}
            textComponent={React.Fragment}
            defaultLocale="en"
          >
            <Router>
              <AppContainer>
                <Header
                  feIntro={{
                    baseUrl: ROUTE_BASE,
                    name: 'SKF Lubricator Control Panel',
                  }}
                  feNavigation={{
                    feItems: [
                      {
                        children: (
                          <FormattedMessage
                            defaultMessage="Dashboard"
                            id="hzSNj4"
                          />
                        ),
                        href: ROUTE_DASHBOARD,
                      },
                      {
                        children: (
                          <FormattedMessage
                            defaultMessage="Organization"
                            id="K56Dim"
                          />
                        ),
                        href: ROUTE_ORGANIZATION,
                      },
                      {
                        children: (
                          <FormattedMessage
                            defaultMessage="Account settings"
                            id="Af5zIY"
                          />
                        ),
                        href: ROUTE_ACCOUNT_SETTINGS,
                      },
                      {
                        icon: Icons.OpenInNew,
                        children: (
                          <FormattedMessage
                            defaultMessage="Profile"
                            id="itPgxd"
                          />
                        ),
                        target: '_blank',
                        href: `${process.env.REACT_APP_SSO_URL}/profile?app=${process.env.REACT_APP_SSO_APPKEY}`,
                      },
                      {
                        icon: Icons.LogOut,
                        children: (
                          <FormattedMessage
                            defaultMessage="Sign out"
                            id="xXbJso"
                          />
                        ),
                        href: `${process.env.REACT_APP_SSO_URL}/logout?app=${process.env.REACT_APP_SSO_APPKEY}`,
                      },
                    ],
                  }}
                />
                <div>
                  <Switch>
                    <Route
                      exact
                      path={ROUTE_BASE}
                      component={AuthHandlerView}
                    />
                    <Route
                      exact
                      authenticated={this.props.authenticated}
                      path={ROUTE_DASHBOARD}
                    >
                      <Redirect push to={ROUTE_DASHBOARD_ASSETS_AND_DEVICES} />
                    </Route>
                    <PrivateRoute
                      authenticated={this.props.authenticated}
                      path={ROUTE_DASHBOARD}
                      component={DashboardView}
                    />
                    <PrivateRoute
                      authenticated={this.props.authenticated}
                      path={ROUTE_ORGANIZATION}
                      component={OrganizationView}
                    />
                    <PrivateRoute
                      exact
                      authenticated={this.props.authenticated}
                      path={ROUTE_ACCOUNT_SETTINGS}
                      component={AccountSettingsView}
                    />
                    <PrivateRoute
                      exact
                      authenticated={this.props.authenticated}
                      path="/admin-super"
                      component={AdminSuperDashboardView}
                    />
                    <PrivateRoute
                      exact
                      authenticated={this.props.authenticated}
                      path="/admin-super/dashboard"
                      component={AdminSuperDashboardView}
                    />
                    <PrivateRoute
                      exact
                      authenticated={this.props.authenticated}
                      path="/admin-super/dashboard/detail/*"
                      component={AdminSuperDashboardViewDetails}
                    />
                    <PrivateRoute
                      exact
                      authenticated={this.props.authenticated}
                      path="/admin-super/export-history"
                      component={AdminSuperDashboardExportHistory}
                    />
                    <PrivateRoute
                      exact
                      authenticated={this.props.authenticated}
                      path="/admin-super/export-history/download"
                      component={AdminSuperDashboardExportHistoryDownload}
                    />
                    <PrivateRoute
                      exact
                      authenticated={this.props.authenticated}
                      path="/admin-super/organizations"
                      component={AdminSuperDashboardOrganizations}
                    />
                    <PrivateRoute
                      exact
                      authenticated={this.props.authenticated}
                      path="/admin-super/organization"
                      component={AdminSuperDashboardOrganization}
                    />
                    <PrivateRoute
                      exact
                      authenticated={this.props.authenticated}
                      path="/admin-super/organization/users"
                      component={AdminSuperDashboardOrganizationsUsers}
                    />
                    <PrivateRoute
                      exact
                      authenticated={this.props.authenticated}
                      path="/admin-super/organization/devices"
                      component={AdminSuperDashboardOrganizationsDevices}
                    />
                    <PrivateRoute
                      exact
                      authenticated={this.props.authenticated}
                      path="/admin-super/organization/gateways"
                      component={AdminSuperDashboardOrganizationsGateways}
                    />
                    <Route>
                      <Redirect to={ROUTE_BASE} />
                    </Route>
                  </Switch>
                </div>
                <TemplateFooter>
                  <Link
                    as="a"
                    href="https://www.skf.com/group/footer/privacy-policy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage
                      defaultMessage="Privacy policy"
                      id="cPwv2c"
                    />
                  </Link>
                  <Link
                    as="a"
                    href="https://www.skf.com/group/footer/terms-and-conditions.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage
                      defaultMessage="Terms and conditions"
                      id="ZibE+c"
                    />
                  </Link>
                  <Link
                    as="a"
                    href="https://www.skf.com/group/footer/cookies.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage defaultMessage="Cookies" id="460C4x" />
                  </Link>
                  <Link
                    as="a"
                    href="https://www.skf.com/cm/tsg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage defaultMessage="Help" id="SENRqu" />
                  </Link>
                </TemplateFooter>
              </AppContainer>
            </Router>
          </IntlProvider>
        </div>
      </>
    );
  }
}

App.propTypes = {
  actions: PropTypes.shape({
    loadOrganization: PropTypes.func,
    getAccount: PropTypes.func.isRequired,
    loadTranslations: PropTypes.func.isRequired,
    completeRedirect: PropTypes.func.isRequired,
    screenResize: PropTypes.func.isRequired,
  }),
  translations: PropTypes.object,
  intlLoading: PropTypes.bool,
  intlLoaded: PropTypes.bool,
  locale: PropTypes.string,
  language: PropTypes.string,
  notifications: PropTypes.array,
  redirectTo: PropTypes.string,
  authenticated: PropTypes.bool,
  authLoading: PropTypes.bool,
  authLoaded: PropTypes.bool,
  inOrbit: PropTypes.bool,
  showLCPUpdateBanner: PropTypes.bool,
  isUserAfterCutoff: PropTypes.bool,
};

App.defaultProps = {
  translations: {},
  redirectTo: '',
};

PrivateRoute.propTypes = {
  component: PropTypes.object,
  location: PropTypes.object,
};

export default connect(mapState, mapDispatch)(App);
