import { Select } from '@skf-internal/ui-components-react-legacy';
import { ValidateOn } from 'helpers/propTypes';
import PropTypes from 'prop-types';
import React from 'react';
import { Control } from 'react-redux-form';

const ControlSelectField = ({
  model, id, validateOn, defaultValue, hint, items, size, rows, required, disabled, ...controlProps
}) => {
  return (
    <Control
      model={model}
      id={id}
      required={required}
      component={Select}
      validateOn={validateOn}
      defaultValue={defaultValue}
      disabled={disabled}
      controlProps={{
        feItems: items,
        feHint: hint,
        feSize: size,
        feRows: rows,
        ...controlProps
      }}
    />
  );
};

ControlSelectField.propTypes = {
  model: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  validateOn: ValidateOn,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  feLabel: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  size: PropTypes.oneOf(['sm', 'md']),
  rows: PropTypes.oneOf([5, 6, 7]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
};

ControlSelectField.defaultProps = {
  rows: 5,
};

export default ControlSelectField;
