import React from 'react';
import {
  Flexbox, Spacings, Spacer
} from '@skf-internal/ui-components-react-legacy';

import TextLabel from 'components/TextLabel';
import { Children } from 'helpers/propTypes';

const TemplateFooter = ({ children }) => {
  return (
    <>
      <Spacer feSpacing={Spacings.Md} />
      <Flexbox feGap={Spacings.Xxs} feJustifyContent="center" feFlexDirection="column">
        <Flexbox role="navigation" feGap={Spacings.Md} feJustifyContent="center" feFlexWrap="wrap">
          {children}
        </Flexbox>
        <Flexbox feJustifyContent="center">
          { (['acceptance', 'development', 'local'].includes(process.env.REACT_APP_ENV)) && (
            <TextLabel text={`${process.env.REACT_APP_ENV} | build${process.env.REACT_APP_BUILD_DATE} | ${process.env.REACT_APP_COMMIT_HASH}`} />
          )}
        </Flexbox>
      </Flexbox>
      <Spacer feSpacing={Spacings.Xxl} />
    </>
  );
};

TemplateFooter.propTypes = {
  children: Children
};

export default TemplateFooter;
