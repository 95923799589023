import SLoader from 'components/SLoader';
import Menu from 'containers/AdminSuperMenu';
import 'es6-shim';
import { colors } from 'helpers/constants';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as adminActions from 'redux/modules/admin';
import styled, { css } from 'styled-components';

const buttonStyle = css`
  background-color: ${({ primary }) => (primary ? colors.blue : colors.lightGray)};
  color: ${({ primary }) => (primary ? colors.white : colors.white)};
  display: block;
  font-size: 1.125rem;
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  border: 0;
  width: ${({ fullwidth }) => (fullwidth ? '100%' : '10rem')};
  margin: ${({ fullwidth }) => (fullwidth ? 0 : '1rem')};
  ${({ margin }) => margin && `margin: ${margin};`}
  cursor: pointer;
  @media (max-width: 784px) {
    width: 100%;
    // margin: 0 0 0 0;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const RootElement = styled.div`
  flex: 1 1 0%;

  button {
    float: right;
  }
`;

const Content = styled.div`
  margin: auto;
  display: flex;
  flex: 1 1 0%;
  width: 1280px;
  max-width: 100%;
  padding-bottom: 1rem;

  @media only screen and (max-width: 784px) {
    display: block;
  }
`;

const GrayBox = styled.div`
  background-color: ${colors.lightestGray};
  flex: 10 10 0%;
  padding: 1rem;
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 auto;
  width: 1280px;
  overflow: hidden;

  @media(max-width:576px) {
    display: block;
  }
`;

const HistoryTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  thead tr,
  tbody tr {
    background-color: white;
    border-bottom: 1px solid #ebf0f0;
  }

  td,
  th {
    text-align: left;
    padding: 0.3rem 1rem;
    white-space: normal;
    min-width: 50px;
  }

  .wider {
    min-width: 70px;
  }

  .fixedWidth {
    white-space: nowrap;
    word-break: unset;
  }
`;

const AgeLink = styled(Link)`
  ${buttonStyle}
  text-align: center;
  padding: 5px 0;
`;

function mapState(state) {
  const { admin } = state;
  return {
    admin
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...adminActions }, dispatch)
  };
}

class AdminSuperDashboardViewDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.data = {
      ages: [
        { value: 'PT3H', text: '3 Hours' },
        { value: 'P1D', text: '1 Day' },
        { value: 'P3D', text: '3 Days' },
        { value: 'P7D', text: '7 days' },
        { value: 'P14D', text: '14 Days' },
        { value: 'P1M', text: '1 Month' },
        { value: 'P3M', text: '3 Months' },
      ]
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadData();
    }
  }

  getAgeFromProps() {
    const params = queryString.parse(this.props.location.search);
    return params.age || 'PT3H';
  }

  loadData() {
    const age = this.getAgeFromProps();
    let ageInDays;
    switch (age) {
      case 'PT3H':
      case 'P1D':
        ageInDays = 1;
        break;
      case 'P3D':
        ageInDays = 3;
        break;
      case 'P7D':
        ageInDays = 7;
        break;
      case 'P14D':
        ageInDays = 14;
        break;
      case 'P1M':
        ageInDays = 31;
        break;
      case 'P3M':
        ageInDays = 93;
        break;
      default:
        ageInDays = 1;
    }
    this.props.actions.loadGraphData(this.props.match.params[0], ageInDays);
  }

  render() {
    const state = this.props.match.params[0];
    const age = this.getAgeFromProps();

    if (this.props.admin.loading) {
      return <SLoader />;
    }
    return (
      <RootElement>
        <Menu/>
        <GrayBox>
          <div>
            <Content>
              <h2>
                {`Connected devices stats: ${state.toUpperCase()}`}
              </h2>
            </Content>
            <Content>
              {this.data.ages.map(({ value, text }, i) => (
                <AgeLink
                  key={`age-${i}-${value}`}
                  className="brand"
                  to={`/admin-super/dashboard/detail/${state}?age=${value}`}
                  primary={age === value}
                >
                  {text}
                </AgeLink>
              ))}
            </Content>
            <Content>
              <h3>Graph</h3>
            </Content>
            <Content>
              <img src={`${process.env.REACT_APP_ADMIN_SUPER_ENDPOINT}/admin/all-devices/graphs?metric=${state}&width=1270&height=300&age=-${age}`} alt={state}/>
            </Content>
            <div>
              <Content>
                <h3>Table</h3>
              </Content>
              <ColumnsContainer>
                <HistoryTable tableborder="0">
                  <thead>
                    <tr>
                      {this.props.admin.data.header && this.props.admin.data.header.map(e => (
                        <th>{e}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <HistoryList history={this.props.admin.data.rows}/>
                  </tbody>
                </HistoryTable>
              </ColumnsContainer>
            </div>
          </div>
        </GrayBox>
      </RootElement>
    );
  }
}

const HistoryList = ({ history }) => {
  if (history) {
    return [...history].map(event => <HistyoryItem event={event} />);
  }
  return [];
};

const HistyoryItem = ({ event }) => {
  return (
    <tr key="{event}">
      {event.map(e => (
        <td>{e}</td>
      ))}
    </tr>
  );
};

HistyoryItem.propTypes = {
  event: PropTypes.object,
};

AdminSuperDashboardViewDetails.propTypes = {
  admin: PropTypes.object,
  match: PropTypes.any,
  location: PropTypes.any
};

AdminSuperDashboardViewDetails.propTypes = {
  actions: PropTypes.object,
};

export default connect(mapState, mapDispatch)(AdminSuperDashboardViewDetails);
