import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { omit } from 'lodash';
import {
  Flexbox, Spacings, Table, Text
} from '@skf-internal/ui-components-react-legacy';

import IconStatus from 'components/IconStatus';
import { AssetWithDataShape } from 'helpers/propTypes';
import { ALERT_LEVELS } from '../../helpers/constants';

const TableAssets = ({ assets, onTableBodyRowClick }) => {
  /**
   * Orders table by `Device statuses` column.
   *
   * **Ordering is relative to the amount of devices in the asset**.
   * That is, if there is only a single device that is in `ALERT` state
   * the asset will be ordered before another asset with three devices where
   * two devices are in `ALERT` state and one is in `DEVICE_OK` state.
   *
   * @returns Number of points that indicates if there are important alarms
   */
  const sortByAssetGroupState = ({
    assetGroupState
  }) => {
    if (!assetGroupState) {
      return '0';
    }

    /**
     * Arbitrary points used for ordering.
     * The greater the alarm the higher the point.
     */
    const points = {
      ALERT: 16,
      DEVICE_NO_CONNECTION: 8,
      WARNING: 4,
      DEVICE_OFF: 2,
      DEVICE_OK: 1,
    };

    return `${Object.keys(
      omit(assetGroupState, ['ASSET_GROUP_SIZE'])
    ).reduce(
      (previousValue, key) => previousValue + points[key] * assetGroupState[key],
      0
    ) / assetGroupState.ASSET_GROUP_SIZE}`;
  };

  const renderAssetsTableBody = () => {
    return assets.map(({
      assetGroupState, assetTag, assetName
    }) => {
      return (
        {
          cells: [
            {
              children: (assetGroupState && assetGroupState.ASSET_GROUP_SIZE) ? assetGroupState.ASSET_GROUP_SIZE : 0,
            },
            {
              children: (
                <>
                  {assetGroupState
                    ? (
                      <Flexbox feGap={Spacings.Xs} assetGroupState={assetGroupState}>
                        {assetGroupState.DEVICE_OK !== 0 && (
                        <Flexbox feAlignItems="center" as="span" feGap={Spacings.Xxs}>
                          <IconStatus level={ALERT_LEVELS.DEVICE_OK} />
                          {assetGroupState.DEVICE_OK}
                        </Flexbox>
                        )}
                        {assetGroupState.WARNING !== 0 && (
                        <Flexbox feAlignItems="center" as="span" feGap={Spacings.Xxs}>
                          <IconStatus level={ALERT_LEVELS.WARNING} />
                          {assetGroupState.WARNING}
                        </Flexbox>
                        )}
                        {assetGroupState.ALERT !== 0 && (
                        <Flexbox feAlignItems="center" as="span" feGap={Spacings.Xxs}>
                          <IconStatus level={ALERT_LEVELS.ALERT} />
                          {assetGroupState.ALERT}
                        </Flexbox>
                        )}
                        {assetGroupState.DEVICE_NO_CONNECTION !== 0 && (
                        <Flexbox feAlignItems="center" as="span" feGap={Spacings.Xxs}>
                          <IconStatus level={ALERT_LEVELS.DEVICE_NO_CONNECTION} />
                          {assetGroupState.DEVICE_NO_CONNECTION}
                        </Flexbox>
                        )}
                        {assetGroupState.DEVICE_OFF !== 0 && (
                        <Flexbox feAlignItems="center" as="span" feGap={Spacings.Xxs}>
                          <IconStatus level={ALERT_LEVELS.DEVICE_OFF} />
                          {assetGroupState.DEVICE_OFF}
                        </Flexbox>
                        )}
                      </Flexbox>
                    )
                    : '--'}
                </>
              ),
            },
            {
              children: assetTag,
            },
            {
              children: assetName,
            },
          ],
          onClick: () => {
            onTableBodyRowClick(assetTag);
          }
        }
      );
    });
  };

  const assetsTableHead = [
    {
      cells: [
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Device count" id="cfYFyG" />,
          scope: 'col',
          sortable: {}
        },
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Device statuses" id="cbUwFh" />,
          scope: 'col',
          sortable: {
            sortBy: sortByAssetGroupState
          }
        },
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Tag" id="18HJlm" />,
          scope: 'col',
          sortable: {}
        },
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Name" id="HAlOn1" />,
          scope: 'col',
          sortable: {}
        }
      ]
    }
  ];

  if (!assets || assets.length === 0) {
    return (
      <Text>
        <FormattedMessage defaultMessage="No {thing} or failed to load {thing}." id="EemuAN" values={{ thing: <FormattedMessage defaultMessage="assets" id="G359MS" /> }} />
      </Text>
    );
  }

  return (
    <Table
      caption="Assets table"
      feCompact={true}
      feBody={renderAssetsTableBody()}
      feHead={assetsTableHead}
      feInteractive
    />
  );
};

TableAssets.propTypes = {
  assets: PropTypes.arrayOf(AssetWithDataShape),
  onTableBodyRowClick: PropTypes.func.isRequired,
};

export default TableAssets;
