/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';
import { Button } from '@skf-internal/ui-components-react-legacy';

import { Children } from 'helpers/propTypes';

const ControlButton = ({
  model, disabled, feSize, type, feType, feVariant, feIcon, onClick, children,
}) => {
  return (
    <Control.button
      model={model}
      disabled={disabled}
      component={Button}
      type={type}
      controlProps={{
        children,
        feSize,
        feType,
        feVariant,
        feIcon,
        onClick,
      }}
    />
  );
};

ControlButton.propTypes = {
  children: Children.isRequired,
  model: PropTypes.string.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  feSize: PropTypes.oneOf(['small']),
  feType: PropTypes.oneOf(['primary', 'secondary']),
  feVariant: PropTypes.oneOf(['destructive', 'passive', 'success']),
  feIcon: PropTypes.shape({
    feIcon: PropTypes.string,
    position: PropTypes.oneOf(['left', 'right']),
  }),
  onClick: PropTypes.func,
};

export default ControlButton;
