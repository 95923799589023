import React from 'react';
import { FormattedMessage } from 'react-intl';

import TemplateDialogContent from 'components/TemplateDialogContent';
import { LubricantShape } from 'helpers/propTypes';

const DialogContentDeleteLubricant = ({ lubricant }) => {
  return (
    <TemplateDialogContent
      content={[
        {
          subheading: (
            <FormattedMessage
              defaultMessage="{thing} details"
              values={{ thing: <FormattedMessage defaultMessage="Lubricant" id="xzBmxi" /> }}
              id="2Q8Pzz"
            />
          ),
          items: [
            {
              id: 'denomination',
              children: (
                <FormattedMessage
                  defaultMessage="Lubricant name: {denomination}"
                  id="46uxqW"
                  values={{ denomination: lubricant.denomination }}
                />
              )
            },
            {
              id: 'manufacturerName',
              children: (
                <FormattedMessage
                  defaultMessage="Manufacturer: {manufacturerName}"
                  id="kcpLA1"
                  values={{ manufacturerName: lubricant.manufacturerName }}
                />
              ),
            },
            {
              id: 'lubricantType',
              children: (
                <FormattedMessage
                  defaultMessage="Lubricant type: {lubricantType}"
                  id="2hj3Ep"
                  values={{ lubricantType: lubricant.lubricantType }}
                />
              )
            }
          ]
        }
      ]}
    />
  );
};

DialogContentDeleteLubricant.defaultProps = {
  lubricant: {
    denomination: '--',
    manufacturerName: '--',
    lubricantType: '--',
  }
};

DialogContentDeleteLubricant.propTypes = {
  lubricant: LubricantShape,
};

export default DialogContentDeleteLubricant;
