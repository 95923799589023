import React from 'react';
import PropTypes from 'prop-types';
import {
  Flexbox, Spacer, Spacings
} from '@skf-internal/ui-components-react-legacy';

import ActionLinkBack from 'components/ActionLinkBack';
import { Children } from 'helpers/propTypes';

const TemplateContent = ({ children, heading, linkBack }) => {
  return (
    <Flexbox feFlexDirection="column">
      <div>
        <ActionLinkBack to={linkBack.to} onClick={linkBack.onClick} />
      </div>
      <Spacer feSpacing={Spacings.Xxl} />
      {heading}
      {children}
    </Flexbox>
  );
};

TemplateContent.propTypes = {
  children: Children.isRequired,
  heading: PropTypes.node,
  linkBack: PropTypes.shape({
    to: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }).isRequired,
};

export default TemplateContent;
