import React from 'react';
import {
  Redirect, Route, Switch as RouterSwitch
} from 'react-router-dom';

import TabsAssetsAndDevices from 'components/TabsAssetsAndDevices';
import AddAssetView from 'containers/AddAssetView';
import AssetDetailsView from 'containers/AssetDetailsView';
import DeviceDetailsView from 'containers/DeviceDetailsView';
import AddDeviceView from 'containers/AddDeviceView/AddDeviceView';
import { ROUTE_DASHBOARD_ASSETS_AND_DEVICES, ROUTE_DASHBOARD_DEVICES, ROUTE_DASHBOARD_ASSETS } from 'helpers/constants';

const DashboardAssetsAndDevicesView = () => {
  return (
    <RouterSwitch>
      <Route exact path={ROUTE_DASHBOARD_ASSETS_AND_DEVICES}>
        <Redirect to={ROUTE_DASHBOARD_ASSETS} />
      </Route>
      <Route exact path={`${ROUTE_DASHBOARD_DEVICES}/add-device`} component={AddDeviceView} />
      <Route exact path={`${ROUTE_DASHBOARD_DEVICES}/:deviceId`} component={DeviceDetailsView} />
      <Route exact path={`${ROUTE_DASHBOARD_ASSETS}/add-asset`} component={AddAssetView} />
      <Route exact path={`${ROUTE_DASHBOARD_ASSETS}/:assetTag/add-device`} component={AddDeviceView} />
      <Route exact path={`${ROUTE_DASHBOARD_ASSETS}/:assetTag`} component={AssetDetailsView} />
      <Route path={ROUTE_DASHBOARD_ASSETS_AND_DEVICES}>
        <TabsAssetsAndDevices />
      </Route>
    </RouterSwitch>
  );
};

export default DashboardAssetsAndDevicesView;
