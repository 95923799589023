import React from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';
import { TextField } from '@skf-internal/ui-components-react-legacy';

const ControlInputText = ({
  model, id, hint, placeholder, required, label, hideLabel = false
}) => {
  return (
    <Control
      model={model}
      feLabel={label}
      feHideLabel={hideLabel}
      feHint={hint}
      id={id}
      placeholder={placeholder}
      required={required}
      component={TextField}
    />
  );
};

ControlInputText.propTypes = {
  model: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  required: PropTypes.bool
};

ControlInputText.defaultProps = {
  required: false,
};

export default ControlInputText;
