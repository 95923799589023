import {
  Heading,
  Link as FeLink, Spacer,
  Spacings, Text
} from '@skf-internal/ui-components-react-legacy';
import FormDevice from 'components/FormDevice';
import SLoader from 'components/SLoader';
import TemplateContent from 'components/TemplateContent';
import TemplatePersistingNotification from 'components/TemplatePersistingNotification';
import 'es6-shim';
import {
  CARTRIDGES,
  LUBRICANTS,
  ROUTE_DASHBOARD_ASSETS
} from 'helpers/constants';
import { AssetShape, DeviceShape, LubricantShape } from 'helpers/propTypes';
import { emptySettings } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  Link, useParams, withRouter
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as assetActions from 'redux/modules/asset';
import * as deviceActions from 'redux/modules/device';
import * as lubricantActions from 'redux/modules/lubricant';

function mapState(state) {
  const {
    asset: { assets, loading: assetLoading, loaded: assetsLoaded },
    device: { devices, loading: devicesLoading, loaded: devicesLoaded },
    lubricant: { lubricants: { items: lubricants, loading: lubricantLoading, loaded: lubricantsLoaded } },
  } = state;
  const loading = assetLoading || devicesLoading || lubricantLoading;

  return {
    loading,
    assets,
    assetsLoaded,
    devices,
    devicesLoaded,
    lubricants,
    lubricantsLoaded,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...assetActions,
        ...deviceActions,
        ...lubricantActions,
      },
      dispatch
    ),
  };
}

const AddDeviceView = ({
  actions, assets, assetsLoaded, devices, devicesLoaded, lubricants, lubricantsLoaded, loading
}) => {
  const { assetTag } = useParams();

  useEffect(() => {
    if (!assetsLoaded) {
      actions.loadAssets();
    }

    if (!devicesLoaded) {
      actions.loadDevices();
    }

    if (!lubricantsLoaded) {
      actions.loadLubricants();
    }
  }, []);

  const handleSubmit = values => {
    // Convert values from UI values to back-end values
    const device = {
      ...values,
      deviceConfig: {
        emptyingTime: parseInt(values.deviceConfig.emptyingTime, 10),
        cartridgeSize: parseInt(values.deviceConfig.cartridgeSize, 10),
        lubricant: values.deviceConfig.lubricant,
      }
    };

    return actions.createDevice(device);
  };

  if (loading) {
    return <SLoader />;
  }

  if (!assets || assets.length === 0) {
    return (
      <TemplateContent
        heading={(
          <Heading as="h2">
            <FormattedMessage
              defaultMessage="Add {thing}"
              id="SziwLc"
              values={{ thing: <FormattedMessage defaultMessage="device" id="eY2JfQ" /> }}
            />
          </Heading>
        )}
        linkBack={{
          to: '/dashboard/assets-and-devices/devices'
        }}
      >
        <Spacer feSpacing={Spacings.Xl} />
        <Text>
          <FormattedMessage
            defaultMessage="You need to {thing} before you can add a device."
            id="j7pyCQ"
            values={{
              thing: (
                <Link to={`${ROUTE_DASHBOARD_ASSETS}/add-asset`}>
                  <FeLink
                    as="button"
                    type="button"
                  >
                    <FormattedMessage defaultMessage="add an asset" id="H6jju+" />
                  </FeLink>
                </Link>
              )
            }}
          />
        </Text>
      </TemplateContent>
    );
  }

  // Set the first asset with locations as the default asset or use the location path parameter `assetTag`.
  const asset = assetTag ? assets.find(a => a.assetTag === decodeURIComponent(assetTag)) : assets.find(a => a.locations.length > 0);

  return (
    <TemplateContent
      heading={(
        <Heading as="h2">
          <FormattedMessage
            defaultMessage="Add {thing}"
            id="SziwLc"
            values={{ thing: <FormattedMessage defaultMessage="device" id="eY2JfQ" /> }}
          />
        </Heading>
      )}
      linkBack={{
        to: '/dashboard/assets-and-devices/devices'
      }}
    >
      <Spacer feSpacing={Spacings.Xl} />
      {(!asset && assets.length > 0) ? (
        <>
          <TemplatePersistingNotification feType="notification">
            <Text>
              <FormattedMessage
                defaultMessage="You have no assets with lubrication points. Please add a lubrication point to one of your assets. {link}."
                id="u5xZaw"
                values={{ link: <Link to="/dashboard/assets-and-devices/assets"><FormattedMessage defaultMessage="Go to assets list" id="JgSFXN" /></Link> }}
              />
            </Text>
          </TemplatePersistingNotification>
          <Spacer feSpacing={Spacings.Md} />
        </>
      ) : (
        <FormDevice
          assets={assets}
          devices={devices}
          lubricants={lubricants}
          initialState={{
            assetTag: asset.assetTag,
            assetName: asset.assetName,
            application: asset.application,
            location: asset.locations[0],
            deviceId: '',
            deviceConfig: {
              cartridgeSize: CARTRIDGES[0],
              lubricant: LUBRICANTS[0].denomination,
              emptyingTime: emptySettings()[0].value,
            },
            deviceType: ''
          }}
          onSubmit={handleSubmit}
        />
      )}
    </TemplateContent>
  );
};

AddDeviceView.propTypes = {
  actions: PropTypes.shape({
    loadAssets: PropTypes.func,
    loadDevices: PropTypes.func,
    loadLubricants: PropTypes.func,
    createDevice: PropTypes.func,
  }),
  assets: PropTypes.arrayOf(AssetShape),
  assetsLoaded: PropTypes.bool,
  devices: PropTypes.arrayOf(DeviceShape),
  devicesLoaded: PropTypes.bool,
  lubricants: PropTypes.arrayOf(LubricantShape),
  lubricantsLoaded: PropTypes.bool,
  loading: PropTypes.bool,
};

AddDeviceView.contextTypes = {
  store: PropTypes.object,
};

export default withRouter(connect(mapState, mapDispatch)(AddDeviceView));
