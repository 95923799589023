import {
  Link, Text
} from '@skf-internal/ui-components-react-legacy';
import TemplateDialogContent from 'components/TemplateDialogContent';
import TemplatePersistingNotification from 'components/TemplatePersistingNotification';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const DialogContentUploadDeviceKeys = ({ deviceKeysTemplateUrl, fileInputRef }) => {
  return (
    <TemplateDialogContent
      content={[
        {
          subheading: <FormattedMessage defaultMessage="Upload a CSV file. Columns should be" id="ow5mJK" />,
          items: [
            {
              id: 'productId',
              children: <FormattedMessage defaultMessage="Column 1: Product Identifier" id="bYZujb" />,
            },
            {
              id: 'nwKey',
              children: <FormattedMessage defaultMessage="Column 2: NWKEY" id="hU+Qfd" />
            },
            {
              id: 'appKey',
              children: <FormattedMessage defaultMessage="Column 3: APPKEY" id="CxREoL" />
            },
            {
              id: 'deviceId',
              children: <FormattedMessage defaultMessage="Column 4: Device identifier" id="9op+P1" />
            },
            {
              id: 'serialNumber',
              children: <FormattedMessage defaultMessage="Column 5: Serial number" id="VHctjr" />
            },
            {
              id: 'productType',
              children: <FormattedMessage defaultMessage="Column 6: Product type" id="tabLVm" />
            },
          ]
        }
      ]}
    >
      <TemplatePersistingNotification>
        <Text>
          <FormattedMessage
            defaultMessage="You can only upload keys for TLDDv1 lubricators!"
            id="/+luHK"
          />
        </Text>
      </TemplatePersistingNotification>
      <Link as="a" href={deviceKeysTemplateUrl} download="template.csv">
        <FormattedMessage defaultMessage="Download a template" id="Bu/sXA" />
      </Link>
      <input type="file" ref={fileInputRef} />
    </TemplateDialogContent>
  );
};

DialogContentUploadDeviceKeys.propTypes = {
  deviceKeysTemplateUrl: PropTypes.string,
  fileInputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

export default DialogContentUploadDeviceKeys;
