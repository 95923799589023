import { ROUTE_ORGANIZATION } from 'helpers/constants';
import { createSelector } from 'reselect';
import { setCurrentLanguageCode } from 'storage/localStorage';
import { loadTranslations } from './intl';
import { displayErrorMessage, displaySuccessMessage } from './notification';
import { loadOrganizationUsers } from './organization';
import {
  FINISH_AUTH, FINISH_LOGIN, FINISH_LOGIN_ADMIN_SUPER, redirectTo
} from './redirect';

const ACCOUNT = 'myrina/auth/ACCOUNT';
const ACCOUNT_SUCCESS = 'myrina/auth/ACCOUNT_SUCCESS';
const ACCOUNT_FAIL = 'myrina/auth/ACCOUNT_FAIL';

const INVITE = 'railway/auth/INVITE';
const INVITE_SUCCESS = 'railway/auth/INVITE_SUCCESS';
const INVITE_FAIL = 'railway/auth/INVITE_FAIL';

const SETTINGS = 'myrina/auth/SETTINGS';
const SETTINGS_SUCCESS = 'myrina/auth/SETTINGS_SUCCESS';
const SETTINGS_FAIL = 'myrina/auth/SETTINGS_FAIL';

const SUBMIT_FEEDBACK = 'myrina/feedback/SUBMIT_FEEDBACK';
const SUBMIT_FEEDBACK_SUCCESS = 'myrina/feedback/SUBMIT_FEEDBACK_SUCCESS';
const SUBMIT_FEEDBACK_FAILURE = 'myrina/feedback/SUBMIT_FEEDBACK_FAILURE';

const SET_TOKEN = 'datalake/auth/SET_TOKEN';

const initialState = {
  loaded: false,
  loading: true,
  authenticated: false,
  userSession: {},
};

export default function auth(state = initialState, action = {}) {
  switch (action.type) {
    case ACCOUNT:
      return {
        ...state,
        loaded: false,
        loading: true,
        error: false,
      };
    case ACCOUNT_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        ...action.result
      };
    case ACCOUNT_FAIL:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    case FINISH_AUTH:
    case FINISH_LOGIN: {
      // From login or localStorage
      const { idToken, accessToken, refreshToken } = action.payload;

      return {
        ...state,
        authenticated: true,
        userSession: {
          ...state.userSession,
          idToken,
          accessToken,
          refreshToken,
        },
      };
    }
    case FINISH_LOGIN_ADMIN_SUPER: {
      // From login
      const { idToken, accessToken } = action.payload;

      return {
        ...state,
        authenticated: true,
        userSession: {
          ...state.userSession,
          idToken,
          accessToken
        },
      };
    }
    case SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedback: action.result
      };
    case SET_TOKEN: {
      // From login
      const { idToken, accessToken, refreshToken } = action.payload;

      // From refresh
      const { IdToken, AccessToken } = action.payload;

      return {
        ...state,
        userSession: {
          ...state.userSession,
          idToken: idToken || IdToken,
          accessToken: accessToken || AccessToken,
          refreshToken: refreshToken || state.userSession.refreshToken,
        },
      };
    }
    default:
      return state;
  }
}

export function getAccount() {
  return {
    types: [ACCOUNT, ACCOUNT_SUCCESS, ACCOUNT_FAIL],
    promise: client => {
      return client.get('/customer/account');
    }
  };
}

export function savePreferences(preferences) {
  const data = { ...preferences, lastEmailAt: 0 };

  return {
    types: [SETTINGS, SETTINGS_SUCCESS, SETTINGS_FAIL],
    promise: (client, dispatch) => {
      return client
        .put('/customer/account', { data })
        .then(() => {
          dispatch(
            loadTranslations(preferences.language)
          );
          setCurrentLanguageCode(preferences.language);
          dispatch(getAccount()); // reload user data
        });
    }
  };
}

export function saveSettings(settings) {
  const data = { ...settings, lastEmailAt: 0 };
  return {
    types: [SETTINGS, SETTINGS_SUCCESS, SETTINGS_FAIL],
    promise: (client, dispatch) => {
      return client
        .put('/customer/account', { data })
        .then(() => {
          dispatch(getAccount()); // reload user data
        });
    }
  };
}

export function submitFeedback(feedbackData) {
  return {
    types: [SUBMIT_FEEDBACK, SUBMIT_FEEDBACK_SUCCESS, SUBMIT_FEEDBACK_FAILURE],
    promise: client => {
      return client
        .put('/customer/feedback/', { data: feedbackData });
    }
  };
}

export function setTokens(tokens) {
  return {
    type: SET_TOKEN,
    payload: tokens,
  };
}

export function invite(params) {
  return {
    types: [INVITE, INVITE_SUCCESS, INVITE_FAIL],
    promise: (client, dispatch) => {
      return client
        .post('/customer/invite', { data: params })
        .then(res => {
          dispatch(loadOrganizationUsers());
          dispatch(redirectTo(ROUTE_ORGANIZATION));
          dispatch(displaySuccessMessage({
            id: '4JbJnD'
          }));
          return res;
        })
        .catch(() => {
          dispatch(loadOrganizationUsers());
          dispatch(redirectTo(ROUTE_ORGANIZATION));
          dispatch(displayErrorMessage({
            id: '4BzSsa'
          }));
        });
    }
  };
}

export function finishAuth(tokens, typevalue = FINISH_AUTH) {
  return {
    type: typevalue,
    payload: tokens,
  };
}

export function finishLogin(tokens, typevalue = FINISH_LOGIN) {
  return {
    type: typevalue,
    payload: tokens,
  };
}

// Parse payload from JWT token
export function parseJwtTokenPayload(token) {
  const payload = token.split('.')[1];
  try {
    return JSON.parse(atob(payload).toString('utf8'));
  }
  catch (err) {
    return {};
  }
}

export const isAuthenticated = createSelector(state => state.auth.authenticated, authenticated => authenticated);

export const getAccessToken = createSelector(state => state.auth.userSession, userSession => {
  if (!userSession.accessToken) {
    return null;
  }

  return userSession.accessToken;
});

export const getIdToken = createSelector(state => state.auth.userSession, userSession => {
  if (!userSession.idToken) {
    return null;
  }

  return userSession.idToken;
});

export const getRefreshToken = createSelector(state => state.auth.userSession, userSession => {
  if (!userSession.refreshToken) {
    return null;
  }
  return userSession.refreshToken;
});

export const isAccountLoaded = createSelector(state => state.auth.loading, state => state.auth.loaded, (loading, loaded) => loaded || loading);
