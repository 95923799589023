import React from 'react';
import PropTypes from 'prop-types';
import { Flexbox, Spacings } from '@skf-internal/ui-components-react-legacy';

import ActionButtonEdit from 'components/ActionButtonEdit';
import ActionLinkDelete from 'components/ActionLinkDelete';

const TemplateDetailsActions = ({ edit, delete: deleteProps }) => {
  return (
    <Flexbox feGap={Spacings.Md}>
      <ActionButtonEdit
        {...edit}
      />
      <ActionLinkDelete
        {...deleteProps}
      />
    </Flexbox>
  );
};

TemplateDetailsActions.propTypes = {
  edit: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.bool,
  }),
  delete: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.bool,
  }),
};

export default TemplateDetailsActions;
