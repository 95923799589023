import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading,
  Spacer,
  Spacings,
} from '@skf-internal/ui-components-react-legacy';

import TemplateContainer from 'components/TemplateContainer';

const TemplateView = ({ heading, children }) => {
  return (
    <TemplateContainer>
      <Heading as="h1">
        {heading}
      </Heading>
      <Spacer feSpacing={Spacings.Lg} />
      {children}
    </TemplateContainer>
  );
};

TemplateView.propTypes = {
  heading: PropTypes.node.isRequired,
  children: PropTypes.any,
};

export default TemplateView;
