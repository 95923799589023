import {
  Card, Flexbox, FontSizes, Spacer, Spacings, Text
} from '@skf-internal/ui-components-react-legacy';
import { CARD_STYLE } from 'helpers/constants';
import PropTypes from 'prop-types';
import React from 'react';

export const HEIGHT_DEFAULT = 42;
export const HEIGHT_MEDIUM = 38;
export const HEIGHT_SMALL = 28;

const TemplateInsightItem = ({
  img, number, text
}) => {
  return (
    <Card style={CARD_STYLE} >
      <Flexbox feFlexDirection="column" feAlignItems="center">
        <Flexbox feFlexDirection="row" feAlignItems="center" feGap={Spacings.Sm}>
          {img && (
            <img
              src={img.src}
              height={img.height || HEIGHT_DEFAULT}
              alt={img.alt}
            />
          )}
          <Text feFontSize={FontSizes.Xxxl}>
            {number || '--'}
          </Text>
        </Flexbox>
        <Spacer feSpacing={Spacings.Md} />
        <Text feFontSize={FontSizes.Lg}>
          {text}
        </Text>
      </Flexbox>
    </Card>
  );
};

TemplateInsightItem.propTypes = {
  img: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  number: PropTypes.node,
  text: PropTypes.node,
};

export default TemplateInsightItem;
