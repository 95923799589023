import {
  finishAuth, finishLogin, getAccount, isAccountLoaded, isAuthenticated
} from 'redux/modules/auth';
import { FINISH_LOGIN_ADMIN_SUPER } from 'redux/modules/redirect';
import { getAuthTokensFromLocalStorage } from './storageMiddleware';

function extractTokensFromHash(hash) {
  return hash.replace('#', '')
    .split('&')
    .reduce(
      (prev, item) => ({
        ...prev,
        [item.split('=')[0]]: item.split('=')[1]
      }),
      {}
    );
}

export default function authMiddleware(type = 'user') { // Default should be user
  return ({ getState, dispatch }) => next => action => {
    const result = next(action);
    const state = getState();

    if (type === 'user') {
      return authUser(result, state, dispatch);
    }

    return authAdminSuper(result, state, dispatch);
  };

  function authUser(result, state, dispatch) {
    if (isAuthenticated(state) && !isAccountLoaded(state)) {
      dispatch(getAccount());
    }

    if (isAuthenticated(state)) {
      return result;
    }

    let tokens = getAuthTokensFromLocalStorage();

    if (window.location.hash.includes('#token=')) {
      tokens = extractTokensFromHash(window.location.hash);
      dispatch(finishLogin(tokens));
    }
    else if (tokens) {
      dispatch(finishAuth(tokens));
    }
    else {
      window.location = `${process.env.REACT_APP_SSO_URL}/?app=${process.env.REACT_APP_SSO_APPKEY}`;
    }

    return result;
  }

  function authAdminSuper(result, state, dispatch) {
    if (isAuthenticated(state)) {
      return result;
    }

    if (!window.location.hash.includes('&access_token=')) {
      window.location = `https://${process.env.REACT_APP_COGNITO_DOMAIN_ADMIN_SUPER}/login?response_type=token&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID_ADMIN_SUPER}&redirect_uri=${process.env.REACT_APP_DOMAIN}/admin-super`;
    }

    if (window.location.hash.includes('&access_token=')) {
      const tokens = extractTokensFromHash(window.location.hash);
      const { access_token: accessToken, id_token: idToken } = tokens;

      dispatch(finishLogin({ accessToken, idToken }, FINISH_LOGIN_ADMIN_SUPER));
    }

    return result;
  }
}
