import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Breakpoints, Spacings } from '@skf-internal/ui-components-react-legacy';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${Spacings.Md} 0;

  @media screen and (min-width: ${Breakpoints.Mobile}px) {
    margin: ${Spacings.Md} 2vw;
  }
  
  @media screen and (min-width: ${Breakpoints.Tablet}px) {
    margin: ${Spacings.Xxl} 4vw;
  }
  
  @media screen and (min-width: ${Breakpoints.Desktop}px) {
    margin: ${Spacings.Xxl} 8vw;
  }

  @media screen and (min-width: ${Breakpoints.Widescreen}px) {
    margin: ${Spacings.Xxl} 16vw;
  }
`;

const Container = ({ children }) => {
  return (
    <StyledContainer>{children}</StyledContainer>
  );
};

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Container;
