/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Breakpoints,
  Card,
  Flexbox,
  Grid,
  GridItem,
  Heading, Search, Spacer,
  Spacings,
  Text
} from '@skf-internal/ui-components-react-legacy';
import ActionButtonAdd from 'components/ActionButtonAdd';
import FormOrganization from 'components/FormOrganization';
import SLoader from 'components/SLoader';
import TableOrganizationUsers from 'components/TableOrganizationUsers';
import TemplateSection from 'components/TemplateSection';
import TemplateView from 'components/TemplateView';
import InviteUserView from 'containers/InviteUserView';
import { ROUTE_ORGANIZATION } from 'helpers/constants';
import { AuthShape, OrganizationUserShape } from 'helpers/propTypes';
import useFuse from 'hooks/useFuse';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  Route, Switch as RouterSwitch
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as authActions from 'redux/modules/auth';
import * as organizationActions from 'redux/modules/organization';

function mapState(state) {
  const {
    auth,
    organization: {
      loading: loadingOrg,
      loaded: loadedOrg,
      system,
      users: { data: users, loading: loadingUsers, loaded: loadedUsers },
    },
    ui: { width },
  } = state;

  return {
    auth,
    loadingAuth: auth.loading,
    system,
    loadingOrg,
    loadedOrg,
    users,
    loadingUsers,
    loadedUsers,
    width,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({
      ...authActions,
      ...organizationActions,
    }, dispatch)
  };
}

const OrganizationView = ({
  actions,
  auth,
  loadingAuth,
  system,
  loadingOrg,
  loadedOrg,
  users,
  loadingUsers,
  loadedUsers,
  width
}) => {
  const intl = useIntl();
  const { hits, onSearch, onReset } = useFuse(users, {
    keys: ['email'],
    matchAllOnEmptyQuery: true,
    shouldSort: false,
    threshold: 0,
    ignoreLocation: true
  });

  useEffect(() => {
    if (!loadedOrg) {
      actions.loadOrganization();
    }

    if (!loadedUsers) {
      actions.loadOrganizationUsers();
    }

    if (!auth.loaded) {
      actions.getAccount();
    }
  }, []);

  const handleEditSubmit = values => {
    return actions.updateOrganization(values);
  };

  const handleUserRemove = removeUser => {
    if (removeUser !== undefined) {
      // In some cases the user doesn't have sub, but the `userId` is in `email`
      actions.removeOrganizationUser(removeUser.sub || removeUser.email);
    }
  };

  if (loadingAuth || loadingOrg) {
    return <SLoader />;
  }

  return (
    <TemplateView heading={<FormattedMessage defaultMessage="Organization" id="K56Dim" />}>
      <RouterSwitch>
        <Route exact path={ROUTE_ORGANIZATION}>
          <Card>
            <TemplateSection>
              <Text>
                <FormattedMessage
                  defaultMessage="In this view you can manage the organization and its users."
                  id="uHLlwo"
                />
              </Text>
            </TemplateSection>
            <TemplateSection>
              <Heading as="h3">
                <FormattedMessage
                  defaultMessage="Organization details"
                  id="vzfBiK"
                />
              </Heading>
              <Spacer feSpacing={Spacings.Md} />
              <Grid>
                <GridItem feColspan={{ desktop: 5, tablet: 6, mobile: 12 }}>
                  <FormOrganization onSubmit={handleEditSubmit} initialState={{ system }} />
                </GridItem>
              </Grid>
            </TemplateSection>
            <TemplateSection>
              <Heading as="h3">
                <FormattedMessage
                  defaultMessage="Organization users table"
                  id="zBj224"
                />
              </Heading>
              <Spacer feSpacing={Spacings.Md} />
              <Flexbox
                feGap={Spacings.Md}
                feJustifyContent="space-between"
                feFlexDirection={width >= Breakpoints.Tablet ? 'row' : 'column'}
                feAlignItems={width >= Breakpoints.Tablet ? 'center' : 'flex-start'}
              >
                <Flexbox>
                  <Search
                    onChange={onSearch}
                    feResetButton={{
                      onClick: onReset
                    }}
                    placeholder={(intl.formatMessage({ defaultMessage: 'Search...', id: '0BUTMv' }))}
                    feHint={(
                      intl.formatMessage(
                        {
                          defaultMessage: 'Search by {list}',
                          id: 'UlWvQl',
                        },
                        {
                          list: intl.formatList([
                            intl.formatMessage({
                              defaultMessage: 'Email', id: 'sy+pv5',
                            }),
                          ], { type: 'conjunction' })
                        }
                      )
                    )}
                    feLabel="Search by Email"
                    feHideLabel
                  />
                </Flexbox>
                <Flexbox
                  feGap={Spacings.Md}
                  feJustifyContent="flex-end"
                  feAlignItems="center"
                >
                  <ActionButtonAdd
                    to={`${ROUTE_ORGANIZATION}/invite-user`}
                    text={(
                      <FormattedMessage
                        defaultMessage="Invite user"
                        id="/4GN+O"
                      />
)}
                  />
                </Flexbox>
              </Flexbox>
            </TemplateSection>
            <TableOrganizationUsers users={hits.map(hit => hit.item)} auth={auth} loading={loadingUsers} onDeleteConfirmClick={handleUserRemove} />
          </Card>
        </Route>
        <Route exact path={`${ROUTE_ORGANIZATION}/invite-user`} component={InviteUserView} />
      </RouterSwitch>
    </TemplateView>
  );
};

OrganizationView.propTypes = {
  actions: PropTypes.shape({
    getAccount: PropTypes.func,
    loadOrganization: PropTypes.func,
    loadOrganizationUsers: PropTypes.func,
    updateOrganization: PropTypes.func,
    removeOrganizationUser: PropTypes.func,
  }),
  auth: AuthShape,
  loadingAuth: PropTypes.bool,
  system: PropTypes.string,
  loadingOrg: PropTypes.bool,
  loadedOrg: PropTypes.bool,
  users: PropTypes.arrayOf(OrganizationUserShape),
  loadingUsers: PropTypes.bool,
  loadedUsers: PropTypes.bool,
  width: PropTypes.number,
};

export default connect(mapState, mapDispatch)(OrganizationView);
