import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Link,
  Text,
} from '@skf-internal/ui-components-react-legacy';

import TemplateDialogContent from 'components/TemplateDialogContent';
import TemplatePersistingNotification from 'components/TemplatePersistingNotification';
import { MAX_UPLOAD_COUNT_DEVICES } from 'helpers/constants';

const DialogContentUploadDevices = ({ devicesTemplateUrl, fileInputRef }) => {
  return (
    <TemplateDialogContent
      content={[
        {
          subheading: <FormattedMessage defaultMessage="Upload a CSV file. Columns should be" id="ow5mJK" />,
          items: [
            {
              id: 'assetTag',
              children: <FormattedMessage defaultMessage="Column 1: Asset tag" id="KsK/Y8" />,
            },
            {
              id: 'location',
              children: <FormattedMessage defaultMessage="Column 2: Lubrication point" id="r4H902" />
            },
            {
              id: 'deviceId',
              children: <FormattedMessage defaultMessage="Column 3: Device ID" id="U6ag3L" />
            },
            {
              id: 'cartridgeSize',
              children: <FormattedMessage defaultMessage="Column 4: Cartridge size" id="GbG0Vo" />
            },
            {
              id: 'lubricant',
              children: <FormattedMessage defaultMessage="Column 5: Lubricant" id="p2RQ45" />
            },
            {
              id: 'emptyingTime',
              children: <FormattedMessage defaultMessage="Column 5: Setting (emptying time)" id="7+FzEw" />
            },
          ]
        }
      ]}
    >
      <Link as="a" href={devicesTemplateUrl} download="template.csv">
        <FormattedMessage defaultMessage="Download a template" id="Bu/sXA" />
      </Link>
      <input type="file" ref={fileInputRef} />
      <TemplatePersistingNotification>
        <Text>
          <FormattedMessage
            defaultMessage="You can only upload {n} {thing} at once!"
            values={{
              n: MAX_UPLOAD_COUNT_DEVICES,
              thing: <FormattedMessage defaultMessage="devices" id="wRUXwz" />
            }}
            id="7mkEyV"
          />
        </Text>
      </TemplatePersistingNotification>
    </TemplateDialogContent>
  );
};

DialogContentUploadDevices.propTypes = {
  devicesTemplateUrl: PropTypes.string,
  fileInputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

export default DialogContentUploadDevices;
