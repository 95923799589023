import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Text
} from '@skf-internal/ui-components-react-legacy';

import { LubricantManufacturerShape } from 'helpers/propTypes';
import { FormattedMessage } from 'react-intl';

const TableLubricantManufacturers = ({ manufacturers, onTableBodyRowClick }) => {
  const renderTableLubricantManufacturersBody = () => {
    return manufacturers.map(({
      name
    }) => {
      return (
        {
          cells: [
            {
              children: name,
            },
          ],
          onClick: () => {
            onTableBodyRowClick(name);
          }
        }
      );
    });
  };

  const lubricantManufacturersTableHead = [
    {
      cells: [
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Name" id="HAlOn1" />,
          scope: 'col',
          sortable: {}
        },
      ]
    }
  ];

  if (!manufacturers || manufacturers.length === 0) {
    return (
      <Text>
        <FormattedMessage
          defaultMessage="No {thing} or failed to load {thing}."
          id="EemuAN"
          values={{
            thing: <FormattedMessage
              defaultMessage="lubricant manufacturers"
              id="3W5pLU"
            />
          }}
        />
      </Text>
    );
  }

  return (
    <Table
      caption="Lubricant manufacturers table"
      feCompact={true}
      feBody={renderTableLubricantManufacturersBody()}
      feHead={lubricantManufacturersTableHead}
      feInteractive
    />
  );
};

TableLubricantManufacturers.propTypes = {
  manufacturers: PropTypes.arrayOf(LubricantManufacturerShape),
  onTableBodyRowClick: PropTypes.func,
};

export default TableLubricantManufacturers;
