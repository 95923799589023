import SLoader from 'components/SLoader';
import Menu from 'containers/AdminSuperMenu';
import 'es6-shim';
import { colors } from 'helpers/constants';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Control, LocalForm } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as adminActions from 'redux/modules/admin';
import styled, { css } from 'styled-components';

const StyledTextInput = styled(Control.text)`
  background-color: ${colors.white};
  color: ${colors.darkestGray};
  display: flex;
  flex: initial;
  width: 100%;
  font-size: 1rem;
  font-family: Arial;
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  box-sizing: border-box;
  &::placeholder {
    color: ${colors.lighterGray};
  }

  &:focus:read-only {
    outline: none;
  }
`;

const GrayTextInput = styled(StyledTextInput)`
  background-color: ${colors.lighterGray};
  color: ${colors.darkestGray};
`;

const ThinTextInput = styled(StyledTextInput)`
  padding: 0.5rem 0.5rem;
  height: 38px;
  margin-bottom: 0;
`;

const buttonStyle = css`
  background-color: ${({ primary }) => (primary ? colors.blue : colors.lightGray)};
  color: ${({ primary }) => (primary ? colors.white : colors.white)};
  display: block;
  font-size: 1.125rem;
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  border: 0;
  width: ${({ fullwidth }) => (fullwidth ? '100%' : '10rem')};
  margin: ${({ fullwidth }) => (fullwidth ? 0 : '1rem')};
  ${({ margin }) => margin && `margin: ${margin};`}
  cursor: pointer;
  @media (max-width: 784px) {
    width: 100%;
    // margin: 0 0 0 0;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const Button = styled.button`
  ${buttonStyle}
`;

const TextInput = props => {
  if (props.gray) {
    return (
      <GrayTextInput
        model={props.model}
        type={props.type}
        placeholder={props.placeholder}
        autoComplete={props.autocomplete}
        onBlur={props.onBlur}
        readOnly={props.readOnly}
        title={props.title}
      />
    );
  }

  if (props.thin) {
    return (
      <ThinTextInput
        model={props.model}
        type={props.type}
        placeholder={props.placeholder}
        autoComplete={props.autocomplete}
        autoFocus={props.autofocus}
        onBlur={props.onBlur}
        style={props.style}
        onChange={props.onChange}
        readOnly={props.readOnly}
        title={props.title}
      />
    );
  }

  return (
    <StyledTextInput
      model={props.model}
      type={props.type}
      placeholder={props.placeholder}
      autoComplete={props.autocomplete}
      autoFocus={props.autofocus}
      onBlur={props.onBlur}
      style={props.style}
      onChange={props.onChange}
      readOnly={props.readOnly}
      title={props.title}
    />
  );
};

TextInput.propTypes = {
  placeholder: PropTypes.string,
  gray: PropTypes.any,
  thin: PropTypes.bool,
  model: PropTypes.string,
  type: PropTypes.string,
  autocomplete: PropTypes.string,
  autofocus: PropTypes.bool,
  onBlur: PropTypes.any,
  style: PropTypes.any,
  onChange: PropTypes.any,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
};

TextInput.defaultProps = {
  type: 'text',
  autocomplete: 'on',
  autofocus: false
};

const RootElement = styled.div`
  flex: 1 1 0%;

  button {
    float: right;
  }
`;

const Content = styled.div`
  margin: auto;
  display: flex;
  flex: 1 1 0%;
  width: 1280px;
  max-width: 100%;
  padding-bottom: 1rem;

  @media only screen and (max-width: 784px) {
    display: block;
  }
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 auto;
  width: 1280px;

  @media(max-width:576px) {
    display: block;
  }
`;

const GrayBox = styled.div`
  background-color: ${colors.lightestGray};
  flex: 10 10 0%;
  padding: 1rem;
`;

const ItemTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  thead tr,
  tbody tr {
    background-color: white;
    border-bottom: 1px solid #ebf0f0;
  }

  td,
  th {
    text-align: left;
    padding: 0.3rem 1rem;
    white-space: normal;
    min-width: 50px;
  }

  .wider {
    min-width: 70px;
  }

  .fixedWidth {
    white-space: nowrap;
    word-break: unset;
  }
`;

function mapState(state) {
  const { admin } = state;
  return {
    admin
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...adminActions }, dispatch)
  };
}

class AdminSuperDashboardOrganizations extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.actions.loadOrganizations();
  }

  handleSubmit(values) {
    this.props.actions.searchOrganization(values);
  }

  render() {
    const initialState = {
      searchValue: '',
    };

    if (this.props.admin.loading) {
      return <SLoader />;
    }
    return (
      <RootElement>
        <Menu />
        <GrayBox>
          <div>
            <Content>
              <h2>Organizations</h2>
            </Content>
            <Content>
              <LocalForm
                onSubmit={values => this.handleSubmit(values)}
                initialState={initialState}
              >
                <TextInput
                  model=".searchValue"
                  placeholder="email, gatewayId"
                  readOnly
                  thin
                />
                <Button>
                  Search
                </Button>
              </LocalForm>
            </Content>
            <ColumnsContainer>
              <ItemTable tableborder="0">
                <colgroup>
                  <col style={{ width: '20%' }}/>
                  <col style={{ width: '20%' }}/>
                  <col style={{ width: '20%' }}/>
                  <col style={{ width: '20%' }}/>
                  <col style={{ width: '20%' }}/>
                </colgroup>
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Organization ID</th>
                    <th>Country</th>
                    <th>City</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <ItemList item={this.props.admin.data.organization}/>
                </tbody>
              </ItemTable>
            </ColumnsContainer>
          </div>
        </GrayBox>
      </RootElement>
    );
  }
}

const ItemList = ({ item }) => {
  if (item) {
    return [...item].map(event => <DataItem event={event} />);
  }
  return [];
};

const DataItem = ({ event }) => {
  return (
    <tr>
      <td>{event.company}</td>
      <td>
        <Link className="brand" to={`/admin-super/organization?org=${event.organizationId}`}>
          {event.organizationId}
        </Link>
      </td>
      <td>{event.country}</td>
      <td>{event.city}</td>
      <td>
        <Link className="brand" to={`/admin-super/organization/users?org=${event.organizationId}`}>
          Users
        </Link>
        &nbsp;|&nbsp;
        <Link className="brand" to={`/admin-super/organization/devices?org=${event.organizationId}`}>
          Devices
        </Link>
        &nbsp;|&nbsp;
        <Link className="brand" to={`/admin-super/organization/gateways?org=${event.organizationId}`}>
          Gateways
        </Link>
      </td>
    </tr>
  );
};

DataItem.propTypes = {
  event: PropTypes.object,
};

AdminSuperDashboardOrganizations.propTypes = {
  admin: PropTypes.object,
};

AdminSuperDashboardOrganizations.propTypes = {
  actions: PropTypes.object,
};

export default connect(mapState, mapDispatch)(AdminSuperDashboardOrganizations);
