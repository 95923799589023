import { lubricantValues, ROUTE_DASHBOARD_DEVICES } from 'helpers/constants';
import messages from 'helpers/messages';
import { redirectTo } from 'redux/modules/redirect';
import { displayErrorMessage, displaySuccessMessage } from './notification';

const LOAD = 'myrina/device/LOAD';
const LOAD_SUCCESS = 'myrina/device/LOAD_SUCCESS';
const LOAD_FAIL = 'myrina/device/LOAD_FAIL';

const ADD = 'myrina/device/ADD';
const ADD_SUCCESS = 'myrina/device/ADD_SUCCESS';
const ADD_FAIL = 'myrina/device/ADD_FAIL';

const DELETE = 'myrina/device/DELETE';
const DELETE_SUCCESS = 'myrina/device/DELETE_SUCCESS';
const DELETE_FAIL = 'myrina/device/DELETE_FAIL';

const UPLOAD_DEVICE = 'myrina/device/UPLOAD_DEVICE';
const UPLOAD_DEVICE_SUCCESS = 'myrina/device/UPLOAD_DEVICE_SUCCESS';
const UPLOAD_DEVICE_FAIL = 'myrina/device/UPLOAD_DEVICE_FAIL';

const UPLOAD_DEVICE_IMAGE = 'myrina/device/UPLOAD_DEVICE_IMAGE';
const UPLOAD_DEVICE_IMAGE_SUCCESS = 'myrina/device/UPLOAD_DEVICE_IMAGE_SUCCESS';
const UPLOAD_DEVICE_IMAGE_FAIL = 'myrina/device/UPLOAD_DEVICE_IMAGE_FAIL';

const UPLOAD_DEVICE_KEY = 'myrina/device/UPLOAD_DEVICE_KEY';
const UPLOAD_DEVICE_KEY_SUCCESS = 'myrina/device/UPLOAD_DEVICE_KEY_SUCCESS';
const UPLOAD_DEVICE_KEY_FAIL = 'myrina/device/UPLOAD_DEVICE_KEY_FAIL';

const IMAGE = 'myrina/device/IMAGE';
const IMAGE_SUCCESS = 'myrina/device/IMAGE_SUCCESS';
const IMAGE_FAIL = 'myrina/device/IMAGE_FAIL';
const IMAGE_SET = 'myrina/device/IMAGE_SET';

const DELETE_IMAGE = 'myrina/device/DELETE_IMAGE';
const DELETE_IMAGE_SUCCESS = 'myrina/device/DELETE_IMAGE_SUCCESS';
const DELETE_IMAGE_FAIL = 'myrina/device/DELETE_IMAGE_FAIL';

const initialState = {
  loaded: false,
  loading: true,
  devices: [],
  stats: {},
  assetOptions: {
    lubricantTypes: lubricantValues,
  },
};

export default function device(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        devices: action.result.devices,
        stats: action.result.stats || {},
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };

    case UPLOAD_DEVICE_IMAGE_SUCCESS:
      return {
        ...state,
        signedUrl: action.signedUrl,
        imageId: action.imageId,
      };

    case IMAGE_SET: {
      const devices = state.devices.map(d => {
        if (d.deviceId === action.deviceId) {
          return { ...d, image: action.image };
        }
        return d;
      });
      return {
        ...state,
        devices,
      };
    }

    case IMAGE_SUCCESS: {
      const devices = state.devices.map(d => {
        if (d.deviceId === action.result.deviceId) {
          const image = action.result.data.pop() || {};
          return { ...d, image };
        }
        return d;
      });
      return {
        ...state,
        devices,
      };
    }

    default:
      return state;
  }
}

export function loadDevices() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => {
      return client.get('/customer/devices');
    },
  };
}

/**
 * Create a device
 *
 * @param {{
 *   assetTag: string,
 *   assetName: string,
 *   application: string,
 *   location: string,
 *   productId: string,
 *   deviceConfig: {
 *     emptyingTime: number,
 *     cartridgeSize: number,
 *     lubricant: string,
 *   },
 * }} data
 *
 */
export function createDevice(data) {
  return {
    types: [ADD, ADD_SUCCESS, ADD_FAIL],
    promise: (client, dispatch) => {
      return client
        .post('/customer/devices', {
          headers: {
            'content-type': 'application/json',
          },
          data,
        })
        .then(res => {
          dispatch(loadDevices());
          dispatch(redirectTo(ROUTE_DASHBOARD_DEVICES));
          dispatch(displaySuccessMessage(messages.successDeviceAdded));
          return res;
        })
        .catch(err => {
          const [, , errorKey] = err.body.match(/\[(\d{3})\] (error..*)/);

          let errorMessage = '';
          switch (errorKey) {
            case 'error.assetNotExists':
              errorMessage = messages.errorAssetNotExists;
              break;
            case 'error.deviceKeyWrongOrg':
              errorMessage = messages.errorDeviceKeyWrongOrg;
              break;
            case 'error.customerDeviceExists':
              errorMessage = messages.errorCustomerDeviceExists;
              break;
            case 'error.deviceKeyNotExists':
              errorMessage = messages.errorDeviceKeyNotExists;
              break;
            default:
              errorMessage = messages.errorDeviceAdd;
              break;
          }

          dispatch(displayErrorMessage(errorMessage));
        });
    },
  };
}

export function uploadDevices(csv) {
  return {
    types: [UPLOAD_DEVICE, UPLOAD_DEVICE_SUCCESS, UPLOAD_DEVICE_FAIL],
    promise: (client, dispatch) => {
      return client
        .post('/customer/devices', {
          headers: {
            'content-type': 'text/csv',
          },
          data: csv,
        })
        .then(res => {
          dispatch(loadDevices());
          dispatch(displaySuccessMessage(messages.successDevicesAdded));
          return res;
        })
        .catch(() => {
          dispatch(displayErrorMessage(messages.errorDevicesAdd));
        });
    },
  };
}

export function updateDevice(values) {
  return {
    types: [ADD, ADD_SUCCESS, ADD_FAIL],
    promise: (client, dispatch) => {
      return client
        .put(`/customer/devices/${encodeURIComponent(values.deviceId)}`, {
          data: values,
        })
        .then(res => {
          dispatch(loadDevices());
          dispatch(redirectTo(ROUTE_DASHBOARD_DEVICES));
          dispatch(displaySuccessMessage(messages.successDeviceUpdated));
          return res;
        })
        .catch(() => {
          dispatch(displayErrorMessage(messages.errorDeviceUpdate));
        });
    },
  };
}

export function deleteDevice(deviceId) {
  return {
    types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
    promise: (client, dispatch) => {
      return client
        .del(`/customer/devices/${encodeURIComponent(deviceId)}`)
        .then(res => {
          dispatch(loadDevices());
          dispatch(redirectTo(ROUTE_DASHBOARD_DEVICES));
          dispatch(displaySuccessMessage(messages.successDeviceDeleted));
          return res;
        })
        .catch(() => {
          dispatch(displayErrorMessage(messages.errorDeviceDelete));
        });
    },
  };
}

export function getSignedUploadURL(data, id, callback) {
  return {
    types: [
      UPLOAD_DEVICE_IMAGE,
      UPLOAD_DEVICE_IMAGE_SUCCESS,
      UPLOAD_DEVICE_IMAGE_FAIL,
    ],
    promise: client => {
      return client
        .get(
          `/customer/devices/${id}/image-upload?contentType=${encodeURIComponent(
            data.type
          )}&objectName=${encodeURIComponent(data.name)}`
        )
        .then(callback);
    },
  };
}

export function getImage(id) {
  return {
    types: [IMAGE, IMAGE_SUCCESS, IMAGE_FAIL],
    promise: client => {
      return client.get(`/customer/devices/${id}/images`).then(res => {
        return { data: res, deviceId: id };
      });
    },
  };
}

export function deleteImage(deviceToDelete) {
  const { deviceId, image } = deviceToDelete;
  return {
    types: [DELETE_IMAGE, DELETE_IMAGE_SUCCESS, DELETE_IMAGE_FAIL],
    promise: (client, dispatch) => {
      return client
        .del(`/customer/devices/${deviceId}/images/${image.imageId}`)
        .then(res => {
          dispatch(getImage(deviceId));
          dispatch(displaySuccessMessage(messages.successDeviceUpdated));
          return res;
        })
        .catch(() => {
          dispatch(displayErrorMessage(messages.errorDeviceUpdate));
        });
    },
  };
}

export function uploadDeviceKeys(csv) {
  return {
    types: [
      UPLOAD_DEVICE_KEY,
      UPLOAD_DEVICE_KEY_SUCCESS,
      UPLOAD_DEVICE_KEY_FAIL,
    ],
    promise: (client, dispatch) => {
      return client
        .post('/device-admin/devicekeys/sso', {
          headers: {
            'content-type': 'text/csv',
          },
          data: csv,
        })
        .then(res => {
          dispatch(displaySuccessMessage(messages.successDeviceKeysAdded));
          return res;
        })
        .catch(() => {
          dispatch(displayErrorMessage(messages.errorDeviceKeysAdd));
        });
    },
  };
}
