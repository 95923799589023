import React from 'react';
import { FormattedMessage } from 'react-intl';

import TemplateDialogContent from 'components/TemplateDialogContent';
import { DeviceShape } from 'helpers/propTypes';

const DialogContentDeleteDevice = ({ device }) => {
  return (
    <TemplateDialogContent
      content={[
        {
          subheading: (
            <FormattedMessage
              defaultMessage="{thing} details"
              values={{
                thing: <FormattedMessage defaultMessage="Device" id="c3VJQo" />
              }}
              id="2Q8Pzz"
            />
          ),
          items: [
            {
              id: 'deviceId',
              children: (
                <FormattedMessage
                  defaultMessage="Device ID: {deviceId}"
                  id="17+elW"
                  values={{ deviceId: device.productId }}
                />
              )
            },
          ]
        }
      ]}
    />
  );
};

DialogContentDeleteDevice.defaultProps = {
  device: {
    productId: '--',
  }
};

DialogContentDeleteDevice.propTypes = {
  device: DeviceShape,
};

export default DialogContentDeleteDevice;
