import React from 'react';
import PropTypes from 'prop-types';
import { FontSizes, Flexbox, Text } from '@skf-internal/ui-components-react-legacy';

const TextGroup = ({ label, text }) => {
  return (
    <Flexbox feFlexDirection="column">
      <Text feFontSize={FontSizes.Xs}>{label}</Text>
      <Text>{text}</Text>
    </Flexbox>
  );
};

TextGroup.propTypes = {
  label: PropTypes.node,
  text: PropTypes.node,
};

export default TextGroup;
