import React from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Button,
  Card, Flexbox, Heading, Link, Spacer, Text
} from '@skf-internal/ui-components-react-legacy';

import { IntlShape } from 'helpers/propTypes';

const NewUserAfterCutoffDateView = ({ intl }) => {
  const text = intl.formatMessage({
    defaultMessage: 'Dear User,\n\n'
    + 'We would like to inform you that this platform has been decommissioned and is no longer accepting new registrations.\n\n'
    + 'We have a new and improved platform where you can create your account. We believe that our new platform will provide a better experience for all our users.\n\n'
    + 'Please visit our new platform by clicking on the link bellow and follow the instructions to create a new account:\n\n'
    + '{orbitLink}\n\n'
    + "If you encounter any issues or have any questions, please don't hesitate to contact our {supportTeam}.\n\n"
    + 'Thank you for your understanding and cooperation.',
    id: 'VZmWBw'
  }, {
    orbitLink: (
      <Link as="a" href="https://www.lubrication-cloud.skf.com/">
        https://www.lubrication-cloud.skf.com/
      </Link>
    ),
    supportTeam: (
      <Link as="a" href="https://www.skf.com/cm/tsg">
        https://www.skf.com/cm/tsg
      </Link>
    )
  });
  return (
    <Flexbox feAlignItems="center" feJustifyContent="center" style={{ minHeight: '100vh' }}>
      <Card style={{ width: '32em' }}>
        <Heading as="h3" style={{ textAlign: 'center' }}>
          Lubrication Control Panel (LCP)
        </Heading>
        <Heading as="h3" style={{ textAlign: 'center' }}>
          <FormattedMessage
            defaultMessage="has been decomissioned"
            id="8Te8Ax"
          />
        </Heading>
        <Spacer />
        <Spacer />
        <Text style={{ whiteSpace: 'pre-line' }}>
          {text}
        </Text>
        <Spacer />
        <Spacer />
        <Flexbox feJustifyContent="center">
          <Button onClick={() => {
            window.location = `${process.env.REACT_APP_SSO_URL}/logout?app=${process.env.REACT_APP_SSO_APPKEY}`;
          }}
          >
            <FormattedMessage
              defaultMessage="Sign out"
              id="xXbJso"
            />
          </Button>
        </Flexbox>
      </Card>
    </Flexbox>
  );
};

NewUserAfterCutoffDateView.propTypes = {
  intl: IntlShape
};

export default compose(injectIntl)(NewUserAfterCutoffDateView);
