import messages from 'helpers/messages';
import {
  success, error, warning, info
} from 'helpers/notifications';

const DISPLAY_MESSAGE = 'lcp/notification/DISPLAY_MESSAGE';

const initialState = {
  message: undefined,
};

export default function notification(state = initialState, action = {}) {
  switch (action.type) {
    case DISPLAY_MESSAGE:
      return {
        message: action.message,
      };
    default:
      return state;
  }
}

function displayMessage(level, message) {
  return (dispatch, getState) => {
    const { intl: { instance, translations } } = getState();

    const formattedMessage = translations[message.id]
      ? instance.formatMessage(message)
      : instance.formatMessage(messages.infoMissingTranslation);

    dispatch(
      level(formattedMessage),
    );

    return {
      type: DISPLAY_MESSAGE,
      message,
    };
  };
}

export function displaySuccessMessage(message) {
  return displayMessage(success, message);
}

export function displayErrorMessage(message) {
  return displayMessage(error, message);
}

export function displayWarningMessage(message) {
  return displayMessage(warning, message);
}

export function displayInfoMessage(message) {
  return displayMessage(info, message);
}
