import {
  Heading, Spacer, Spacings
} from '@skf-internal/ui-components-react-legacy';
import FormLubricantManufacturer from 'components/FormLubricantManufacturer';
import SLoader from 'components/SLoader';
import TemplateContent from 'components/TemplateContent';
import { LubricantManufacturerShape } from 'helpers/propTypes';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as lubricantActions from 'redux/modules/lubricant';

function mapState(state) {
  const {
    lubricant: { manufacturers: { items: manufacturers, loading, loaded } },
  } = state;
  return {
    manufacturers,
    loading,
    loaded,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(lubricantActions, dispatch),
    dispatch
  };
}

const AddLubricantManufacturerView = ({
  actions, manufacturers, loading, loaded
}) => {
  useEffect(() => {
    if (!loaded) {
      actions.loadLubricantManufacturers();
    }
  }, []);

  const handleSubmit = values => {
    return actions.createLubricantManufacturer(values);
  };

  if (loading) {
    return (
      <SLoader />
    );
  }

  return (
    <TemplateContent
      heading={(
        <Heading as="h2">
          <FormattedMessage
            defaultMessage="Add {thing}"
            id="SziwLc"
            values={{ thing: <FormattedMessage defaultMessage="lubricant manufacturer" id="iPYOWP" /> }}
          />
        </Heading>
      )}
      linkBack={{
        to: '/dashboard/lubricants-and-manufacturers/lubricant-manufacturers'
      }}
    >
      <Spacer feSpacing={Spacings.Xxl} />
      <FormLubricantManufacturer
        onSubmit={handleSubmit}
        manufacturers={manufacturers}
      />
    </TemplateContent>
  );
};

AddLubricantManufacturerView.propTypes = {
  actions: PropTypes.shape({
    loadLubricantManufacturers: PropTypes.func,
    createLubricantManufacturer: PropTypes.func,
  }),
  manufacturers: PropTypes.arrayOf(LubricantManufacturerShape),
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
};

export default connect(
  mapState,
  mapDispatch
)(AddLubricantManufacturerView);
