import messages from 'helpers/messages';
import { ROUTE_DASHBOARD_GATEWAYS } from 'helpers/constants';
import { displayErrorMessage, displaySuccessMessage } from './notification';
import { redirectTo } from './redirect';

const LOAD = 'myrina/gateway/LOAD';
const LOAD_SUCCESS = 'myrina/gateway/LOAD_SUCCESS';
const LOAD_FAIL = 'myrina/gateway/LOAD_FAIL';

const ADD = 'myrina/gateway/ADD';
const ADD_SUCCESS = 'myrina/gateway/ADD_SUCCESS';
const ADD_FAIL = 'myrina/gateway/ADD_FAIL';

const DELETE = 'myrina/gateway/DELETE';
const DELETE_SUCCESS = 'myrina/gateway/DELETE_SUCCESS';
const DELETE_FAIL = 'myrina/gateway/DELETE_FAIL';

const initialState = {
  loaded: false,
  gateways: []
};

export default function gateway(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        gateways: action.result
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    default:
      return state;
  }
}

export function loadGateways() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => {
      return client.get('/customer/gateways');
    }
  };
}

export function createGateway(values) {
  return {
    types: [ADD, ADD_SUCCESS, ADD_FAIL],
    promise: (client, dispatch) => {
      return client
        .post('/customer/gateways', {
          headers: {
            'content-type': 'application/json',
          },
          data: values
        })
        .then(res => {
          dispatch(loadGateways());
          dispatch(redirectTo(ROUTE_DASHBOARD_GATEWAYS));
          dispatch(displaySuccessMessage(messages.successGatewayAdded));
          return res;
        })
        .catch(() => {
          dispatch(displayErrorMessage(messages.errorGatewayAdd));
        });
    }
  };
}

export function updateGateway(values) {
  return {
    types: [ADD, ADD_SUCCESS, ADD_FAIL],
    promise: (client, dispatch) => {
      return client
        .put(`/customer/gateways/${values.id}`, { data: values })
        .then(res => {
          dispatch(loadGateways());
          dispatch(redirectTo(ROUTE_DASHBOARD_GATEWAYS));
          dispatch(displaySuccessMessage(messages.successGatewayUpdated));
          return res;
        })
        .catch(() => {
          dispatch(displayErrorMessage(messages.errorGatewayUpdate));
        });
    }
  };
}

export function deleteGateway(gway) {
  return {
    types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
    promise: (client, dispatch) => {
      return client
        .del(`/customer/gateways/${gway.gatewayId}`, { data: gway })
        .then(res => {
          dispatch(loadGateways());
          dispatch(redirectTo(ROUTE_DASHBOARD_GATEWAYS));
          dispatch(displaySuccessMessage(messages.successGatewayDeleted));
          return res;
        })
        .catch(() => {
          dispatch(displayErrorMessage(messages.errorGatewayDelete));
        });
    }
  };
}
