import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Icons, Link as FeLink
} from '@skf-internal/ui-components-react-legacy';
import { FormattedMessage } from 'react-intl';

const ActionLinkBack = ({ to, onClick, text }) => {
  return (
    <Link to={to}>
      <FeLink
        as="button"
        type="button"
        feIcon={{
          feIcon: Icons.ArrowLeft,
          position: 'left'
        }}
        onClick={onClick}
      >
        {text}
      </FeLink>
    </Link>
  );
};

ActionLinkBack.defaultProps = {
  onClick: () => {},
  text: <FormattedMessage defaultMessage="Back" id="cyR7Kh" />,
};

ActionLinkBack.propTypes = {
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.node,
};

export default ActionLinkBack;
