import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Icons,
  Link as FeLink
} from '@skf-internal/ui-components-react-legacy';

const ActionLinkDelete = ({
  onClick,
  disabled,
  title,
  icon,
  text,
}) => {
  return (
    <FeLink
      as="button"
      type="button"
      feIcon={icon ? {
        feIcon: Icons.Trash,
        position: 'left',
      } : undefined}
      onClick={onClick}
      disabled={disabled}
      title={title}
    >
      {text}
    </FeLink>
  );
};

ActionLinkDelete.defaultProps = {
  text: <FormattedMessage defaultMessage="Delete" id="K3r6DQ" />,
};

ActionLinkDelete.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.bool,
  text: PropTypes.node,
};

export default ActionLinkDelete;
