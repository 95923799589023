import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Icons,
  Link as FeLink
} from '@skf-internal/ui-components-react-legacy';

const ActionButtonEdit = ({
  onClick,
  disabled,
  title,
  icon,
  text,
}) => {
  return (
    <FeLink
      as="button"
      type="button"
      feIcon={icon ? {
        feIcon: Icons.Edit,
        position: 'left',
      } : undefined}
      onClick={onClick}
      disabled={disabled}
      title={title}
    >
      {text}
    </FeLink>
  );
};

ActionButtonEdit.defaultProps = {
  text: <FormattedMessage defaultMessage="Edit" id="wEQDC6" />
};

ActionButtonEdit.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.bool,
  text: PropTypes.node,
};

export default ActionButtonEdit;
