import {
  Breakpoints,
  Flexbox,
  Heading, Search, Spacer,
  Spacings,
  Text
} from '@skf-internal/ui-components-react-legacy';
import ActionButtonAdd from 'components/ActionButtonAdd';
import SLoader from 'components/SLoader';
import TableLubricants from 'components/TableLubricants';
import TemplateSection from 'components/TemplateSection';
import { ROUTE_DASHBOARD_LUBRICANTS } from 'helpers/constants';
import { DeviceShape, LubricantShape } from 'helpers/propTypes';
import useFuse from 'hooks/useFuse';
import PropTypes from 'prop-types';
import React, {
  useEffect
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  Route, Switch as RouterSwitch
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as authActions from 'redux/modules/auth';
import * as deviceActions from 'redux/modules/device';
import * as lubricantActions from 'redux/modules/lubricant';

function mapState(state) {
  const {
    auth: { organizationId, loading: loadingAuth, loaded: loadedAuth },
    device: { devices, loading: loadingDevices, loaded: loadedDevices },
    lubricant: { lubricants: { items: lubricants, loading: loadingLubricants, loaded: loadedLubricants } },
    ui: { width },
  } = state;

  return {
    organizationId,
    loadingAuth,
    loadedAuth,
    devices,
    loadingDevices,
    loadedDevices,
    lubricants,
    loadingLubricants,
    loadedLubricants,
    width,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({
      ...authActions, ...deviceActions, ...lubricantActions
    }, dispatch)
  };
}

const DashboardLubricantsView = ({
  actions,
  organizationId,
  loadingAuth,
  loadedAuth,
  devices,
  loadingDevices,
  loadedDevices,
  lubricants,
  loadingLubricants,
  loadedLubricants,
  width,
}) => {
  const intl = useIntl();
  const { hits, onSearch, onReset } = useFuse(lubricants, {
    keys: ['manufacturerName', 'denomination'],
    matchAllOnEmptyQuery: true,
    shouldSort: false,
    threshold: 0,
    ignoreLocation: true
  });

  useEffect(() => {
    if (!loadedDevices) {
      actions.loadDevices();
    }

    if (!loadedLubricants) {
      actions.loadLubricants();
    }

    if (!loadedAuth) {
      actions.getAccount();
    }
  }, []);

  const handleDeleteConfirmButtonClick = denomination => {
    actions.deleteLubricant(denomination);
  };

  const loading = loadingDevices || loadingAuth || loadingLubricants;

  if (loading) {
    return <SLoader />;
  }

  return (
    <RouterSwitch>
      <Route exact path={ROUTE_DASHBOARD_LUBRICANTS}>
        <TemplateSection>
          <Heading as="h2">
            <FormattedMessage
              defaultMessage="Lubricants"
              id="Z6afvc"
            />
          </Heading>
          <Spacer feSpacing={Spacings.Md} />
          <Flexbox feGap={Spacings.Md} feFlexDirection="column">
            <Text>
              <FormattedMessage
                defaultMessage="Lubricants are oils or greases applied to the Assets. In this view you can manage them."
                id="2C/6n8"
              />
            </Text>
          </Flexbox>
        </TemplateSection>
        <TemplateSection>
          <Heading as="h3">
            <FormattedMessage
              defaultMessage="{thing} table"
              values={{
                thing: <FormattedMessage
                  defaultMessage="Lubricants"
                  id="Z6afvc"
                />
              }}
              id="CZDCWN"
            />
          </Heading>
          <Spacer feSpacing={Spacings.Md} />
          <Flexbox
            feGap={Spacings.Md}
            feJustifyContent="space-between"
            feFlexDirection={width >= Breakpoints.Tablet ? 'row' : 'column'}
            feAlignItems={width >= Breakpoints.Tablet ? 'center' : 'flex-start'}
          >
            <Flexbox>
              <Search
                size={32}
                onChange={onSearch}
                feResetButton={{
                  onClick: onReset
                }}
                placeholder={(intl.formatMessage({ defaultMessage: 'Search...', id: '0BUTMv' }))}
                feHint={(
                  intl.formatMessage(
                    {
                      defaultMessage: 'Search by {list}',
                      id: 'UlWvQl',
                    },
                    {
                      list: intl.formatList([
                        intl.formatMessage({
                          defaultMessage: 'Manufacturer', id: 'VI/+Qx',
                        }),
                        intl.formatMessage({
                          defaultMessage: 'Name', id: 'HAlOn1',
                        }),
                      ], { type: 'conjunction' })
                    }
                  )
                )}
                feLabel="Search by Manufacturer and Name"
                feHideLabel
              />
            </Flexbox>
            <Flexbox
              feGap={Spacings.Md}
              feJustifyContent="flex-end"
              feAlignItems="center"
            >
              <ActionButtonAdd
                to={`${ROUTE_DASHBOARD_LUBRICANTS}/add-lubricant`}
                text={<FormattedMessage defaultMessage="Add lubricant" id="njrFZr" />}
              />
            </Flexbox>
          </Flexbox>
        </TemplateSection>
        <TableLubricants
          organizationId={organizationId}
          devices={devices}
          lubricants={hits.map(hit => hit.item)}
          loading={loading}
          onDeleteConfirmClick={handleDeleteConfirmButtonClick}
        />
      </Route>
    </RouterSwitch>
  );
};

DashboardLubricantsView.propTypes = {
  actions: PropTypes.shape({
    getAccount: PropTypes.func,
    loadDevices: PropTypes.func,
    loadLubricants: PropTypes.func,
    deleteLubricant: PropTypes.func,
  }),
  organizationId: PropTypes.string,
  loadingAuth: PropTypes.bool,
  loadedAuth: PropTypes.bool,
  devices: PropTypes.arrayOf(DeviceShape),
  loadingDevices: PropTypes.bool,
  loadedDevices: PropTypes.bool,
  lubricants: PropTypes.arrayOf(LubricantShape),
  loadingLubricants: PropTypes.bool,
  loadedLubricants: PropTypes.bool,
  width: PropTypes.number,
};

export default connect(mapState, mapDispatch)(DashboardLubricantsView);
