import {
  Breakpoints, Flexbox,
  Heading, Search, Spacer,
  Spacings,
  Tag,
  Text
} from '@skf-internal/ui-components-react-legacy';
import ActionButtonAdd from 'components/ActionButtonAdd';
import DeleteDialog from 'components/DeleteDialog';
import DialogContentDeleteAsset from 'components/DialogContentDeleteAsset';
import FormAsset from 'components/FormAsset';
import SLoader from 'components/SLoader';
import TableDevices from 'components/TableDevices';
import TemplateContent from 'components/TemplateContent';
import TemplateDetailsActions from 'components/TemplateDetailsActions';
import TemplatePersistingNotification from 'components/TemplatePersistingNotification';
import TextGroup from 'components/TextGroup';
import TextLabel from 'components/TextLabel';
import { ROUTE_BASE, ROUTE_DASHBOARD_ASSETS, ROUTE_DASHBOARD_DEVICES } from 'helpers/constants';
import {
  AssetShape,
  DeviceShape
} from 'helpers/propTypes';
import useDevicesWithData from 'hooks/useDevicesWithData';
import useFuse from 'hooks/useFuse';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  FormattedMessage, useIntl
} from 'react-intl';
import { connect } from 'react-redux';
import {
  Redirect,
  useHistory, useLocation, useParams
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as assetActions from 'redux/modules/asset';
import * as deviceActions from 'redux/modules/device';

function mapState(state) {
  const {
    asset: {
      assets, loading: assetLoading, loaded: assetsLoaded
    },
    device: {
      devices, loading: deviceLoading, loaded: devicesLoaded
    },
    ui: {
      width,
    }
  } = state;

  return {
    assets,
    assetsLoaded,
    devices,
    devicesLoaded,
    loading: assetLoading || deviceLoading,
    width,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...assetActions, ...deviceActions }, dispatch),
  };
}

const AssetDetailsView = ({
  actions, assets, assetsLoaded, devices, devicesLoaded, loading, width,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { assetTag: paramAssetTag } = useParams();
  const assetTag = paramAssetTag ? decodeURIComponent(paramAssetTag) : undefined;
  const routerLocation = useLocation();
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [devicesWithData] = useDevicesWithData(devices);

  useEffect(() => {
    if (!assetsLoaded) {
      actions.loadAssets();
    }

    if (!devicesLoaded) {
      actions.loadDevices();
    }
  }, []);

  const handleEditCancelClick = () => {
    setShowEditForm(false);
  };

  const handleEditClick = () => {
    setShowEditForm(true);
  };

  const handleEditSubmit = values => {
    return actions.updateAsset(values);
  };

  const handleDeleteClick = () => {
    setShowDeleteForm(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteForm(false);
  };

  const handleDeleteConfirm = () => {
    actions.deleteAsset(assetTag);
  };

  const handleDeviceRowClick = deviceId => {
    history.push(`${ROUTE_DASHBOARD_DEVICES}/${encodeURIComponent(deviceId)}`);
  };

  const asset = assets.find(a => a.assetTag === assetTag);
  const assetDevices = asset ? devicesWithData.filter(device => device.assetTag === asset.assetTag) : [];

  const locationsInUse = new Set();
  assetDevices.forEach(device => {
    locationsInUse.add(device.location);
  });

  const { hits, onSearch, onReset } = useFuse(assetDevices, {
    keys: ['deviceId', 'productId', 'lastGatewayId'],
    matchAllOnEmptyQuery: true,
    shouldSort: false,
    threshold: 0,
    ignoreLocation: true
  });

  if (loading) {
    return <SLoader />;
  }

  if (assetTag === undefined || !asset) {
    return (
      <Redirect to={ROUTE_BASE} />
    );
  }

  if (!assetDevices) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: routerLocation }
        }}
      />
    );
  }

  const {
    assetName, application, locations
  } = asset;

  return (
    <TemplateContent
      heading={(
        <Flexbox feJustifyContent="space-between">
          <Heading as="h2">
            <FormattedMessage
              defaultMessage="{thing} details - {value}"
              id="yFCfxD"
              values={{ thing: <FormattedMessage defaultMessage="Asset" id="WKCp0D" />, value: assetTag }}
            />
          </Heading>
          {!showEditForm && (
            <TemplateDetailsActions
              edit={{ onClick: handleEditClick }}
              delete={{
                onClick: handleDeleteClick,
                disabled: assetDevices.length > 0,
                title: assetDevices.length > 0
                  ? intl.formatMessage({ defaultMessage: 'You cannot delete an asset with devices.', id: 'AGOqoF' })
                  : '',
              }}
            />
          )}
        </Flexbox>
      )}
      linkBack={{
        to: ROUTE_DASHBOARD_ASSETS,
      }}
    >
      <DeleteDialog
        open={showDeleteForm}
        onCancel={handleDeleteCancel}
        onDelete={handleDeleteConfirm}
        title={(
          intl.formatMessage({
            defaultMessage: 'Delete {thing}',
            id: '23BjSJ',
          },
          {
            thing: intl.formatMessage({ defaultMessage: 'asset', id: 'kqj91u' })
          })
        )}
      >
        <DialogContentDeleteAsset asset={asset} />
      </DeleteDialog>

      <Spacer feSpacing={Spacings.Xxl} />
      {!showEditForm && locations.length === 0
        && (
        <>
          <TemplatePersistingNotification feType="notification">
            <Text>
              <FormattedMessage defaultMessage="This asset has no lubrication points. You will be unable to add lubricators to it." id="5Qe2fe" />
            </Text>
          </TemplatePersistingNotification>
          <Spacer feSpacing={Spacings.Md} />
        </>
        )}
      {showEditForm
        ? (
          <FormAsset
            formType="edit"
            initialState={{
              application, assetTag, assetName, locations
            }}
            disabledFields={['assetTag']}
            cancel={{
              text: <FormattedMessage defaultMessage="Cancel" id="47FYwb" />,
              onClick: handleEditCancelClick
            }}
            locationsInUse={locationsInUse}
            submitBtnText={<FormattedMessage defaultMessage="Save changes" id="X0ha1a" />}
            onSubmit={handleEditSubmit}
          />
        )
        : (
          <Flexbox feFlexDirection="column" feGap={Spacings.Xs}>
            <TextGroup label={<FormattedMessage defaultMessage="Name" id="HAlOn1" />} text={assetName} />
            <TextGroup label={<FormattedMessage defaultMessage="Functional location" id="lOOR5y" />} text={application} />
            <TextLabel label={<FormattedMessage defaultMessage="Lubrication points" id="2zeqaC" />} />
            {locations.length > 0
              && (
              <Flexbox feGap={Spacings.Xs}>
                {locations.map(location => (
                  <Tag key={location}>{location}</Tag>
                ))}
              </Flexbox>
              )}
          </Flexbox>
        )}
      <Spacer feSpacing={Spacings.Lg} />
      <Heading as="h4">
        Asset devices
      </Heading>
      <Spacer feSpacing={Spacings.Md} />
      <Flexbox
        feGap={Spacings.Md}
        feJustifyContent="space-between"
        feFlexDirection={width >= Breakpoints.Tablet ? 'row' : 'column'}
        feAlignItems={width >= Breakpoints.Tablet ? 'center' : 'flex-start'}
        feFlexWrap="wrap"
      >
        <Flexbox>
          <Search
            size={32}
            onChange={onSearch}
            feResetButton={{
              onClick: onReset
            }}
            placeholder={(intl.formatMessage({ defaultMessage: 'Search...', id: '0BUTMv' }))}
            feHint={(
              intl.formatMessage(
                {
                  defaultMessage: 'Search by {list}',
                  id: 'UlWvQl',
                },
                {
                  list: intl.formatList([
                    intl.formatMessage({
                      defaultMessage: 'Device ID', id: 'f9m4vq',
                    }),
                    intl.formatMessage({
                      defaultMessage: 'Gateway', id: 'pMcQck',
                    }),
                  ], { type: 'conjunction' })
                }
              )
            )}
            feLabel="Search by Device ID and Gateway"
            feHideLabel
          />
        </Flexbox>
        <ActionButtonAdd
          to={`${ROUTE_DASHBOARD_ASSETS}/${encodeURIComponent(assetTag)}/add-device`}
          text={<FormattedMessage defaultMessage="Add device" id="kFWJpj" />}
          disabled={locations.length === 0}
        />
      </Flexbox>
      <Spacer feSpacing={Spacings.Sm} />
      <TableDevices
        devices={hits.map(hit => hit.item)}
        onTableBodyRowClick={handleDeviceRowClick}
      />
    </TemplateContent>
  );
};

AssetDetailsView.propTypes = {
  actions: PropTypes.shape({
    loadAssets: PropTypes.func,
    loadDevices: PropTypes.func,
    updateAsset: PropTypes.func,
    deleteAsset: PropTypes.func,
  }),
  assets: PropTypes.arrayOf(AssetShape),
  assetsLoaded: PropTypes.bool,
  devices: PropTypes.arrayOf(DeviceShape),
  devicesLoaded: PropTypes.bool,
  loading: PropTypes.bool,
  width: PropTypes.number,
};

export default connect(mapState, mapDispatch)(AssetDetailsView);
