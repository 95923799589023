import {
  Flexbox,
  Spacings,
  Table,
  Text
} from '@skf-internal/ui-components-react-legacy';
import IconConnection from 'components/IconConnection';
import IconStatus from 'components/IconStatus';
import { DEMO_MODE, LENS_SYNC_STATUS, SIGNAL_STRENGTH_THRESHOLDS } from 'helpers/constants';
import {
  intlEmptyMessage, intlFill, intlLastDataReceivedAt, intlLensSyncStatus
} from 'helpers/intlMethods';
import { DeviceShape } from 'helpers/propTypes';
import { alarmNumberToStatus, lensSyncToAlertLevel } from 'helpers/ValueMapper';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const TableDevices = ({ devices, onTableBodyRowClick }) => {
  const intl = useIntl();

  const sortByAlertLevel = ({ level }) => `${level}`;

  // Dirty hack to make empty strings compare to last element
  const sortByGateway = ({ gateway }) => gateway || Array(8).join('Z');

  const renderDevicesTableBody = () => {
    return devices.map(({
      alertLevel,
      deviceId,
      productId,
      application,
      lastGatewayId,
      empty,
      deviceData: {
        alarmNumber,
        lubricantLeft,
        signalStrength,
        rssi,
        lsnr
      },
      deviceConfig: {
        emptyingTime
      },
      dataReceivedAt,
      lensSync
    }) => {
      const emptyMessage = intlEmptyMessage(intl, emptyingTime || empty);
      const status = alarmNumberToStatus(alarmNumber);
      const lastDataReceivedAt = intlLastDataReceivedAt(intl, moment, dataReceivedAt);

      return (
        {
          cells: [
            {
              children: (
                lensSync && lensSync.status !== LENS_SYNC_STATUS.OK ? (
                  <Text level={lensSync.status} title={`${lensSync.status}`}>
                    <IconStatus level={lensSyncToAlertLevel(lensSync.status)} />
                      &nbsp;&nbsp;
                    {intlLensSyncStatus(intl, lensSync.status)}
                  </Text>
                )
                  : (
                    <Text level={alertLevel} title={`${status} - ${lastDataReceivedAt}`}>
                      <Flexbox feGap={Spacings.Md}>
                        <IconStatus level={alertLevel} />
                        <IconConnection
                          rssi={rssi || signalStrength}
                          lsnr={lsnr}
                          min={{ // Negative RSSI values
                            weak: SIGNAL_STRENGTH_THRESHOLDS.WEAK,
                            fair: SIGNAL_STRENGTH_THRESHOLDS.FAIR,
                            good: SIGNAL_STRENGTH_THRESHOLDS.GOOD,
                            excellent: SIGNAL_STRENGTH_THRESHOLDS.EXCELLENT
                          }}
                        />
                        {status}
                      </Flexbox>
                    </Text>
                  )
              )
              ,
            },
            {
              children: productId,
            },
            {
              children: (<Text gateway={lastGatewayId}>{lastGatewayId}</Text>)
            },
            {
              children: (
                <Text
                  title={application}
                  style={{
                    maxWidth: '180px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {application}
                </Text>
              ),
            },
            {
              children: intlFill(intl, lubricantLeft, emptyingTime !== DEMO_MODE ? empty : null),
            },
            {
              children: emptyMessage,
            },
          ],
          onClick: () => {
            onTableBodyRowClick(deviceId);
          }
        }
      );
    });
  };

  const devicesTablehead = [
    {
      cells: [
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Status" id="tzMNF3" />,
          scope: 'col',
          sortable: {
            sortBy: sortByAlertLevel
          }
        },
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Device ID" id="f9m4vq" />,
          scope: 'col',
          sortable: {}
        },
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Gateway" id="pMcQck" />,
          scope: 'col',
          sortable: {
            sortBy: sortByGateway
          },
        },
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Functional location" id="lOOR5y" />,
          scope: 'col',
          sortable: {}
        },
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Lubricant left" id="r8ELxT" />,
          scope: 'col',
          sortable: {}
        },
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Dispense setting" id="avzi/E" />,
          scope: 'col',
          sortable: {},
        },
      ]
    }
  ];

  if (!devices || devices.length === 0) {
    return (
      <Text>
        <FormattedMessage
          defaultMessage="No {thing} or failed to load {thing}."
          values={{
            thing: <FormattedMessage
              defaultMessage="devices"
              id="wRUXwz"
            />
          }}
          id="EemuAN"
        />
      </Text>
    );
  }

  return (
    <Table
      caption={intl.formatMessage({
        defaultMessage: '{thing} table',
        id: 'CZDCWN'
      }, {
        thing: intl.formatMessage({ defaultMessage: 'Device', id: 'c3VJQo' })
      })}
      feCompact={true}
      feBody={renderDevicesTableBody()}
      feHead={devicesTablehead}
      feInteractive
    />
  );
};

TableDevices.propTypes = {
  devices: PropTypes.arrayOf(DeviceShape),
  onTableBodyRowClick: PropTypes.func.isRequired,
};

export default TableDevices;
