import {
  Button,
  Confirm,
  Dialog,
  Flexbox,
  Grid,
  GridItem,
  Heading,
  Icons,
  Image,
  Link, Progress,
  Spacer,
  Spacings,
  Text
} from '@skf-internal/ui-components-react-legacy';
import DeleteDialog from 'components/DeleteDialog';
import DialogContentDeleteDevice from 'components/DialogContentDeleteDevice';
import DialogContentUploadImage from 'components/DialogContentUploadImage';
import FormDevice from 'components/FormDevice';
import SLoader from 'components/SLoader';
import TableDeviceHistory from 'components/TableDeviceHistory';
import TemplateContent from 'components/TemplateContent';
import TemplateDetailsActions from 'components/TemplateDetailsActions';
import TemplatePersistingNotification from 'components/TemplatePersistingNotification';
import TextGroup from 'components/TextGroup';
import {
  APP_ID, CARTRIDGES, DeviceType, LENS_SYNC_STATUS, ROUTE_BASE
} from 'helpers/constants';
import {
  intlEmptySetting, intlFill, intlLensSyncStatus, intlLensSyncMessage
} from 'helpers/intlMethods';
import { AssetShape, DeviceShape, LubricantShape } from 'helpers/propTypes';
import { emptySettings } from 'helpers/utils';
import {
  alarmNumberToAlertLevel, alarmNumberToStatus, lensSyncToAlertLevel
} from 'helpers/ValueMapper';
import useDeviceWithData from 'hooks/useDeviceWithData';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as assetActions from 'redux/modules/asset';
import * as deviceActions from 'redux/modules/device';
import * as lubricantActions from 'redux/modules/lubricant';
import IconStatus from '../../components/IconStatus';

function mapState(state) {
  const {
    asset: { assets, loading: loadingAssets, loaded: loadedAssets },
    device: { devices, loading: loadingDevices, loaded: loadedDevices },
    lubricant: { lubricants: { items: lubricants, loading: loadingLubricants, loaded: loadedLubricants } },
  } = state;

  return {
    assets,
    loadingAssets,
    loadedAssets,
    devices,
    loadingDevices,
    loadedDevices,
    lubricants,
    loadingLubricants,
    loadedLubricants,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...deviceActions,
        ...assetActions,
        ...lubricantActions,
      },
      dispatch
    ),
  };
}

const DeviceDetailsView = ({
  actions,
  assets,
  loadingAssets,
  loadedAssets,
  lubricants,
  loadingLubricants,
  loadedLubricants,
  devices,
  loadingDevices,
  loadedDevices,
}) => {
  const intl = useIntl();
  const { deviceId: paramDeviceId } = useParams();
  const deviceId = paramDeviceId ? decodeURIComponent(paramDeviceId) : undefined;
  const uploadImageInput = useRef(null);
  const [device, setDevice] = useState(devices.find(d => d.deviceId === deviceId));
  const deviceWithData = useDeviceWithData(device);
  const [uploadProgress, setUploadProgess] = useState(undefined);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showUploadImageDialog, setShowUploadImageDialog] = useState(false);

  useEffect(() => {
    if (!loadedAssets) {
      actions.loadAssets();
    }

    if (!loadedDevices) {
      actions.loadDevices();
    }

    if (!loadedLubricants) {
      actions.loadLubricants();
    }
  }, []);

  useEffect(() => {
    if (!loadingDevices && devices.length > 0) {
      setDevice(devices.find(d => d.deviceId === deviceId));
    }
  }, [loadingDevices, devices]);

  useEffect(() => {
    if (device !== undefined && device.deviceId && !device.image) {
      actions.getImage(device.deviceId);
    }
  }, [device]);

  const handleEditDeviceClick = () => {
    setShowEditForm(true);
  };

  const handleEditCancelClick = () => {
    setShowEditForm(false);
  };

  const handleSubmit = values => {
    return actions.updateDevice({
      ...values,
      deviceConfig: {
        ...values.deviceConfig,
        emptyingTime: Number(values.deviceConfig.emptyingTime) || emptySettings()[0].value,
        cartridgeSize: Number(values.deviceConfig.cartridgeSize) || Number(CARTRIDGES[0]),
      },
      deviceId,
    });
  };

  const handleDeleteDeviceClick = () => {
    setShowDeleteForm(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteForm(false);
  };

  const handleDeleteConfirm = () => {
    if (!device) {
      return;
    }
    actions.deleteDevice(device.deviceId);
  };

  const handleAddImageClick = () => {
    setShowUploadImageDialog(true);
  };

  const handleAddImageClose = () => {
    setShowUploadImageDialog(false);
  };

  const handleAddImageCancel = () => {
    setShowUploadImageDialog(false);
  };

  const handleUploadStart = () => {
    uploadImageInput.current.uploadFile();
    setShowUploadImageDialog(false);
  };

  const handleUploadProgress = progress => {
    setUploadProgess(progress);
  };

  const handleUploadError = () => {
    setUploadProgess(undefined);
  };

  const handleUploadFinish = () => {
    actions.getImage(device.deviceId);
    setUploadProgess(undefined);
  };

  const handleDeleteImageClick = () => {
    actions.deleteImage(device);
  };

  if (loadingAssets || loadingDevices || loadingLubricants) {
    return <SLoader />;
  }

  if (deviceId === undefined || !device) {
    return (
      <Redirect to={ROUTE_BASE} />
    );
  }

  const {
    assetTag,
    assetName,
    location,
    application,
    deviceConfig: { cartridgeSize, lubricant, emptyingTime },
    deviceData,
    productId,
    lastGatewayId,
    image,
    deviceType,
    lensSync,
  } = deviceWithData;

  const { lubricantLeft = undefined, alarmNumber = undefined } = deviceData || {};

  return (
    <TemplateContent
      heading={(
        <Flexbox feJustifyContent="space-between">
          <Heading as="h2">
            <FormattedMessage
              defaultMessage="{thing} details - {value}"
              id="yFCfxD"
              values={{ thing: <FormattedMessage defaultMessage="Device" id="c3VJQo" />, value: productId }}
            />
          </Heading>
          {!showEditForm && (
            <TemplateDetailsActions
              edit={{ onClick: handleEditDeviceClick }}
              delete={{ onClick: handleDeleteDeviceClick }}
            />
          )}
        </Flexbox>
      )}
      linkBack={{
        to: '/dashboard/assets-and-devices/devices',
      }}
    >
      <Dialog
        feAppId={APP_ID}
        feDisplayCloseButton={true}
        feOnClose={() => {
          handleAddImageClose();
        }}
        open={showUploadImageDialog}
        title={(
          <FormattedMessage
            defaultMessage="Upload {thing}"
            id="XS9yXk"
            values={{ thing: <FormattedMessage defaultMessage="image" id="6zHQTa" /> }}
          />
        )}
      >
        <Confirm
          feButtons={{
            cancel: {
              children: <FormattedMessage defaultMessage="Cancel" id="47FYwb" />,
              onClick: handleAddImageCancel,
            },
            confirm: {
              children: <FormattedMessage defaultMessage="Upload" id="p4N05H" />,
              onClick: handleUploadStart,
            },
          }}
        >
          <DialogContentUploadImage
            inputRef={uploadImageInput}
            deviceId={deviceId}
            getSignedUrl={actions.getSignedUploadURL}
            onUploadProgress={handleUploadProgress}
            onUploadError={handleUploadError}
            onUploadFinish={handleUploadFinish}
          />
        </Confirm>
      </Dialog>
      <DeleteDialog
        openDialog={showDeleteForm}
        onCancel={handleDeleteCancel}
        onDelete={handleDeleteConfirm}
        isLoadingDevices={loadingDevices}
        title={(
          intl.formatMessage({
            defaultMessage: 'Delete {thing}',
            id: '23BjSJ',
          },
          {
            thing: intl.formatMessage({ defaultMessage: 'device', id: 'eY2JfQ' })
          })
        )}
      >
        <DialogContentDeleteDevice device={device} />
      </DeleteDialog>
      <Spacer feSpacing={Spacings.Xxl} />
      {showEditForm ? (
        <FormDevice
          formType="edit"
          initialState={{
            assetTag,
            assetName,
            location,
            application,
            deviceConfig: {
              cartridgeSize,
              lubricant,
              emptyingTime,
            },
            deviceId: productId,
            deviceType
          }}
          disabledFields={
            ['deviceId']
          }
          assets={assets}
          lubricants={lubricants}
          onSubmit={handleSubmit}
          cancel={{
            text: <FormattedMessage defaultMessage="Cancel" id="47FYwb" />,
            onClick: handleEditCancelClick
          }}
          submitBtnText={<FormattedMessage defaultMessage="Save changes" id="X0ha1a" />}
        />
      ) : (
        <>
          <>

            {lensSync && lensSync.status === LENS_SYNC_STATUS.FAIL
              ? (
                <TemplatePersistingNotification feType="error">
                  {intlLensSyncMessage(intl, lensSync.errorType)}
                </TemplatePersistingNotification>
              )
              : (
                <TemplatePersistingNotification feType="notification">
                  <FormattedMessage
                    defaultMessage="Updating configurations take time to be reflected onto the devices. It may take more than an hour to see these changes."
                    id="Ashj/L"
                  />
                </TemplatePersistingNotification>
              )}

            <Spacer feSpacing={Spacings.Md} />
          </>
          <Grid>
            <GridItem feColspan={{ desktop: 4, tablet: 6, mobile: 12 }}>
              <Heading as="h3">
                <FormattedMessage defaultMessage="Image" id="+0zv6g" />
              </Heading>
              <Spacer feSpacing={Spacings.Md} />
              {image && image.imageId ? (
                <div>
                  <Image src={image.url} alt="Image of device" feHeight="200px" />
                  <Spacer feSpacing={Spacings.Md} />
                  <Flexbox
                    feFlexDirection="column"
                    feJustifyContent="end"
                    feAlignItems="start"
                    feGap={Spacings.Md}
                  >
                    <Text>
                      <Link
                        as="a"
                        href={image.url}
                        target="_blank"
                        feIcon={{
                          feIcon: Icons.Download,
                          position: 'right',
                        }}
                      >
                        <FormattedMessage
                          defaultMessage="Download"
                          id="5q3qC0"
                        />
                      </Link>
                    </Text>
                    <Text>
                      <FormattedMessage
                        defaultMessage="Uploaded image can help find a particular device on an asset."
                        id="qSgwDE"
                      />
                    </Text>
                    <Flexbox feFlexDirection="row" feGap={Spacings.Xs}>
                      <Button
                        feSize="sm"
                        feDestructive
                        type="button"
                        onClick={handleDeleteImageClick}
                      >
                        <FormattedMessage defaultMessage="Delete" id="K3r6DQ" />
                      </Button>
                    </Flexbox>
                  </Flexbox>
                </div>
              ) : (
                <Flexbox
                  feFlexDirection="column"
                  feJustifyContent="end"
                  feAlignItems="start"
                  feGap={Spacings.Md}
                >
                  <Text>
                    <FormattedMessage
                      defaultMessage="Uploaded image can help find a particular device on an asset."
                      id="qSgwDE"
                    />
                  </Text>
                  <Button feSize="sm" type="button" onClick={handleAddImageClick}>
                    <FormattedMessage
                      defaultMessage="Add {thing}"
                      id="SziwLc"
                      values={{ thing: <FormattedMessage defaultMessage="image" id="6zHQTa" /> }}
                    />
                  </Button>
                </Flexbox>
              )}
              {uploadProgress && <Progress value={uploadProgress} />}
            </GridItem>
            <GridItem feColspan={{ desktop: 4, tablet: 6, mobile: 12 }}>
              <Heading as="h3">
                <FormattedMessage defaultMessage="Asset" id="WKCp0D" />
              </Heading>
              <Spacer feSpacing={Spacings.Md} />
              <Flexbox feFlexDirection="column" feGap={Spacings.Xs}>
                <TextGroup label={<FormattedMessage defaultMessage="Tag" id="18HJlm" />} text={assetTag} />
                <TextGroup label={<FormattedMessage defaultMessage="Name" id="HAlOn1" />} text={assetName} />
                <TextGroup label={<FormattedMessage defaultMessage="Functional location" id="lOOR5y" />} text={application} />
                <TextGroup label={<FormattedMessage defaultMessage="Lubrication point" id="5ri2Ns" />} text={location} />
              </Flexbox>
            </GridItem>
            <GridItem feColspan={{ desktop: 4, tablet: 6, mobile: 12 }}>
              <Heading as="h3">
                <FormattedMessage defaultMessage="Configuration" id="7OW8BT" />
              </Heading>
              <Spacer feSpacing={Spacings.Md} />
              <Flexbox feFlexDirection="column" feGap={Spacings.Xs}>
                <TextGroup label={<FormattedMessage defaultMessage="Device ID" id="f9m4vq" />} text={productId} />
                <TextGroup label={<FormattedMessage defaultMessage="Gateway" id="pMcQck" />} text={lastGatewayId} />
                <TextGroup label={<FormattedMessage defaultMessage="Lubricant" id="xzBmxi" />} text={lubricant} />
                <TextGroup label={<FormattedMessage defaultMessage="Cartridge size" id="yHfuIB" />} text={cartridgeSize || <FormattedMessage defaultMessage="Not set" id="p5LNtB" />} />
                <TextGroup
                  label={<FormattedMessage defaultMessage="Dispense setting" id="avzi/E" />}
                  text={intlEmptySetting(intl, emptyingTime, { nbMonths: deviceType === DeviceType.LUBRICATOR_V2 ? 24 : 12 })}
                />
              </Flexbox>
            </GridItem>
            <GridItem feColspan={{ desktop: 12, tablet: 6, mobile: 12 }}>
              <Heading as="h3">
                <FormattedMessage defaultMessage="Device state" id="HeNHtw" />
              </Heading>
              <Spacer feSpacing={Spacings.Md} />
              <Flexbox feFlexDirection="column" feGap={Spacings.Xs}>

                <TextGroup
                  label={<FormattedMessage defaultMessage="Status" id="tzMNF3" />}
                />
                {lensSync && lensSync.status !== LENS_SYNC_STATUS.OK ? (
                  <Text level={lensSync.status} title={`${lensSync.status}`}>
                    <IconStatus level={lensSyncToAlertLevel(lensSync.status)} />
                      &nbsp;&nbsp;
                    {intlLensSyncStatus(intl, lensSync.status)}
                  </Text>
                )
                  : (
                    <Text level={alarmNumber} title={`${alarmNumber}`}>
                      <IconStatus level={alarmNumberToAlertLevel(alarmNumber)} />
                      &nbsp;&nbsp;
                      {alarmNumberToStatus(alarmNumber)}
                    </Text>
                  )}
                <TextGroup
                  label={<FormattedMessage defaultMessage="Lubricant left" id="r8ELxT" />}
                  text={intlFill(intl, lubricantLeft)}
                />
              </Flexbox>
            </GridItem>
            {device.history && (
            <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
              <Heading as="h3">
                <FormattedMessage defaultMessage="State log" id="FXuuoM" />
              </Heading>
              <Spacer feSpacing={Spacings.Md} />
              <TableDeviceHistory
                history={device.history.map(event => ({ ...event }))}
              />
            </GridItem>
            )}
          </Grid>
        </>
      )}
    </TemplateContent>
  );
};

DeviceDetailsView.propTypes = {
  actions: PropTypes.shape({
    loadDevices: PropTypes.func,
    updateDevice: PropTypes.func,
    deleteDevice: PropTypes.func,
    loadAssets: PropTypes.func,
    loadLubricants: PropTypes.func,
    getImage: PropTypes.func,
    deleteImage: PropTypes.func,
    getSignedUploadURL: PropTypes.func,
  }),
  assets: PropTypes.arrayOf(AssetShape),
  loadingAssets: PropTypes.bool,
  loadedAssets: PropTypes.bool,
  lubricants: PropTypes.arrayOf(LubricantShape),
  loadingLubricants: PropTypes.bool,
  loadedLubricants: PropTypes.bool,
  devices: PropTypes.arrayOf(DeviceShape),
  loadingDevices: PropTypes.bool,
  loadedDevices: PropTypes.bool,
};

export default connect(mapState, mapDispatch)(DeviceDetailsView);
