import { cloneDevice } from '../helpers/cloning';
import {
  ALARM_NUMBERS,
  ALERT_LEVELS,
  DEVICE_STATUSES
} from '../helpers/constants';
import {
  alarmNumberToAlertLevel,
  alarmNumberToStatus,
  getGreaseUsage
} from '../helpers/ValueMapper';

const useDeviceWithData = device => {
  // Clone the original device to avoid prop mutation
  const deviceClone = cloneDevice({
    // Set default attributes to ensure all required fields exist
    status: DEVICE_STATUSES.DEFAULT,
    alertLevel: ALERT_LEVELS.ALERT,
    dataReceivedAt: undefined,
    empty: '--',
    ...device, // Override the default attributes with prop attributes
  });

  if (Object.keys(deviceClone.deviceData).length === 0) {
    deviceClone.deviceData.alarmNumber = ALARM_NUMBERS.NO_CONNECTION;
  }

  deviceClone.status = alarmNumberToStatus(deviceClone.deviceData.alarmNumber);
  deviceClone.empty = getGreaseUsage(deviceClone) || '--';

  deviceClone.alertLevel = alarmNumberToAlertLevel(
    deviceClone.deviceData.alarmNumber
  );
  // !IMPORTANT: the greaseUsed is wrongly named and stored, greaseUsed should be lubricantLeft
  deviceClone.deviceData.lubricantLeft = deviceClone.deviceData.greaseUsed;

  return deviceClone;
};

export default useDeviceWithData;
