import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import dashboardActiveIcon from 'images/icons/skf01_jrk_17003_dashboard_S_active_2017-09-18_a.svg';
import dashboardIcon from 'images/icons/skf01_jrk_17003_dashboard_S_normal_2017-09-18_a.svg';

import historyActiveIcon from 'images/icons/skf01_jrk_17003_settings_L_active_2017-09-18_a.svg';
import historyIcon from 'images/icons/skf01_jrk_17003_settings_L_normal_2017-09-18_a.svg';

import orgActiveIcon from 'images/icons/skf01_jrk_17003_search_L_active_2017-09-18_a.svg';
import orgIcon from 'images/icons/skf01_jrk_17003_search_L_normal_2017-09-18_a.svg';

import appIcon from 'images/icons/skf01_jrk_17003_high_pressure_2017-09-18_a.svg';
import { colors } from 'helpers/constants';

const Box = styled.div`
  vertical-align: top;

  flex: ${props => props.cols || 1} ${props => props.cols || 1} 0%;
  background-color: ${props => {
    return props.white ? 'white' : 'transparent';
  }};
  padding-right: ${props => props.container && '2rem'};

  @media (max-width: 784px) {
    margin-bottom: 1rem;
    padding-right: 0;
  }
`;

const Content = styled.div`
  margin: auto;
  display: flex;
  flex: 1 1 0%;
  width: 1280px;
  max-width: 100%;
  padding-bottom: 1rem;

  @media only screen and (max-width: 784px) {
    display: block;
  }
`;

const Navi = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;

  li {
    text-align: center;
    
    img {
      width: 4rem;
      vertical-align: middle;
      margin-right: 0.75rem;
      left: 0.75rem;
    }
  }

  @media (max-width: 784px) {
    li {
      img {
        display: block;
        margin: auto;
        padding-bottom: 0.5rem;
        padding-right: 0.5rem;
      }
    }
  }
`;

const Purple = styled.span`
  color: ${colors.purple};
  font-weight: bold;
`;

const Blue = styled.span`
  color: ${colors.blue};
`;

const LightGray = styled.span`
  color: ${colors.lightGray};
`;

const RootElement = styled(Content)`
  > div:first-child {
    padding-left: 1rem;
    padding-top: 1.5rem;

    @media (max-width: 784px) {
      display: none;
    }
  }
  > div > img {
    width: 4rem;
    float: left;
    margin-top: -0.5rem;
    margin-right: 1rem;
  }
  > div > a {
    float: right;
  }
`;

class AdminSuperMenu extends Component {
  highlightIfActive(r, comp) {
    // const route = this.props.router.route.location.pathname;
    const route = this.props.location.pathname;
    return r === route ? <Blue>{comp}</Blue> : <LightGray>{comp}</LightGray>;
  }

  render() {
    const route = this.props.location.pathname;
    return (
      <RootElement>
        <Box white className="brand">
          <img src={appIcon} alt="" />
          <Purple>Super admin</Purple>
          <br />
          tools
        </Box>

        <Box cols={4}>
          <Navi>
            <li>
              <Link className="brand" to="/admin-super/dashboard">
                <img src={route === '/admin-super/dashboard' ? dashboardActiveIcon : dashboardIcon} alt="" />
                {this.highlightIfActive(
                  '/admin-super/dashboard',
                  <FormattedMessage id="hzSNj4" defaultMessage="Dashboard" />
                )}
              </Link>
              &nbsp;
              &nbsp;
              &nbsp;
            </li>
            <li>
              <Link className="brand" to="/admin-super/export-history">
                <img src={route === '/admin-super/export-history' ? historyActiveIcon : historyIcon} alt="" />
                {this.highlightIfActive(
                  '/admin-super/export-history',
                  <FormattedMessage id="H0COit" defaultMessage="Data export history" />
                )}
              </Link>
              &nbsp;
              &nbsp;
              &nbsp;
            </li>
            <li>
              <Link className="brand" to="/admin-super/organizations">
                <img src={route === '/admin-super/organizations' ? orgActiveIcon : orgIcon} alt="" />
                {this.highlightIfActive(
                  '/admin-super/organizations',
                  <FormattedMessage id="JD2pwH" defaultMessage="Organizations" />
                )}
              </Link>
            </li>
          </Navi>
        </Box>
      </RootElement>
    );
  }
}

AdminSuperMenu.propTypes = {
  location: PropTypes.object
};

AdminSuperMenu.contextTypes = {
  router: PropTypes.object,
  location: PropTypes.object
};

export default withRouter(AdminSuperMenu);
