import {
  Flexbox,
  Heading,
  Link as FeLink, Spacer,
  Spacings
} from '@skf-internal/ui-components-react-legacy';
import DeleteDialog from 'components/DeleteDialog';
import DialogContentDeleteLubricantManufacturer from 'components/DialogContentDeleteLubricantManufacturer';
import FormLubricantManufacturer from 'components/FormLubricantManufacturer';
import SLoader from 'components/SLoader';
import TableLubricants from 'components/TableLubricants';
import TemplateContent from 'components/TemplateContent';
import TemplateDetailsActions from 'components/TemplateDetailsActions';
import TemplateSection from 'components/TemplateSection';
import { ROUTE_BASE, ROUTE_DASHBOARD_MANUFACTURERS } from 'helpers/constants';
import { DeviceShape, LubricantManufacturerShape, LubricantShape } from 'helpers/propTypes';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  Link, Redirect, useParams, useRouteMatch
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as authActions from 'redux/modules/auth';
import * as deviceActions from 'redux/modules/device';
import * as lubricantActions from 'redux/modules/lubricant';

function mapState(state) {
  const {
    auth: { organizationId, loading: loadingAuth, loaded: loadedAuth },
    device: { devices, loading: loadingDevices, loaded: loadedDevices },
    lubricant: {
      manufacturers: {
        items: manufacturers,
        loading: loadingManufacturers,
        loaded: loadedManufacturers,
      },
      lubricants: {
        items: lubricants,
        loading: loadingLubricants,
        loaded: loadedLubricants,
      }
    },
  } = state;

  return {
    organizationId,
    loadingAuth,
    loadedAuth,
    devices,
    loadingDevices,
    loadedDevices,
    lubricants,
    loadingLubricants,
    loadedLubricants,
    manufacturers,
    loadingManufacturers,
    loadedManufacturers,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({
      ...authActions, ...deviceActions, ...lubricantActions
    }, dispatch)
  };
}

const LubricantManufacturerDetailsView = ({
  actions,
  organizationId,
  loadingAuth,
  loadedAuth,
  devices,
  loadingDevices,
  loadedDevices,
  lubricants,
  loadingLubricants,
  loadedLubricants,
  manufacturers,
  loadingManufacturers,
  loadedManufacturers,
}) => {
  const intl = useIntl();
  const { url } = useRouteMatch();
  const { manufacturerName: paramManufacturerName } = useParams();
  const manufacturerName = paramManufacturerName ? decodeURIComponent(paramManufacturerName) : undefined;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    if (!loadedManufacturers) {
      actions.loadLubricantManufacturers();
    }

    if (!loadedLubricants) {
      actions.loadLubricants();
    }

    if (!loadedDevices) {
      actions.loadDevices();
    }

    if (!loadedAuth) {
      actions.getAccount();
    }
  }, []);

  const handleEditCancelClick = () => {
    setShowEditForm(false);
  };

  const handleEditAssetClick = () => {
    setShowEditForm(true);
  };

  const handleEditSubmit = values => {
    return actions.updateLubricantManufacturer(
      manufacturerName, // Original name, the name is changeable.
      values
    );
  };

  const handleDeleteClick = () => {
    setShowDeleteDialog(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteDialog(false);
  };

  const handleDeleteConfirm = () => {
    actions.deleteLubricantManufacturer(manufacturerName);
  };

  const handleDeleteConfirmButtonClick = denomination => {
    actions.deleteLubricant(manufacturerName, denomination);
  };

  const loading = loadingDevices || loadingAuth || loadingManufacturers || loadingLubricants;

  if (loading) {
    return <SLoader />;
  }

  const manufacturer = manufacturers.find(m => m.name === manufacturerName);
  if (manufacturerName === undefined || !manufacturer) {
    return <Redirect to={ROUTE_BASE} />;
  }

  const manufacturerLubricants = lubricants.filter(l => l.manufacturerName === manufacturerName);
  const actionsDisabled = manufacturerLubricants.length > 0;

  return (
    <TemplateContent
      heading={(
        <Flexbox feJustifyContent="space-between">
          <Heading as="h2">
            <FormattedMessage
              defaultMessage="{thing} details - {value}"
              id="yFCfxD"
              values={{
                thing: <FormattedMessage
                  defaultMessage="Manufacturers"
                  id="8mr1Hh"
                />,
                value: manufacturer.name
              }}
            />
          </Heading>
          {!showEditForm && (
            <TemplateDetailsActions
              edit={{
                onClick: handleEditAssetClick,
                disabled: actionsDisabled,
                title: actionsDisabled
                  ? intl.formatMessage({
                    defaultMessage: 'You cannot {action} a lubricant manufacturer that has one or more lubricants.',
                    id: 'Eou1A9'
                  }, {
                    action: intl.formatMessage({
                      defaultMessage: 'edit',
                      id: '+vj0U3'
                    })
                  })
                  : '',
              }}
              delete={{
                onClick: handleDeleteClick,
                disabled: actionsDisabled,
                title: actionsDisabled
                  ? intl.formatMessage({
                    defaultMessage: 'You cannot {action} a lubricant manufacturer that has one or more lubricants.',
                    id: 'Eou1A9'
                  }, {
                    action: intl.formatMessage({
                      defaultMessage: 'delete',
                      id: 'sznaQh'
                    })
                  })
                  : '',
              }}
            />
          )}
        </Flexbox>
      )}
      linkBack={{
        to: ROUTE_DASHBOARD_MANUFACTURERS
      }}
    >
      <DeleteDialog
        open={showDeleteDialog}
        onCancel={handleDeleteCancel}
        onDelete={handleDeleteConfirm}
        title={(
          intl.formatMessage({
            defaultMessage: 'Delete {thing}',
            id: '23BjSJ',
          },
          {
            thing: intl.formatMessage({ defaultMessage: 'manufacturer', id: 'RPkvDY' })
          })
        )}
      >
        <DialogContentDeleteLubricantManufacturer
          manufacturer={manufacturer}
        />
      </DeleteDialog>
      {showEditForm
        && (
          <TemplateSection>
            <FormLubricantManufacturer
              formType="edit"
              initialState={{ name: manufacturer.name }}
              cancel={{
                text: <FormattedMessage defaultMessage="Cancel" id="47FYwb" />,
                onClick: handleEditCancelClick
              }}
              submitBtnText={<FormattedMessage defaultMessage="Save changes" id="X0ha1a" />}
              onSubmit={handleEditSubmit}
            />
          </TemplateSection>
        )}
      <TemplateSection>
        <Heading as="h3">
          <FormattedMessage
            defaultMessage="{thing} table"
            values={{ thing: <FormattedMessage defaultMessage="Lubricants" id="Z6afvc" /> }}
            id="CZDCWN"
          />
        </Heading>
        <Spacer feSpacing={Spacings.Md} />
        <Flexbox feGap={Spacings.Md} feJustifyContent="flex-end" feAlignItems="center">
          <Link to={`${url}/add-lubricant`}>
            <FeLink
              as="button"
              type="button"
              feIcon={{
                feIcon: 'Plus',
                position: 'right'
              }}
            >
              <FormattedMessage
                defaultMessage="Add {thing}"
                id="SziwLc"
                values={{ thing: <FormattedMessage defaultMessage="lubricant" id="muUpZ3" /> }}
              />
            </FeLink>
          </Link>
        </Flexbox>
        <Spacer feSpacing={Spacings.Md} />
        <TableLubricants
          loading={loading}
          organizationId={organizationId}
          devices={devices}
          lubricants={manufacturerLubricants}
          onDeleteConfirmClick={handleDeleteConfirmButtonClick}
        />
      </TemplateSection>
    </TemplateContent>
  );
};

LubricantManufacturerDetailsView.propTypes = {
  actions: PropTypes.shape({
    getAccount: PropTypes.func,
    loadDevices: PropTypes.func,
    loadLubricants: PropTypes.func,
    loadLubricantManufacturers: PropTypes.func,
    updateLubricantManufacturer: PropTypes.func,
    deleteLubricant: PropTypes.func,
    deleteLubricantManufacturer: PropTypes.func,
    changeActiveLubricantManufacturer: PropTypes.func,
    changeActiveView: PropTypes.func,
    changeActiveLubricantsAndLubricantManufacturersView: PropTypes.func,
  }),
  organizationId: PropTypes.string,
  loadingAuth: PropTypes.bool,
  loadedAuth: PropTypes.bool,
  devices: PropTypes.arrayOf(DeviceShape),
  loadingDevices: PropTypes.bool,
  loadedDevices: PropTypes.bool,
  lubricants: PropTypes.arrayOf(LubricantShape),
  loadingLubricants: PropTypes.bool,
  loadedLubricants: PropTypes.bool,
  manufacturers: PropTypes.arrayOf(LubricantManufacturerShape),
  loadingManufacturers: PropTypes.bool,
  loadedManufacturers: PropTypes.bool,
};

export default connect(mapState, mapDispatch)(LubricantManufacturerDetailsView);
