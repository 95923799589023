import SLoader from 'components/SLoader';
import 'es6-shim';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from 'redux/modules/admin';

function mapState(state) {
  const { admin } = state;
  return {
    admin
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...adminActions }, dispatch)
  };
}

class AdminSuperDashboardExportHistoryDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    this.props.actions.loadXLSX(params.file);
  }

  render() {
    if (this.props.admin.loading) {
      return <SLoader />;
    }
    if (this.props.admin.data.devicesXLS) {
      window.open(this.props.admin.data.devicesXLS, '_blank');
      window.history.back();
    }
    return '';
  }
}

AdminSuperDashboardExportHistoryDownload.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.any
};

AdminSuperDashboardExportHistoryDownload.propTypes = {
  actions: PropTypes.object,
};

export default connect(mapState, mapDispatch)(AdminSuperDashboardExportHistoryDownload);
