const SCREEN_RESIZE = 'lcp/dashboard/SCREEN_RESIZE';

const initialState = {
  width: 0,
};

export default function ui(state = initialState, action = {}) {
  switch (action.type) {
    case SCREEN_RESIZE: {
      return {
        ...state,
        width: action.width,
      };
    }
    default:
      return state;
  }
}

export function screenResize(width) {
  return {
    type: SCREEN_RESIZE,
    width,
  };
}
