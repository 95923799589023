import React from 'react';
import PropTypes from 'prop-types';
import { Errors } from 'react-redux-form';
import {
  Colors,
  Text,
} from '@skf-internal/ui-components-react-legacy';

const ControlError = ({ model, messages, show }) => {
  return (
    <Text feColor={Colors.RedBase}>
      <Errors
        model={model}
        messages={messages}
        show={show}
      />
    </Text>
  );
};

ControlError.propTypes = {
  model: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  show: PropTypes.func,
};

ControlError.defaultProps = {
  show: field => field.touched && !field.focus,
};

export default ControlError;
