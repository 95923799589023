import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as notifications } from 'react-notification-system-redux';

import intl from './intl';
import auth from './auth';
import device from './device';
import gateway from './gateway';
import redirect from './redirect';
import organization from './organization';
import metadata from './metadata';
import notification from './notification';
import admin from './admin';
import csvTemplate from './csv-template';
import lubricant from './lubricant';
import asset from './asset';
import dashboard from './dashboard';
import ui from './ui';

export default combineReducers({
  routing: routerReducer,
  intl,
  auth,
  device,
  gateway,
  redirect,
  notifications,
  organization,
  metadata,
  notification,
  admin,
  csvTemplate,
  lubricant,
  asset,
  dashboard,
  ui,
});
