import noSignal from 'images/icons/skf01_jrk_17003_no_signal_2017-11-28_a.svg';
import signal0 from 'images/icons/skf01_jrk_17003_signal_0__2017-11-23_a.svg';
import signal1 from 'images/icons/skf01_jrk_17003_signal_1__2017-11-23_a.svg';
import signal2 from 'images/icons/skf01_jrk_17003_signal_2__2017-11-23_a.svg';
import signal3 from 'images/icons/skf01_jrk_17003_signal_3__2017-11-23_a.svg';
import signal4 from 'images/icons/skf01_jrk_17003_signal_4__2017-11-23_a.svg';
import iconDemo from 'images/icons/skf01_jrk_17003_demo__2017-11-23_a.svg';
import iconEmptyBattery from 'images/icons/skf01_jrk_17003_empty_battery_2017-09-18_a.svg';
import iconEmptyCardridge from 'images/icons/skf01_jrk_17003_empty_cardridge_2017-09-18_a.svg';
import iconFailure from 'images/icons/skf01_jrk_17003_failure_2017-09-18_a.svg';
import iconBlocked from 'images/icons/skf01_jrk_17003_blocked_2017-09-18_a.svg';
// import iconLowCardridge from 'images/icons/skf01_jrk_17003_low_cardridge_2017-09-18_a.svg';
import iconLowBattery from 'images/icons/skf01_jrk_17003_low_battery_2017-09-18_a.svg';
import iconHighPressure from 'images/icons/skf01_jrk_17003_high_pressure_2017-09-18_a.svg';

import {
  ALERT_LEVELS, ALARM_NUMBERS, DEVICE_STATUSES, ALERT_COLORS, LENS_SYNC_STATUS
} from './constants';

export function alarmnumToStatus(key) {
  switch (key) {
    case 0:
      return 'OFF';
    case 1:
      return 'OK';
    case 2:
      return 'Empty alarm';
    case 3:
      return 'Low battery';
    case 4:
      return 'Empty battery';
    case 5:
      return 'High pressure warning';
    case 6:
      return 'High pressure alarm';
    case 7:
      return 'Internal alarm';
    case 8:
      return 'End of demo';
    case 998:
      return 'No connection';
    default:
      return 'Waiting for connection';
  }
}

export function alarmnumToIcon(key) {
  switch (key) {
    case 1:
      return null;
    case 2:
      return iconEmptyCardridge;
    case 3:
      return iconLowBattery;
    case 4:
      return iconEmptyBattery;
    case 5:
      return iconHighPressure;
    case 6:
      return iconBlocked; // Bad naming, this is really "high pressure alarm". Icon mis-match.
    case 7:
      return iconFailure; // Internal alarm
    case 8:
      return iconDemo;
    case 998:
      return noSignal;
    default:
      return iconFailure;
  }
}

export function signalToIcon(signal) {
  if (signal > -67) {
    return signal4;
  }
  if (signal > -80) {
    return signal3;
  }
  if (signal > -93) {
    return signal2;
  }
  if (signal > -106) {
    return signal1;
  } if (signal) {
    return signal0;
  }

  return noSignal;
}

/**
 * Find color for alarm number
 *
 * @param {number} alarmNumber
 */
export const alarmNumberToColor = alarmNumber => {
  switch (alarmNumber) {
    case ALARM_NUMBERS.DEVICE_OFF:
      return ALERT_COLORS.DEVICE_OFF;
    case ALARM_NUMBERS.DEVICE_OK:
      return ALERT_COLORS.DEVICE_OK;
    case ALARM_NUMBERS.LOW_BATTERY: // Warnings
    case ALARM_NUMBERS.HIGH_PRESSURE_WARNING:
      return ALERT_COLORS.WARNING;
    default: // Alerts
      return ALERT_COLORS.ALERT;
  }
};

/**
 * Find color for alert level
 *
 * @param {number} alertLevel
 */
export const alertLevelToColor = alertLevel => {
  switch (alertLevel) {
    case ALERT_LEVELS.DEVICE_OFF:
      return ALERT_COLORS.DEVICE_OFF;
    case ALERT_LEVELS.DEVICE_OK:
      return ALERT_COLORS.DEVICE_OK;
    case ALERT_LEVELS.WARNING:
      return ALERT_COLORS.WARNING;
    default:
      return ALERT_COLORS.ALERT;
  }
};

/**
 * Find status for alarm number
 *
 * @param {number} alarmNumber
 */
export const alarmNumberToStatus = alarmNumber => {
  const statusKey = Object.keys(ALARM_NUMBERS).find(key => ALARM_NUMBERS[key] === alarmNumber);
  return DEVICE_STATUSES[statusKey] || DEVICE_STATUSES.DEFAULT;
};

/**
 * Find alert level for alarm number
 *
 * @param {number} alarmNumber
 */
export const alarmNumberToAlertLevel = alarmNumber => {
  switch (alarmNumber) {
    case ALARM_NUMBERS.DEVICE_OFF:
      return ALERT_LEVELS.DEVICE_OFF;
    case ALARM_NUMBERS.DEVICE_OK:
      return ALERT_LEVELS.DEVICE_OK;
    case ALARM_NUMBERS.LOW_BATTERY: // Warnings
    case ALARM_NUMBERS.HIGH_PRESSURE_WARNING:
      return ALERT_LEVELS.WARNING;
    case ALARM_NUMBERS.NO_CONNECTION:
      return ALERT_LEVELS.DEVICE_NO_CONNECTION;
    default: // Alerts
      return ALERT_LEVELS.ALERT;
  }
};

export const lensSyncToAlertLevel = lensSync => {
  switch (lensSync) {
    case LENS_SYNC_STATUS.FAIL:
      return ALERT_LEVELS.ALERT;
    case LENS_SYNC_STATUS.PENDING:
      return ALERT_LEVELS.DEVICE_NO_CONNECTION;
    default:
      return ALERT_LEVELS.ALERT;
  }
};

/**
 * Get device grease usage
 */
export const getGreaseUsage = device => (
  // eslint-disable-next-line no-mixed-operators
  Math.round(device.deviceData.greaseUsed / 100 * 52 / 12 * device.deviceConfig.emptyingTime)
);
