import SLoader from 'components/SLoader';
import Menu from 'containers/AdminSuperMenu';
import 'es6-shim';
import { colors } from 'helpers/constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as adminActions from 'redux/modules/admin';
import styled from 'styled-components';

const RootElement = styled.div`
  flex: 1 1 0%;

  button {
    float: right;
  }
`;

const Content = styled.div`
  margin: auto;
  display: flex;
  flex: 1 1 0%;
  width: 1280px;
  max-width: 100%;
  padding-bottom: 1rem;

  @media only screen and (max-width: 784px) {
    display: block;
  }
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 auto;
  width: 1280px;

  @media(max-width:576px) {
    display: block;
  }
`;

const GrayBox = styled.div`
  background-color: ${colors.lightestGray};
  flex: 10 10 0%;
  padding: 1rem;
`;

const HistoryTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  thead tr,
  tbody tr {
    background-color: white;
    border-bottom: 1px solid #ebf0f0;
  }

  td,
  th {
    text-align: left;
    padding: 0.3rem 1rem;
    white-space: normal;
    min-width: 50px;
  }

  .wider {
    min-width: 70px;
  }

  .fixedWidth {
    white-space: nowrap;
    word-break: unset;
  }
`;

function mapState(state) {
  const { admin } = state;
  return {
    admin
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...adminActions }, dispatch)
  };
}

class AdminSuperDashboardExportHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.actions.loadExportHistory();
  }

  render() {
    if (this.props.admin.loading) {
      return <SLoader />;
    }
    return (
      <RootElement>
        <Menu />
        <GrayBox>
          <div>
            <Content>
              <h2>Data export history</h2>
            </Content>
            <ColumnsContainer>
              <HistoryTable tableborder="0">
                <colgroup>
                  <col style={{ width: '50%' }}/>
                  <col style={{ width: '50%' }}/>
                </colgroup>
                <thead>
                  <tr>
                    <th>Datetime</th>
                    <th>Link</th>
                  </tr>
                </thead>
                <tbody>
                  <HistoryList history={this.props.admin.data.exportsKeys}/>
                </tbody>
              </HistoryTable>
            </ColumnsContainer>
          </div>
        </GrayBox>
      </RootElement>
    );
  }
}

const HistoryList = ({ history }) => {
  if (history) {
    return [...history].reverse().map(event => <HistyoryItem event={event} />);
  }
  return [];
};

const HistyoryItem = ({ event }) => {
  const d = event.split('.')[0].split('-')[1];
  const time = moment(d * 1000);
  const lastDataReceivedAtStr = time.format('lll');
  return (
    <tr>
      <td>{lastDataReceivedAtStr}</td>
      <td>
        <Link className="brand" to={`/admin-super/export-history/download?file=${event}`}>
          Download
        </Link>
      </td>
    </tr>
  );
};

HistyoryItem.propTypes = {
  event: PropTypes.object,
};

AdminSuperDashboardExportHistory.propTypes = {
  admin: PropTypes.object,
};

AdminSuperDashboardExportHistory.propTypes = {
  actions: PropTypes.object,
};

export default connect(mapState, mapDispatch)(AdminSuperDashboardExportHistory);
