import React from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Button,
  Card, Flexbox, Heading, Link, Spacer, Text
} from '@skf-internal/ui-components-react-legacy';

import { IntlShape } from 'helpers/propTypes';

const InOrbitOrganizationView = ({ intl }) => {
  const text = intl.formatMessage({
    defaultMessage: 'Dear SKF LCP user,\n\nWe are excited to inform you that your account has been successfully migrated to our new SKF Lubrication Cloud platform. This change is part of our continuous effort to provide you with the best possible experience.\n\nYou can access the new platform by clicking on the following link:\n\n{orbitLink}\n\nPlease note that all your account details and preferences have been preserved. To access your account, simply use your existing email via the new platform website. If you encounter any issues or have any questions, please do not hesitate to contact our Technical Support Group (TSG) via the following link:\n\n{supportTeam}\n\nWe appreciate your understanding and cooperation during this transition. We hope you enjoy the new features and improvements we have developed for you and we look forward to hearing your feedback.\n\nThank you for choosing us as your trusted lubrication partner and for your continued support,\n\nThe SKF Lubrication Cloud team',
    id: 'ZZVllo'
  }, {
    orbitLink: (
      <Link as="a" href="https://www.lubrication-cloud.skf.com/">
        https://www.lubrication-cloud.skf.com/
      </Link>
    ),
    supportTeam: (
      <Link as="a" href="https://www.skf.com/cm/tsg">
        https://www.skf.com/cm/tsg
      </Link>
    )
  });
  return (
    <Flexbox feAlignItems="center" feJustifyContent="center" style={{ minHeight: '100vh' }}>
      <Card style={{ width: '32em' }}>
        <Heading as="h3" style={{ textAlign: 'center' }}>
          <FormattedMessage
            defaultMessage="Your SKF LCP account has been migrated"
            id="9Fdd1j"
          />
        </Heading>
        <Spacer />
        <Spacer />
        <Text style={{ whiteSpace: 'pre-line' }}>
          {text}
        </Text>
        <Spacer />
        <Spacer />
        <Flexbox feJustifyContent="center">
          <Button onClick={() => {
            window.location = `${process.env.REACT_APP_SSO_URL}/logout?app=${process.env.REACT_APP_SSO_APPKEY}`;
          }}
          >
            <FormattedMessage
              defaultMessage="Sign out"
              id="xXbJso"
            />
          </Button>
        </Flexbox>
      </Card>
    </Flexbox>
  );
};

InOrbitOrganizationView.propTypes = {
  intl: IntlShape
};

export default compose(injectIntl)(InOrbitOrganizationView);
