const CHANGE_SHOW_ALL_ASSETS = 'lcp/dashboard/CHANGE_SHOW_ALL_ASSETS';

const initialState = {
  showAllAssets: true,
};

export default function dashboard(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_SHOW_ALL_ASSETS: {
      return {
        ...state,
        showAllAssets: action.showAllAssets,
      };
    }
    default:
      return state;
  }
}

export function changeShowAllAssets(nextState) {
  return {
    type: CHANGE_SHOW_ALL_ASSETS,
    showAllAssets: nextState,
  };
}
