import {
  Breakpoints,
  Flexbox, Heading, Search, Spacer, Spacings, Text
} from '@skf-internal/ui-components-react-legacy';
import ActionButtonAdd from 'components/ActionButtonAdd';
import SLoader from 'components/SLoader';
import TableLubricantManufacturers from 'components/TableLubricantManufacturers';
import TemplateSection from 'components/TemplateSection';
import { ROUTE_DASHBOARD_MANUFACTURERS } from 'helpers/constants';
import { LubricantManufacturerShape } from 'helpers/propTypes';
import useFuse from 'hooks/useFuse';
import PropTypes from 'prop-types';
import React, {
  useEffect
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  Route, Switch as RouterSwitch, useHistory
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as lubricantActions from 'redux/modules/lubricant';

function mapState(state) {
  const {
    lubricant: { manufacturers: { items: manufacturers, loading, loaded } },
    ui: { width },
  } = state;

  return {
    manufacturers,
    loading,
    loaded,
    width,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(lubricantActions, dispatch)
  };
}

const DashboardLubricantsView = ({
  actions, manufacturers, loading, loaded, width
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { hits, onSearch, onReset } = useFuse(manufacturers, {
    keys: ['name'],
    matchAllOnEmptyQuery: true,
    shouldSort: false,
    threshold: 0,
    ignoreLocation: true
  });

  useEffect(() => {
    if (!loaded) {
      actions.loadLubricantManufacturers();
    }
  }, []);

  const handleLubricantManufacturerRowClick = name => {
    history.push(`/dashboard/lubricants-and-manufacturers/lubricant-manufacturers/${encodeURIComponent(name)}`);
  };

  if (loading) {
    return <SLoader />;
  }

  return (
    <RouterSwitch>
      <Route exact path={ROUTE_DASHBOARD_MANUFACTURERS}>
        <TemplateSection>
          <Heading as="h2">
            <FormattedMessage
              defaultMessage="Lubricant manufacturers"
              id="fPsNkT"
            />
          </Heading>
          <Spacer feSpacing={Spacings.Md} />
          <Flexbox feGap={Spacings.Md} feFlexDirection="column">
            <Text>
              <FormattedMessage
                defaultMessage="Lubricant manufacturers are brands that produce oil or grease. In this view you can manage them."
                id="+YohjS"
              />
            </Text>
          </Flexbox>
        </TemplateSection>
        <TemplateSection>
          <Heading as="h3">
            <FormattedMessage
              defaultMessage="{thing} table"
              values={{
                thing: <FormattedMessage
                  defaultMessage="Lubricant manufacturers"
                  id="fPsNkT"
                />
              }}
              id="CZDCWN"
            />
          </Heading>
          <Spacer feSpacing={Spacings.Md} />
          <Flexbox
            feGap={Spacings.Md}
            feJustifyContent="space-between"
            feFlexDirection={width >= Breakpoints.Tablet ? 'row' : 'column'}
            feAlignItems={width >= Breakpoints.Tablet ? 'center' : 'flex-start'}
          >
            <Flexbox>
              <Search
                onChange={onSearch}
                feResetButton={{
                  onClick: onReset
                }}
                placeholder={(intl.formatMessage({ defaultMessage: 'Search...', id: '0BUTMv' }))}
                feHint={(
                  intl.formatMessage(
                    {
                      defaultMessage: 'Search by {list}',
                      id: 'UlWvQl',
                    },
                    {
                      list: intl.formatList([
                        intl.formatMessage({
                          defaultMessage: 'Name', id: 'HAlOn1',
                        }),
                      ], { type: 'conjunction' })
                    }
                  )
                )}
                feLabel="Search by Name"
                feHideLabel
              />
            </Flexbox>
            <Flexbox
              feGap={Spacings.Md}
              feJustifyContent="flex-end"
              feAlignItems="center"
            >
              <ActionButtonAdd
                to={`${ROUTE_DASHBOARD_MANUFACTURERS}/add-lubricant-manufacturer`}
                text={<FormattedMessage defaultMessage="Add lubricant manufacturer" id="kMW88f" />}
              />
            </Flexbox>
          </Flexbox>
        </TemplateSection>
        <TableLubricantManufacturers manufacturers={hits.map(hit => hit.item)} onTableBodyRowClick={handleLubricantManufacturerRowClick} />
      </Route>
    </RouterSwitch>
  );
};

DashboardLubricantsView.propTypes = {
  actions: PropTypes.shape({
    loadLubricantManufacturers: PropTypes.func,
  }),
  manufacturers: PropTypes.arrayOf(LubricantManufacturerShape),
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  width: PropTypes.number,
};

export default connect(mapState, mapDispatch)(DashboardLubricantsView);
