import {
  Icon,
  IconColors,
  Icons,
  Table,
  Text
} from '@skf-internal/ui-components-react-legacy';
import ActionLinkDelete from 'components/ActionLinkDelete';
import DeleteDialog from 'components/DeleteDialog';
import DialogContentDeleteGateway from 'components/DialogContentDeleteGateway';
import IconStatus from 'components/IconStatus';
import { GATEWAY_STATUSES, LENS_SYNC_STATUS } from 'helpers/constants';
import { GatewayShape } from 'helpers/propTypes';
import { lensSyncToAlertLevel } from 'helpers/ValueMapper';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { intlLensSyncStatus } from 'helpers/intlMethods';
import {
  FormattedMessage, useIntl
} from 'react-intl';

const TableGateway = ({ gateways, onDeleteConfirmClick }) => {
  const intl = useIntl();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteGateway, setDeleteGateway] = useState(undefined);

  const sortByStatus = ({ status }) => `${status}`;

  const handleDeleteClick = gateway => {
    setDeleteGateway(gateway);
    setShowDeleteDialog(true);
  };

  const handleDeleteCancel = () => {
    setDeleteGateway(undefined);
  };

  const handleDeleteConfirm = () => {
    if (deleteGateway) {
      onDeleteConfirmClick(deleteGateway);
    }
  };

  const renderGatewaysTableBody = () => {
    return gateways.map(gateway => {
      let pingLastReceivedAtStr = intl.formatMessage({
        id: 'kh7rOP',
        defaultMessage: 'No gateway ping received'
      });

      if (gateway.pingReceivedAt) {
        const pingTime = moment(gateway.pingReceivedAt);
        pingLastReceivedAtStr = `${pingTime.fromNow()} (${pingTime.format('lll')})`;
      }

      const status = typeof gateway.status === 'number' ? gateway.status : '--';

      return (
        {
          cells: [
            {
              children: (
                gateway.lensSync && gateway.lensSync.status !== LENS_SYNC_STATUS.OK ? (
                  <Text level={gateway.lensSync.status} title={`${gateway.lensSync.status}`}>
                    <IconStatus level={lensSyncToAlertLevel(gateway.lensSync.status)} />
                      &nbsp;&nbsp;
                    {intlLensSyncStatus(intl, gateway.lensSync.status)}
                  </Text>
                )
                  : (
                    <Text
                      as="span"
                      title={status === GATEWAY_STATUSES.OFFLINE ? intl.formatMessage({ defaultMessage: 'No connection', id: 'NP4I0A' }) : ''}
                      status={status}
                    >
                      <Icon
                        feIcon={status === GATEWAY_STATUSES.ONLINE ? Icons.Connection4 : Icons.WarningDiamond}
                        feColor={status === GATEWAY_STATUSES.ONLINE ? IconColors.Green : IconColors.Red}
                      />
                    </Text>
                  )
              )
            },
            {
              children: gateway.gatewayId,
            },
            {
              children: gateway.name || '--',
            },
            {
              children: pingLastReceivedAtStr,
            },
            {
              children: (
                <ActionLinkDelete
                  onClick={() => {
                    handleDeleteClick(gateway);
                  }}
                  icon
                />
              )
            }
          ],
        }
      );
    });
  };

  const gatewaysTableHead = [
    {
      cells: [
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Status" id="tzMNF3" />,
          scope: 'col',
          sortable: {
            sortBy: sortByStatus
          }
        },
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Gateway ID" id="v4p4ra" />,
          scope: 'col',
          sortable: {}
        },
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Name" id="HAlOn1" />,
          scope: 'col',
          sortable: {}
        },
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Last gateway ping received" id="3TMo3Q" />,
          scope: 'col'
        },
        {
          as: 'th',
          children: '',
          scope: 'col'
        }
      ]
    }
  ];

  if (!gateways || gateways.length === 0) {
    return (
      <Text>
        <FormattedMessage
          defaultMessage="No {thing} or failed to load {thing}."
          id="EemuAN"
          values={{
            thing: <FormattedMessage
              defaultMessage="gateways"
              id="YJpDE8"
            />
          }}
        />
      </Text>
    );
  }

  return (
    <>
      <DeleteDialog
        open={showDeleteDialog}
        title={(
          intl.formatMessage({
            defaultMessage: 'Delete gateway',
            id: 'u//xkV',
          })
        )}
        onCancel={handleDeleteCancel}
        onDelete={handleDeleteConfirm}
      >
        <DialogContentDeleteGateway gateway={deleteGateway} />
      </DeleteDialog>
      <Table
        caption="Gateways table"
        feCompact={true}
        feBody={renderGatewaysTableBody()}
        feHead={gatewaysTableHead}
        feType="static"
      />
    </>
  );
};

TableGateway.propTypes = {
  gateways: PropTypes.arrayOf(GatewayShape),
  onDeleteConfirmClick: PropTypes.func,
};

export default TableGateway;
