import { redirectTo } from 'redux/modules/redirect';
import {
  displayErrorMessage,
  displaySuccessMessage,
} from 'redux/modules/notification';
import messages from 'helpers/messages';
import { ROUTE_DASHBOARD_ASSETS } from 'helpers/constants';
import { loadDevices } from './device';

const LOAD = 'myrina/assets/LOAD';
const LOAD_SUCCESS = 'myrina/assets/LOAD_SUCCESS';
const LOAD_FAIL = 'myrina/assets/LOAD_FAIL';

const LOAD_ASSET_DEVICES_SUCCESS = 'myrina/assets/{assetTag}/LOAD_SUCCESS';
const LOAD_ASSET_DEVICES_FAIL = 'myrina/assets/{assetTag}/LOAD_FAIL';

const CREATE = 'myrina/assets/CREATE';
const CREATE_SUCCESS = 'myrina/assets/CREATE_SUCCESS';
const CREATE_FAIL = 'myrina/assets/CREATE_FAIL';

const UPDATE = 'myrina/assets/{assetTag}/UPDATE';
const UPDATE_SUCCESS = 'myrina/assets/{assetTag}/UPDATE_SUCCESS';
const UPDATE_FAIL = 'myrina/assets/{assetTag}/UPDATE_FAIL';

const DELETE = 'myrina/assets/{assetTag}/DELETE';
const DELETE_SUCCESS = 'myrina/assets/{assetTag}/DELETE_SUCCESS';
const DELETE_FAIL = 'myrina/assets/{assetTag}/DELETE_FAIL';

const UPLOAD = 'myrina/assets/UPLOAD';
const UPLOAD_SUCCESS = 'myrina/assets/UPLOAD_SUCCESS';
const UPLOAD_FAIL = 'myrina/assets/UPLOAD_FAIL';

const initialState = {
  loaded: false,
  loading: true,
  assets: [],
  assetDevices: [],
};

export default function asset(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
    case CREATE:
    case UPDATE:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        assets: action.result,
      };
    case LOAD_ASSET_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        assetDevices: action.result.assetDevices,
      };
    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case LOAD_FAIL:
    case CREATE_FAIL:
    case UPDATE_FAIL:
    case LOAD_ASSET_DEVICES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    default:
      return state;
  }
}

export function loadAssets() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => {
      return client.get('/customer/assets');
    },
  };
}

export function createAsset(data) {
  return {
    types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
    promise: (client, dispatch) => {
      return client
        .post('customer/assets', {
          headers: {
            'content-type': 'application/json',
          },
          data,
        })
        .then(res => {
          dispatch(loadAssets());
          dispatch(redirectTo(ROUTE_DASHBOARD_ASSETS));
          dispatch(displaySuccessMessage(messages.successAssetAdded));
          return res;
        })
        .catch(() => {
          dispatch(displayErrorMessage(messages.errorAssetAdd));
        });
    },
  };
}

export function uploadAssets(csv) {
  return {
    types: [UPLOAD, UPLOAD_SUCCESS, UPLOAD_FAIL],
    promise: (client, dispatch) => {
      return client
        .post('/customer/assets', {
          headers: {
            'content-type': 'text/csv',
          },
          data: csv,
        })
        .then(res => {
          dispatch(loadAssets());
          dispatch(displaySuccessMessage(messages.successAssetsUploaded));
          return res;
        })
        .catch(() => {
          dispatch(displayErrorMessage(messages.errorAssetsUpload));
        });
    },
  };
}

export function updateAsset({ assetTag, ...data }) {
  return {
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
    promise: (client, dispatch) => {
      return client
        .put(`customer/assets/${encodeURIComponent(assetTag)}`, {
          headers: {
            'content-type': 'application/json',
          },
          data,
        })
        .then(res => {
          dispatch(loadAssets());
          dispatch(loadDevices());
          dispatch(redirectTo(ROUTE_DASHBOARD_ASSETS));
          dispatch(displaySuccessMessage(messages.successAssetUpdated));
          return res;
        })
        .catch(() => {
          dispatch(displayErrorMessage(messages.errorAssetUpdate));
        });
    },
  };
}

export function deleteAsset(assetTag) {
  return {
    types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
    promise: (client, dispatch) => {
      return client
        .del(`customer/assets/${encodeURIComponent(assetTag)}`)
        .then(res => {
          dispatch(loadAssets());
          dispatch(redirectTo(ROUTE_DASHBOARD_ASSETS));
          dispatch(displaySuccessMessage(messages.successAssetDeleted));
          return res;
        })
        .catch(() => {
          dispatch(displayErrorMessage(messages.errorAssetDelete));
        });
    },
  };
}
