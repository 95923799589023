import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Flexbox,
  Grid,
  GridItem,
} from '@skf-internal/ui-components-react-legacy';
import { string } from 'yup';

import PromptForm from 'components/PromptForm';
import ControlError from 'components/ControlError';
import ControlButton from 'components/ControlButton';
import ControlTextField from 'components/ControlTextField';
import ControlValidationError from 'components/ControlValidationError';
import { FormType, GatewayShape } from 'helpers/propTypes';

// No OIQSZ or lower case characters in the form "AA11AA11"
const GATEWAY_ID_REGEX = /^([^OIQSZa-z]{2}[0-9]{2}){2}$/;
const MIN_LENGTH_GATEWAY_NAME = 3;

const FormGateway = ({
  onSubmit, gateways, formType, initialState, submitBtnText
}) => {
  const intl = useIntl();
  const validationSchema = {
    name: {
      isRequired: value => string().required().isValidSync(value),
      isNotLongEnough: value => string().min(MIN_LENGTH_GATEWAY_NAME).isValidSync(value),
      ...(formType === 'add' && {
        isAlreadyAdded: value => !gateways.some(gateway => gateway.name === value)
      })
    },
    id: {
      isRequired: value => string().required().isValidSync(value),
      isNotValidExample: value => string().matches(GATEWAY_ID_REGEX).isValidSync(value),
      ...(formType === 'add' && {
        isAlreadyAdded: value => !gateways.some(gateway => gateway.gatewayId === value)
      })
    },
    '': {
      // Form-level validator
      hasChanges: values => {
        return Object.keys(values).some(key => values[key] !== initialState[key]);
      },
    }
  };

  return (
    <PromptForm
      model="gateway"
      onSubmit={onSubmit}
      initialState={initialState}
      validators={validationSchema}
    >
      {isSubmitting => (
        <>
          <Grid>
            <GridItem feColspan={{ desktop: 6, tablet: 6, mobile: 12 }}>
              <ControlTextField
                feLabel={intl.formatMessage({
                  defaultMessage: 'Name',
                  id: 'HAlOn1'
                })}
                id="name"
                model=".name"
                placeholder="LS-01 GW"
                hint={intl.formatMessage({
                  defaultMessage: 'Enter a name to identify the Gateway', id: 'k4Tyd0'
                })}
                required
              />
              <ControlError
                model=".name"
                messages={{
                  isRequired: <ControlValidationError validator="isRequired" />,
                  isNotLongEnough: () => (
                    <ControlValidationError
                      validator="isNotLongEnough"
                      values={{ field: 'Name', length: MIN_LENGTH_GATEWAY_NAME }}
                    />
                  ),
                  ...(formType === 'add' && {
                    isAlreadyAdded: value => (
                      <ControlValidationError
                        validator="isAlreadyAdded"
                        values={{ value }}
                      />
                    ),
                  })
                }}
              />
            </GridItem>
            <GridItem feColspan={{ desktop: 6, tablet: 6, mobile: 12 }}>
              <ControlTextField
                feLabel={intl.formatMessage({
                  defaultMessage: 'Gateway ID',
                  id: 'v4p4ra'
                })}
                id="id"
                model=".id"
                placeholder="TG63HH19"
                hint={intl.formatMessage({
                  defaultMessage: 'Enter an ID marked on the Gateway device', id: '2rNY9T'
                })}
                required
              />
              <ControlError
                model=".id"
                messages={{
                  isRequired: <ControlValidationError validator="isRequired" />,
                  isNotValidExample: value => (
                    <ControlValidationError
                      validator="isNotValidExample"
                      values={{ value, thing: <FormattedMessage defaultMessage="ID" id="qlcuNQ" />, example: 'TG63HH19' }}
                    />
                  ),
                  ...(formType === 'add' && {
                    isAlreadyAdded: value => (
                      <ControlValidationError
                        validator="isAlreadyAdded"
                        values={{ value }}
                      />
                    ),
                  })
                }}
              />
            </GridItem>
            <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
              <Flexbox feJustifyContent="flex-end" feAlignItems="center">
                <ControlButton model="gateway" id="submit" type="submit" disabled={isSubmitting || { valid: false }}>
                  {submitBtnText}
                </ControlButton>
              </Flexbox>
            </GridItem>
          </Grid>
        </>
      )}
    </PromptForm>
  );
};

FormGateway.defaultProps = {
  initialState: {
    gatewayName: undefined,
    gatewayId: undefined,
  },
  submitBtnText: (
    <FormattedMessage
      defaultMessage="Add {thing}"
      id="SziwLc"
      values={{ thing: <FormattedMessage defaultMessage="gateway" id="MbLp8E" /> }}
    />
  ),
  formType: 'add',
};

FormGateway.propTypes = {
  formType: FormType,
  onSubmit: PropTypes.func.isRequired,
  initialState: PropTypes.shape({
    gatewayName: PropTypes.string,
    gatewayId: PropTypes.string,
  }),
  gateways: PropTypes.arrayOf(GatewayShape),
  submitBtnText: PropTypes.element,
};

export default FormGateway;
