import {
  Card,
  Flexbox,
  FontSizes,
  Icon,
  IconSizes,
  Spacer,
  Spacings,
  Text
} from '@skf-internal/ui-components-react-legacy';
import { HEIGHT_MEDIUM } from 'components/TemplateInsightItem/TemplateInsightItem';
import { CARD_STYLE } from 'helpers/constants';
import iconTotalLubricators from 'images/icons/3-lubricators.png';
import iconGateway from 'images/icons/extender-router-icon.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const TemplateStatusNumber = ({
  numberDevices, text, feIcon, feColor, numberGateways
}) => {
  if (typeof numberDevices === 'number' && typeof numberGateways === 'number') {
    return (
      <Card style={CARD_STYLE}>
        <Flexbox feFlexDirection="column" feAlignItems="center">
          <Flexbox feFlexDirection="row" feAlignItems="center" feGap={Spacings.Sm}>
            <Flexbox feFlexDirection="column" feAlignItems="center">
              <img
                src={iconTotalLubricators}
                height={HEIGHT_MEDIUM}
                alt={(
                  <FormattedMessage
                    defaultMessage="Devices"
                    id="+20FW7"
                  />
                )}
              />
              <Spacer feSpacing={Spacings.Xxs} />
              <Text feFontSize={FontSizes.Xxxl}>
                {numberDevices}
              </Text>
            </Flexbox>
            <Flexbox feFlexDirection="column" feAlignItems="center">
              <img
                src={iconGateway}
                height={HEIGHT_MEDIUM}
                alt={(
                  <FormattedMessage
                    defaultMessage="Gateways"
                    id="jtdQHR"
                  />
                  )}
              />
              <Spacer feSpacing={Spacings.Xxs} />
              <Text feFontSize={FontSizes.Xxxl}>
                {numberGateways}
              </Text>
            </Flexbox>
          </Flexbox>
          <Spacer feSpacing={Spacings.Md} />
          <Icon feIcon={feIcon} feSize={IconSizes.Lg} feColor={feColor} />
          <Spacer feSpacing={Spacings.Md} />
          <Text feFontSize={FontSizes.Lg}>
            {text}
          </Text>
        </Flexbox>
      </Card>
    );
  }
  const value = numberDevices || numberGateways || 0;
  return (
    <Card style={CARD_STYLE}>
      <Flexbox feFlexDirection="column" feAlignItems="center" style={CARD_STYLE}>
        <Flexbox feFlexDirection="row" feAlignItems="center" feGap={typeof value === 'number' ? Spacings.Sm : null}>
          <Icon feIcon={feIcon} feSize={IconSizes.Lg} feColor={feColor} />
          <Text feFontSize={FontSizes.Xxxl}>
            {value}
          </Text>
        </Flexbox>
        <Spacer feSpacing={Spacings.Md} />
        <Text feFontSize={FontSizes.Lg}>
          {text}
        </Text>
      </Flexbox>
    </Card>
  );
};

TemplateStatusNumber.propTypes = {
  numberDevices: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  numberGateways: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  text: PropTypes.string,
  feIcon: PropTypes.string,
  feColor: PropTypes.string,
};

export default TemplateStatusNumber;
