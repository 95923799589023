import messages from 'helpers/messages';
import { displayErrorMessage, displaySuccessMessage } from './notification';

const LOAD = 'myrina/organization/LOAD';
const LOAD_SUCCESS = 'myrina/organization/LOAD_SUCCESS';
const LOAD_FAIL = 'myrina/organization/LOAD_FAIL';

const UPDATE = 'myrina/organization/UPDATE';
const UPDATE_SUCCESS = 'myrina/organization/UPDATE_SUCCESS';
const UPDATE_FAIL = 'myrina/organization/UPDATE_FAIL';

const LOAD_USERS = 'myrina/organization/LOAD_USERS';
const LOAD_USERS_SUCCESS = 'myrina/organization/LOAD_USERS_SUCCESS';
const LOAD_USERS_FAIL = 'myrina/organization/LOAD_USERS_FAIL';

const DELETE_USER = 'myrina/organization/DELETE_USER';
const DELETE_USER_SUCCESS = 'myrina/organization/DELETE_USER_SUCCESS';
const DELETE_USER_FAIL = 'myrina/organization/DELETE_USER_FAIL';

const initialState = {
  loaded: false,
  loading: true,
  users: {
    data: [],
    loading: true,
  },
  system: undefined,
  company: undefined,
  inOrbit: undefined,
  showLCPUpdateBanner: undefined,
};

export default function organization(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        system: action.result.system,
        company: action.result.company,
        inOrbit: action.result.inOrbit,
        showLCPUpdateBanner: action.result.showLCPUpdateBanner,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case LOAD_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
      };
    case LOAD_USERS_SUCCESS:
      return {
        ...state,
        users: {
          data: action.result,
          loaded: true,
          loading: false,
        },
      };
    case LOAD_USERS_FAIL:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          loaded: true,
        },
        error: action.error,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result,
      };
    case UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    default:
      return state;
  }
}

export function loadOrganization() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => {
      return client.get('/customer/organization');
    },
  };
}

export function updateOrganization(data) {
  return {
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
    promise: (client, dispatch) => {
      return client
        .put('/customer/organization', {
          data
        })
        .then(() => {
          dispatch(loadOrganization());
          dispatch(displaySuccessMessage(messages.successOrganizationUpdated));
        })
        .catch(e => {
          dispatch(displayErrorMessage(messages.errorOrganizationUpdate));
          return e;
        });
    },
  };
}

export function loadOrganizationUsers() {
  return {
    types: [LOAD_USERS, LOAD_USERS_SUCCESS, LOAD_USERS_FAIL],
    promise: client => {
      return client.get('/customer/organization/users');
    },
  };
}

export function removeOrganizationUser(userId) {
  return {
    types: [DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_FAIL],
    promise: (client, dispatch) => {
      return client
        .del(`/customer/organization/users/${userId}`)
        .then(res => {
          dispatch(loadOrganizationUsers());
          dispatch(displaySuccessMessage(messages.successOrganizationUserRemove));
          return res;
        })
        .catch(e => {
          dispatch(displayErrorMessage(messages.errorOrganizationUserRemove));
          return e;
        });
    },
  };
}
