import {
  Flexbox,
  Grid,
  GridItem,
  IconColors, Spacings
} from '@skf-internal/ui-components-react-legacy';
import SLoader from 'components/SLoader';
import TabsDashboard from 'components/TabsDashboard';
import TemplateStatusNumber from 'components/TemplateStatusNumber';
import DashboardViewInsights from 'containers/DashboardViewInsights';
import { ALERT_LEVELS, GATEWAY_STATUSES } from 'helpers/constants';
import { DeviceShape } from 'helpers/propTypes';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const TemplateDashboard = ({
  width, devices, statusCount, gatewaysStatusCount
}) => {
  const intl = useIntl();

  return (
    <Grid style={{ gap: Spacings.Sm }}>
      <GridItem feColspan={{ desktop: 2, tablet: 12, mobile: 12 }}>
        <Grid style={{ gap: Spacings.Sm }}>
          {devices.some(device => [undefined, null].includes(device.alertLevel))
            ? <SLoader />
            : (
              <>
                <GridItem feColspan={{ desktop: 12, tablet: 3, mobile: 6 }}>
                  <TemplateStatusNumber feIcon="WarningDiamond" feColor={IconColors.Red} numberDevices={statusCount[ALERT_LEVELS.ALERT]} numberGateways={gatewaysStatusCount[GATEWAY_STATUSES.OFFLINE]} text={intl.formatMessage({ defaultMessage: 'Alarms', id: '4VPIXt' })} />
                </GridItem>
                <GridItem feColspan={{ desktop: 12, tablet: 3, mobile: 6 }}>
                  <TemplateStatusNumber feIcon="Warning" feColor={IconColors.Yellow} numberDevices={statusCount[ALERT_LEVELS.WARNING]} text={intl.formatMessage({ defaultMessage: 'Warnings', id: 'VSWkne' })} />
                </GridItem>
                <GridItem feColspan={{ desktop: 12, tablet: 3, mobile: 6 }}>
                  <TemplateStatusNumber feIcon="CheckCircle" feColor={IconColors.Green} numberDevices={statusCount[ALERT_LEVELS.DEVICE_OK]} numberGateways={gatewaysStatusCount[GATEWAY_STATUSES.ONLINE]} text={intl.formatMessage({ defaultMessage: 'Normal', id: 'myq2ZL' })} />
                </GridItem>
                <GridItem feColspan={{ desktop: 12, tablet: 3, mobile: 6 }}>
                  <TemplateStatusNumber feIcon="PowerOff" numberDevices={statusCount[ALERT_LEVELS.DEVICE_OFF]} text={intl.formatMessage({ defaultMessage: 'Off', id: 'OvzONl' })} />
                </GridItem>
                <GridItem feColspan={{ desktop: 12, tablet: 3, mobile: 6 }}>
                  <TemplateStatusNumber feIcon="Stop" numberDevices={statusCount[ALERT_LEVELS.DEVICE_NO_CONNECTION]} text={intl.formatMessage({ defaultMessage: 'No connection', id: 'NP4I0A' })} />
                </GridItem>
              </>
            )}
          {width >= 784 && <DashboardViewInsights />}
        </Grid>
      </GridItem>
      <GridItem feColspan={{ desktop: 10, tablet: 12, mobile: 12 }}>
        <Flexbox feFlexDirection="column">
          <TabsDashboard />
        </Flexbox>
      </GridItem>
      {width < 784
        && (
        <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
          <Grid style={{ gap: Spacings.Sm }}>
            <DashboardViewInsights />
          </Grid>
        </GridItem>
        )}
    </Grid>
  );
};

TemplateDashboard.propTypes = {
  width: PropTypes.number,
  devices: PropTypes.arrayOf(DeviceShape),
  statusCount: PropTypes.arrayOf(PropTypes.number),
  gatewaysStatusCount: PropTypes.arrayOf(PropTypes.number),
};

export default TemplateDashboard;
