import { Tabs } from '@skf-internal/ui-components-react-legacy';
import DashboardAssetsView from 'containers/DashboardAssetsView';
import DashboardDevicesView from 'containers/DashboardDevicesView';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  useHistory, useLocation
} from 'react-router-dom';

const tabRouteMap = [
  '/dashboard/assets-and-devices/assets',
  '/dashboard/assets-and-devices/devices',
];

const TabsAssetsAndDevices = () => {
  const history = useHistory();
  const location = useLocation();
  const activeTab = tabRouteMap.findIndex((_, index) => location.pathname.includes(tabRouteMap[index]));
  const intl = useIntl();

  const handleTabClick = (_e, index) => {
    history.push(tabRouteMap[index]);
  };

  return (
    <Tabs
      feSelected={activeTab}
      feItems={[
        {
          children: <DashboardAssetsView />,
          label: intl.formatMessage({
            defaultMessage: 'Assets', id: 'd1uESJ',
          }),
        },
        {
          children: <DashboardDevicesView />,
          label: intl.formatMessage({
            defaultMessage: 'Devices', id: '+20FW7',
          }),
        },
      ]}
      onClick={handleTabClick}
      feType="compressed"
    />
  );
};

export default TabsAssetsAndDevices;
