import React from 'react';
import PropTypes from 'prop-types';
import { Flexbox, Spacings, Text } from '@skf-internal/ui-components-react-legacy';

import { Children } from 'helpers/propTypes';

const TemplateDialogContent = ({ content, children }) => {
  return (
    <Flexbox feGap={Spacings.Md} feFlexDirection="column">
      {content.map(section => (
        <React.Fragment key={`section-${section.subheading}`}>
          <Text>{section.subheading}</Text>
          <ul>
            {section.items.map(item => (
              <li key={item.id}>
                {item.children}
              </li>
            ))}
          </ul>
        </React.Fragment>
      ))}
      {children}
    </Flexbox>
  );
};

TemplateDialogContent.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      subheading: PropTypes.node,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          children: PropTypes.node,
        }),
      ),
    }),
  ),
  children: Children
};

export default TemplateDialogContent;
