
import * as Sentry from '@sentry/react';
import { FerrisProvider } from '@skf-internal/ui-components-react-legacy';
import App from 'App';
import { client } from 'helpers/ApiClient';
import 'index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createStore from 'redux/create';
import WebFont from 'webfontloader';
// Fetch polyfill for older browsers
import 'whatwg-fetch';

// Load Google fonts async
WebFont.load({
  google: {
    families: ['Dosis:300,400,600'],
    timeout: 2000,
  },
});

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://f7681138547d4f399cf32e96e71ca8cb@o1193385.ingest.sentry.io/4504208588603392',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: process.env.REACT_APP_ENV === 'production' ? 0.1 : 1.0,
    environment: process.env.REACT_APP_ENV,
  });
}

let store = createStore(client);

if (window && window.location && window.location.href.includes('admin-super')) {
  store = createStore(client, 'admin-super');
}
const render = () => {
  ReactDOM.render(
    <Sentry.ErrorBoundary showDialog>
      <Provider store={store}>
        <FerrisProvider>
          <App />
        </FerrisProvider>
      </Provider>
    </Sentry.ErrorBoundary>,
    document.getElementById('root')
  );
};

render();

if (module.hot) {
  module.hot.accept('./App', () => {
    render();
  });
}
