import {
  TextField
} from '@skf-internal/ui-components-react-legacy';
import { ValidateOn } from 'helpers/propTypes';
import PropTypes from 'prop-types';
import React from 'react';
import { Control } from 'react-redux-form';

const ControlTextField = ({
  model, id, placeholder, onChange, onBlur, required, defaultValue, feLabel, type, hint, validateOn, disabled, title
}) => {
  return (
    <Control
      model={model}
      id={id}
      required={required}
      onChange={onChange}
      onBlur={onBlur}
      component={TextField}
      validateOn={validateOn}
      defaultValue={defaultValue}
      title={title}
      controlProps={{
        feLabel,
        feHint: hint,
        placeholder,
        type,
        disabled,
      }}
    />
  );
};

ControlTextField.defaultProps = {
  required: false,
  type: 'text',
  disabled: false,
};

ControlTextField.propTypes = {
  model: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  feLabel: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.oneOf(['hidden', 'text', 'tel', 'url', 'email', 'week']),
  hint: PropTypes.string,
  disabled: PropTypes.bool,
  validateOn: ValidateOn,
  defaultValue: PropTypes.string,
  title: PropTypes.string,
};

export default ControlTextField;
