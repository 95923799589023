import React from 'react';
import PropTypes from 'prop-types';
import S3Uploader from 'react-s3-uploader';

const DialogContentUploadImage = ({
  inputRef,
  deviceId,
  getSignedUrl,
  onUploadProgress,
  onUploadError,
  onUploadFinish
}) => {
  return (
    <S3Uploader
      ref={inputRef}
      autoUpload={false}
      getSignedUrl={(data, callback) => getSignedUrl(data, deviceId, callback)}
      accept="image/*"
      onProgress={onUploadProgress}
      onError={onUploadError}
      onFinish={onUploadFinish}
      contentDisposition="auto"
      uploadRequestHeaders={{}}
    />
  );
};

DialogContentUploadImage.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  deviceId: PropTypes.string,
  getSignedUrl: PropTypes.func,
  onUploadProgress: PropTypes.func,
  onUploadError: PropTypes.func,
  onUploadFinish: PropTypes.func,
};

export default DialogContentUploadImage;
