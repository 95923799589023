const LOAD = 'myrina/admin-super/LOAD';
const LOAD_SUCCESS = 'myrina/admin-super/LOAD_SUCCESS';
const LOAD_SUCCESS_ORG = 'myrina/admin-super/LOAD_SUCCESS_ORG';
const LOAD_SUCCESS_ORG_DET = 'myrina/admin-super/LOAD_SUCCESS_ORG_DET';
const LOAD_SUCCESS_ORG_USERS = 'myrina/admin-super/LOAD_SUCCESS_ORG_USERS';
const LOAD_SUCCESS_ORG_DEVICES = 'myrina/admin-super/LOAD_SUCCESS_ORG_DEVICES';
const LOAD_SUCCESS_ORG_GATEWAYS = 'myrina/admin-super/LOAD_SUCCESS_ORG_GATEWAYS';
const LOAD_FAIL = 'myrina/admin-super/LOAD_FAIL';
const LOAD_SUCCESS_PLATFORM = 'myrina/admin-super/LOAD_SUCCESS_PLATFORM';
const initialState = {
  loaded: false,
  data: {}
};

export default function admin(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_SUCCESS_ORG:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          organization: action.result
        }
      };
    case LOAD_SUCCESS_ORG_DET:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          org: action.result
        }
      };
    case LOAD_SUCCESS_PLATFORM:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          platform: action.result
        }
      };
    case LOAD_SUCCESS_ORG_USERS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          users: action.result
        }
      };
    case LOAD_SUCCESS_ORG_DEVICES:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          devices: action.result.devices
        }
      };
    case LOAD_SUCCESS_ORG_GATEWAYS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          gateways: action.result
        }
      };

    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };

    default:
      return state;
  }
}

export function loadLatestXLSX() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => {
      return client.get('/admin/all-devices');
    }
  };
}

export function loadExportHistory() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => {
      return client.get('/admin/all-devices/exports');
    }
  };
}
export function loadOrganizations() {
  return {
    types: [LOAD, LOAD_SUCCESS_ORG, LOAD_FAIL],
    promise: client => {
      return client.get('/organizations');
    }
  };
}

export function searchOrganization(data) {
  return {
    types: [LOAD, LOAD_SUCCESS_ORG, LOAD_FAIL],
    promise: client => {
      return client.post('organizations', { data: { ...data }, headers: { 'Content-Type': 'application/json' } });
    }
  };
}

export function loadOrganization(organizationId) {
  return {
    types: [LOAD, LOAD_SUCCESS_ORG_DET, LOAD_FAIL],
    promise: client => {
      return client.get(`organizations/${organizationId}`);
    }
  };
}

export function loadOrganizationsUsers(organizationId) {
  return {
    types: [LOAD, LOAD_SUCCESS_ORG_USERS, LOAD_FAIL],
    promise: client => {
      return client.get(`organizations/${organizationId}/users`);
    }
  };
}

export function loadPlatformSummary() {
  return {
    types: [LOAD, LOAD_SUCCESS_PLATFORM, LOAD_FAIL],
    promise: client => {
      return client.get('platform');
    }
  };
}

export function loadOrganizationsDevices(organizationId) {
  return {
    types: [LOAD, LOAD_SUCCESS_ORG_DEVICES, LOAD_FAIL],
    promise: client => {
      return client.get(`organizations/${organizationId}/devices`);
    }
  };
}

export function loadOrganizationsGateways(organizationId) {
  return {
    types: [LOAD, LOAD_SUCCESS_ORG_GATEWAYS, LOAD_FAIL],
    promise: client => {
      return client.get(`organizations/${organizationId}/gateways`);
    }
  };
}

export function loadGraphData(metric, ageInDays) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => {
      return client.get(`admin/all-devices/graphdata?metric=${metric}&ageInDays=${ageInDays}`);
    }
  };
}

export function loadXLSX(key) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => {
      return client.get(`admin/all-devices/${key}`);
    }
  };
}
