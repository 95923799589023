import { getCurrentLanguageCode } from 'storage/localStorage';
import {
  getAccessToken,
  getIdToken,
  getRefreshToken,
  setTokens
} from '../redux/modules/auth';

const methods = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  patch: 'PATCH',
  del: 'DELETE'
};

const LANGUAGE_HEADER = 'X-LANGUAGE';

function formatUrl(path, local = false) {
  let adjustedPath = path[0] !== '/' ? `/${path}` : path;
  if (!local) {
    if (path.indexOf('/metadata') !== -1) {
      adjustedPath = `${process.env.REACT_APP_META_API}${adjustedPath}`;
    }
    else if (window && window.location && window.location.href.includes('admin-super')) {
      adjustedPath = `${process.env.REACT_APP_ADMIN_SUPER_ENDPOINT}${adjustedPath}`;
    }
    else {
      adjustedPath = `${process.env.REACT_APP_ENDPOINT}${adjustedPath}`;
    }
  }

  return adjustedPath;
}

export default class ApiClient {
  constructor() {
    Object.keys(methods).forEach(method => {
      this[method] = async (path, clientParams = {}) => {
        const {
          params,
          data,
          local,
          headers = {},
          isAccessToken = true,
          refresh = true,
          login = false,
        } = clientParams;
        let token;

        if (isAccessToken) {
          token = getAccessToken(this.store.getState());
        }
        else {
          token = getIdToken(this.store.getState());
        }

        let url = formatUrl(path, local);
        const opts = {
          credentials: 'same-origin',
          method: methods[method],
          headers: {
            ...headers,
            ...(token ? { Authorization: `${token}` } : {})
          }
        };

        if (params) {
          const query = Object.keys(params)
            .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
            .join('&');

          url = `${url}?${query}`;
        }

        // No Content-Type headers are generally being set in where requests are triggered,
        // but some functionalities like Upload asset CSV expect formdata.
        // Because of that we can't just check if it is "application/json" and then stringify.
        if (data) {
          if (headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            opts.body = data;
          }
          else {
            opts.body = JSON.stringify(data);
          }
        }

        // Usually we should not need to wrap fetch in try/catch
        // but this response is exception. Fetch will raise on
        // CORS error which comes when request fails so it
        // won't return the proper 401/403 error in status
        //
        // For other request it should work normally and that is
        // why we still do the status check later on
        let res;
        const lang = getCurrentLanguageCode();
        opts.headers[LANGUAGE_HEADER] = lang;
        try {
          res = await fetch(url, opts);
        }
        catch (err) {
          if (login || !refresh) {
            throw err;
          }

          await this.refreshSession();

          // Request again, but prevent refreshing
          return this[method](path, { ...clientParams, refresh: false });
        }

        if (res.status < 200 || res.status >= 300) {
          const err = new Error(res.statusText);
          err.response = res;

          try {
            // Check if error has body with error message and save that to error
            err.body = await res.json();
            err.errorMessage = err.body.errorMessage;
          }
          catch (e) {
            // We don't really care about this error...
          }

          throw err;
        }

        return res.json();
      };
    });
  }

  async refreshSession() {
    const refreshToken = getRefreshToken(this.store.getState());
    if (!refreshToken) {
      return;
    }
    const res = await fetch(`${process.env.REACT_APP_SSO_SERVICE_URL}/refresh`, {
      method: 'POST',
      body: JSON.stringify({ refreshToken }),
    });

    if (res.status < 200 || res.status >= 300) {
      const error = new Error(res.statusText);
      error.response = res;
      throw error;
    }

    const body = await res.json();
    const tokens = body.AuthenticationResult || body;

    this.store.dispatch(setTokens(tokens));
  }

  /**
   * Used by client middleware to set store for client
   */
  setStore(store) {
    this.store = store;
  }
}

export const client = new ApiClient();
