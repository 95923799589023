/**
 * @fileoverview Defines notification system messages
 *
 * *IMPORTANT: DO NOT DEFINE ANY OTHER MESSAGES IN THIS FILE*
 */

import { defineMessages } from 'react-intl';

/**
 * Notification messages
 *
 * Message keys should be of shape: <error|info|success><entity><action|description>
 * *IMPORTANT: DO NOT USE MANUAL IDS, `babel-plugin-formatjs` WILL AUTOGENERATE THE IDS.*
 */
const messages = defineMessages({
  // General messages
  errorInternal: {
    defaultMessage: 'Internal error', id: '86rtcp'
  },
  errorUnknown: {
    defaultMessage: 'Unknown error', id: 'qDwvZ4',
  },
  infoMissingTranslation: {
    defaultMessage: 'Missing translation', id: 'pca/67',
  },

  // File messages
  errorCsvWrongFormat: {
    defaultMessage: 'Wrong file format, please upload a proper .csv file', id: 'u/z4fd'
  },

  // Account messages
  errorAccountUnauthenticated: {
    defaultMessage: 'Unauthenticated', id: 'DRyQH8'
  },
  errorAccountNotAuthorized: {
    defaultMessage: 'User not authorized', id: 'vwpZlc'
  },
  errorAccountNotConfirmed: {
    defaultMessage: 'User not confirmed', id: 'WtY9pI'
  },
  errorAccountNotFound: {
    defaultMessage: 'User not found', id: 'INrbJL'
  },
  errorAccountLoginExpired: {
    defaultMessage: 'Login expired', id: 'o2revq'
  },
  successAccountCreated: {
    defaultMessage: 'Account created', id: 'FRBgoF'
  },
  infoAccountCheckEmail: {
    defaultMessage: 'Please check your email before logging in', id: '+v3v9u'
  },
  infoAccountPasswordResetRequired: {
    defaultMessage: 'Password reset is required', id: 'SLOGZq'
  },

  // Organization messages
  errorOrganizationUpdate: {
    defaultMessage: 'Updating organization failed', id: 'uf3/01',
  },
  errorOrganizationUserRemove: {
    defaultMessage: 'Removing a user from the organization failed', id: 'SSgB1r'
  },
  errorOrganizationInvite: {
    defaultMessage: 'Sending an invite to the given address failed', id: '4BzSsa'
  },
  successOrganizationUpdated: {
    defaultMessage: 'Organization updated', id: 'Sh5BWd',
  },
  successOrganizationUserRemove: {
    defaultMessage: 'User removed', id: 'R95WbP'
  },
  infoOrganizationInviteSent: {
    defaultMessage: 'Invite sent to given address', id: 'v5+L2M'
  },

  // Asset messages
  errorAssetAdd: {
    defaultMessage: 'Adding an asset failed', id: '055ADi'
  },
  errorAssetsUpload: {
    defaultMessage: 'Uploading assets failed', id: 'ryEmgW'
  },
  errorAssetUpdate: {
    defaultMessage: 'Updating an asset failed', id: 'ffmG3d'
  },
  errorAssetDelete: {
    defaultMessage: 'Deleting an asset failed', id: 'PUgUcQ'
  },
  successAssetAdded: {
    defaultMessage: 'Asset added', id: 'nNYeAO'
  },
  successAssetsUploaded: {
    defaultMessage: 'Assets uploaded', id: 'X+qxxQ'
  },
  successAssetUpdated: {
    defaultMessage: 'Asset updated', id: 'S7kM2a'
  },
  successAssetDeleted: {
    defaultMessage: 'Asset deleted', id: 'BkK3sB'
  },

  // Device messages
  errorDeviceAdd: {
    defaultMessage: 'Adding a device failed', id: '1ayrmq'
  },
  errorDevicesAdd: {
    defaultMessage: 'Adding devices failed', id: 'BRaCAN',
  },
  errorDeviceUpdate: {
    defaultMessage: 'Updating a device failed', id: 'v07xzd'
  },
  errorDeviceDelete: {
    defaultMessage: 'Deleting a device failed', id: 'JKIbFJ'
  },
  errorDeviceKeysAdd: {
    defaultMessage: 'Adding a device keys failed', id: 'EmzDwx'
  },
  successDeviceAdded: {
    defaultMessage: 'Device added', id: '9GxuH5'
  },
  successDevicesAdded: {
    defaultMessage: 'Devices added', id: 'oHtfue',
  },
  successDeviceUpdated: {
    defaultMessage: 'Device updated', id: 'K0f7GG'
  },
  successDeviceDeleted: {
    defaultMessage: 'Device deleted', id: 'mFfhmZ'
  },
  successDeviceKeysAdded: {
    defaultMessage: 'Device keys added', id: 'e5yom+'
  },
  errorAssetNotExists: {
    defaultMessage: 'Asset not exists', id: 'UqkFhU'
  },
  errorDeviceKeyWrongOrg: {
    defaultMessage: 'Device key is already registered to another organization', id: 'qw4TGK'
  },
  errorCustomerDeviceExists: {
    defaultMessage: 'Device already exists', id: '/qpo8T'
  },
  errorDeviceKeyNotExists: {
    defaultMessage: 'Device key not exists', id: 'mzIEgZ'
  },

  // Lubricant messages
  errorLubricantAdd: {
    defaultMessage: 'Adding a lubricant failed', id: 'td2wN1'
  },
  errorLubricantDelete: {
    defaultMessage: 'Deleting a lubricant failed', id: 'ERaceb'
  },
  successLubricantAdded: {
    defaultMessage: 'Lubricant added', id: 'PNwhML'
  },
  successLubricantDeleted: {
    defaultMessage: 'Lubricant deleted', id: 'u1OQlg'
  },

  // Lubricant manufacturer messages
  errorLubricantManufacturerAdd: {
    defaultMessage: 'Adding a lubricant manufacturer failed', id: 'w5l2Ik'
  },
  errorLubricantManufacturerUpdate: {
    defaultMessage: 'Updating a lubricant manufacturer failed', id: 'zXHeon'
  },
  errorLubricantManufacturerDelete: {
    defaultMessage: 'Deleting a lubricant manufacturer failed', id: 'UEzg+W'
  },
  successLubricantManufacturerAdded: {
    defaultMessage: 'Lubricant manufacturer added', id: 'rhFZe3'
  },
  successLubricantManufacturerUpdated: {
    defaultMessage: 'Lubricant manufacturer updated', id: '2Ongp7'
  },
  successLubricantManufacturerDeleted: {
    defaultMessage: 'Lubricant manufacturer deleted', id: '4DW2/d'
  },

  // Gateway messages
  errorGatewayAdd: {
    defaultMessage: 'Adding a gateway failed', id: 'UatXvm'
  },
  errorGatewayUpdate: {
    defaultMessage: 'Updating a gateway failed', id: 'HW3P+J'
  },
  errorGatewayDelete: {
    defaultMessage: 'Deleting gateway failed', id: 'vgfo7/'
  },
  successGatewayAdded: {
    defaultMessage: 'Gateway added', id: 'Uz+7Gr'
  },
  successGatewayUpdated: {
    defaultMessage: 'Gateway updated', id: 'dg0Md0'
  },
  successGatewayDeleted: {
    defaultMessage: 'Gateway deleted', id: 'O7m4OR'
  },
});

export default messages;
