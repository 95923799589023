import {
  any, array, bool, element, func, node, number, object, oneOf,
  oneOfType, shape, string
} from 'prop-types';

export const Children = oneOfType([string, element, node]);

export const AuthShape = shape({
  authenticated: bool,
  deviceAdmin: bool,
  email: string,
  emailFrequency: string,
  error: bool,
  feedback: object,
  groups: array,
  lastEmailAt: number,
  loaded: bool,
  loading: bool,
  organizationId: string,
  userId: string,
  userSession: object,
  inOrbit: bool,
  showLCPUpdateBanner: bool,
  isUserAfterCutoff: bool
});

export const DeviceShape = shape({
  deviceConfig: shape({
    emptyingTime: oneOfType([number, object]),
    lubricant: oneOfType([string, object]),
    cartridgeSize: oneOfType([number, object])
  }),
  location: string,
  deviceId: string,
  appKey: string,
  assetName: string,
  organizationId: string,
  updatedAt: number,
  application: string,
  assetTag: string,
  productId: string,
  deviceData: shape({
    alarmNumber: number,
    greaseUsed: number,
    lubricantLeft: number,
    cartridgeSize: number,
    emptyingTime: number,
    signalStrength: number,
    rssi: number,
    lsnr: number
  }),
  status: string,
  empty: oneOfType([number, string]),
  alarmLevel: number,
  dataReceivedAt: number,
  lastGatewayId: string
});

export const DeviceHistoryShape = shape({
  alarm: number,
  date: oneOfType([string, number]),
  cartridgeSize: oneOfType([string, number]),
  emptyingTime: oneOfType([string, number]),
  lubricant: string,
});

export const AssetPropTypes = {
  assetTag: string,
  assetName: string,
  application: string,
  location: string,
};

export const AssetShape = shape(AssetPropTypes);

export const AssetWithDataShape = shape({
  ...AssetPropTypes,
  alertLevel: oneOfType([number, string]),
});

export const GatewayShape = shape({
  creditCreatedAt: number,
  creditExpiresAt: number,
  creditExpiryEmailSentAt: number,
  creditUpdatedAt: number,
  gatewayId: string,
  name: string,
  organizationId: string,
  pingReceivedAt: number,
  updatedAt: number,
});

export const LubricantManufacturerShape = shape({
  name: string,
  createdByOrg: string,
});

export const OrganizationShape = shape({
  system: string,
});

export const OrganizationUserShape = shape({
  email: string.isRequired,
  email_verified: string,
  phone_verified: string,
  family_name: string,
  given_name: string,
  sub: string,
  username: string,
});

export const LubricantShape = shape({
  denomination: string,
  manufacturerName: string,
  lubricantType: string,
});

export const ControlSelectFieldItemShape = shape({
  value: oneOfType([string, number]).isRequired,
  label: oneOfType([string, number]).isRequired,
});

export const ActiveViewShape = shape({
  index: number,
  id: string,
});

export const ValidateOn = oneOf(['blur', 'change']);

export const IntlConfigPropTypes = {
  locale: string,
  timeZone: string,
  formats: object,
  messages: object,
  textComponent: any,
  defaultLocale: string,
  defaultFormats: object,
  onError: func,
};

export const IntlFormatPropTypes = {
  formatDate: func.isRequired,
  formatTime: func.isRequired,
  formatRelative: func,
  formatNumber: func.isRequired,
  formatPlural: func.isRequired,
  formatMessage: func.isRequired,
  formatHTMLMessage: func,
};

export const IntlShape = shape({
  ...IntlConfigPropTypes,
  ...IntlFormatPropTypes,
  formatters: object,
  now: func,
});

export const FeedbackShape = shape({
  basicStats: string,
});

export const FormType = oneOf(['add', 'edit']);
