import {
  Icons,
  Link, Table,
  Text
} from '@skf-internal/ui-components-react-legacy';
import DeleteDialog from 'components/DeleteDialog';
import DialogContentDeleteOrganizationUser from 'components/DialogContentDeleteOrganizationUser';
import SLoader from 'components/SLoader';
import { AuthShape, OrganizationUserShape } from 'helpers/propTypes';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const TableOrganizationUsers = ({
  users, auth, loading, onDeleteConfirmClick
}) => {
  const intl = useIntl();
  const [removeUser, setRemoveUser] = useState(undefined);

  const handleUserRemoveDialogCancel = () => {
    setRemoveUser(undefined);
  };

  const handleTableBodyRowDeleteButtonClick = user => {
    setRemoveUser(user);
  };

  const renderTableOrganizationUsersBody = () => {
    return users.map(user => {
      const disabled = auth.email === user.email;
      return (
        {
          cells: [
            {
              children: user.email,
            },
            {
              children: (
                <Link
                  as="button"
                  type="button"
                  href="#delete-user"
                  title={disabled ? (
                    intl.formatMessage({
                      defaultMessage: 'Cannot remove yourself from an organization.',
                      id: 'peyor8'
                    })
                  ) : ''}
                  disabled={disabled}
                  feIcon={{
                    feIcon: Icons.Trash,
                    position: 'left'
                  }}
                  onClick={() => {
                    handleTableBodyRowDeleteButtonClick(user);
                  }}
                >
                  <FormattedMessage defaultMessage="Delete" id="K3r6DQ" />
                </Link>
              )
            }
          ],
        }
      );
    });
  };

  const organizationUsersTableHead = [
    {
      cells: [
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Email" id="sy+pv5" />,
          scope: 'col',
          sortable: {}
        },
        {
          as: 'th',
          children: '',
          scope: 'col',
        },
      ]
    }
  ];

  if (loading) {
    return <SLoader />;
  }

  if (!users || users.length === 0) {
    return (
      <Text>
        <FormattedMessage
          defaultMessage="No {thing} or failed to load {thing}."
          id="EemuAN"
          values={{
            thing: <FormattedMessage
              defaultMessage="users"
              id="V6wm5v"
            />
          }}
        />
      </Text>
    );
  }

  return (
    <>
      <DeleteDialog
        open={removeUser !== undefined}
        onCancel={handleUserRemoveDialogCancel}
        onDelete={() => {
          onDeleteConfirmClick(removeUser);
        }}
        title={(
          intl.formatMessage({
            defaultMessage: 'Remove user',
            id: 'TP/O/b',
          })
        )}
      >
        <DialogContentDeleteOrganizationUser user={removeUser} />
      </DeleteDialog>
      <Table
        caption="Organization users table"
        feCompact={true}
        feBody={renderTableOrganizationUsersBody()}
        feHead={organizationUsersTableHead}
        feType="static"
      />
    </>
  );
};

TableOrganizationUsers.propTypes = {
  auth: AuthShape,
  loading: PropTypes.bool,
  users: PropTypes.arrayOf(OrganizationUserShape),
  onDeleteConfirmClick: PropTypes.func,
};

export default TableOrganizationUsers;
