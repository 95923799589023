import { LOCAL_STORAGE_KEYS, LANGUAGE_NAMES } from '../helpers/constants';

export const getCurrentLanguageName = () => {
  const languageCode = localStorage.getItem(LOCAL_STORAGE_KEYS.userLocale);
  return LANGUAGE_NAMES[languageCode];
};

export const getCurrentLanguageCode = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.userLocale);
};

export const setCurrentLanguageCode = languageCode => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.userLocale, languageCode);
};
