import { Confirm, Dialog } from '@skf-internal/ui-components-react-legacy';
import { APP_ID } from 'helpers/constants';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

const DeleteDialog = ({
  openDialog,
  onCancel,
  onDelete,
  children,
  ...rest
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <Dialog
      feAppId={APP_ID}
      feDisplayCloseButton={true}
      feOnClose={onCancel}
      open={openDialog}
      {...rest}
    >
      <Confirm
        feButtons={{
          cancel: {
            children: <FormattedMessage defaultMessage="Cancel" id="47FYwb" />,
            onClick: onCancel,
          },
          confirm: {
            children: <FormattedMessage defaultMessage="Delete" id="K3r6DQ" />,
            onClick: values => {
              setIsDeleting(true);
              onDelete(values);
            },
            feDestructive: true,
            disabled: isDeleting
          },
        }}
      >
        {children}
      </Confirm>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  openDialog: PropTypes.bool,
  thing: PropTypes.object,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  children: PropTypes.object,
};

export default DeleteDialog;
