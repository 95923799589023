import {
  DEMO_MODE, EMAIL_FREQUENCIES_INTL, LUBRICANT_TYPES_INTL, LENS_SYNC_STATUS, LENS_ERROR_TYPE
} from './constants';
import { emptySettings } from './utils';

export const intlEmptySetting = (intl, value, options = { setting: undefined, nbMonths: 12 }) => {
  const setting = options.setting || emptySettings(options.nbMonths).find(emptySetting => emptySetting.value === value);
  return (setting.value > 0 && setting.value < DEMO_MODE) ? `${setting.value} ${intl.formatMessage(setting.intl)}` : intl.formatMessage(setting.intl);
};

export const intlEmailFrequency = (intl, value) => {
  const emailFrequency = EMAIL_FREQUENCIES_INTL.find(frequency => frequency.value === value);
  return intl.formatMessage(emailFrequency.intl);
};

export const intlLubricantType = (intl, value) => {
  const lubricantType = LUBRICANT_TYPES_INTL.find(type => type.value === value);
  return intl.formatMessage(lubricantType.intl);
};

export const intlEmptyMessage = (intl, emptyingTime) => {
  let emptyMessage;

  switch (emptyingTime) {
    case 0:
      return intl.formatMessage({
        defaultMessage: 'Off', id: 'OvzONl'
      });
    case '--':
      return intl.formatMessage({
        defaultMessage: 'Off', id: 'OvzONl'
      });
    case DEMO_MODE:
      return intl.formatMessage({
        defaultMessage: 'Demo', id: 'TPv/Zv'
      });
    default:

      if (typeof emptyingTime !== 'number') {
        return '--';
      }

      emptyMessage = emptyingTime;

      if (emptyingTime === 1) {
        emptyMessage += ` ${intl.formatMessage({
          defaultMessage: 'Month', id: 'TBovrx'
        })}`;
      }
      else {
        emptyMessage += ` ${intl.formatMessage({
          defaultMessage: 'Months', id: 'AxDOiG'
        })}`;
      }
      return emptyMessage;
  }
};

export const intlLastPingReceivedAt = (intl, moment, pingReceivedAt) => {
  if (!pingReceivedAt) {
    return intl.formatMessage({
      defaultMessage: 'No gateway ping received', id: 'kh7rOP'
    });
  }

  const pingTime = moment(pingReceivedAt);

  return `${intl.formatMessage({
    defaultMessage: 'Last gateway ping received', id: '3TMo3Q'
  })} ${pingTime.fromNow()} (${pingTime.format('lll')})`;
};

export const intAlarmDescription = (intl, alarm) => {
  switch (alarm) {
    case 0:
      return intl.formatMessage({
        defaultMessage: 'OFF', id: 'vcTCAn'
      });
    case 1:
      return intl.formatMessage({
        defaultMessage: 'OK', id: 'kAEQyV'
      });
    case 2:
      return intl.formatMessage({
        defaultMessage: 'Empty alarm', id: '5ez8bE'
      });
    case 3:
      return intl.formatMessage({
        defaultMessage: 'Low battery', id: 'Jg6QHW'
      });
    case 4:
      return intl.formatMessage({
        defaultMessage: 'Empty battery', id: 'xW2XRX'
      });
    case 5:
      return intl.formatMessage({
        defaultMessage: 'High pressure warning', id: 'HGGpMi'
      });
    case 6:
      return intl.formatMessage({
        defaultMessage: 'High pressure alarm', id: '9gvyRq'
      });
    case 7:
      return intl.formatMessage({
        defaultMessage: 'Internal alarm', id: 'Skr/Ez'
      });
    case 8:
      return intl.formatMessage({
        defaultMessage: 'End of demo', id: 'r9iq5i'
      });
    case 998:
      return intl.formatMessage({
        defaultMessage: 'No connection', id: 'NP4I0A'
      });
    default:
      return intl.formatMessage({
        defaultMessage: 'Waiting for connection', id: 'qf5KUv'
      });
  }
};

export const intlLastDataReceivedAt = (intl, moment, dataReceivedAt) => {
  if (!dataReceivedAt) {
    return intl.formatMessage({
      defaultMessage: 'No device data received', id: 'rr5KT1'
    });
  }

  const dataTime = moment(dataReceivedAt);

  return dataTime.format('lll');
};

export const intlFill = (intl, lubricantLeft, timeToEmpty) => {
  if (!lubricantLeft) {
    return intl.formatMessage({
      defaultMessage: 'N/A', id: 'PW+sL4',
    });
  }
  let intlTimeToEmpty = '';
  if (typeof timeToEmpty === 'number') {
    intlTimeToEmpty = timeToEmpty > 1
      ? `(${timeToEmpty} ${intl.formatMessage({ defaultMessage: 'weeks', id: 'umNSDp' })})`
      : `(${timeToEmpty} ${intl.formatMessage({ defaultMessage: 'week', id: 'zyFak8' })})`;
  }

  return `${lubricantLeft} % ${intlTimeToEmpty}`;
};

export const intlLensSyncStatus = (intl, status) => {
  switch (status) {
    case LENS_SYNC_STATUS.FAIL:
      return intl.formatMessage({
        defaultMessage: 'Add Failed', id: 'NYXIMN'
      });
    case LENS_SYNC_STATUS.PENDING:
      return intl.formatMessage({
        defaultMessage: 'Adding...', id: 'Nvr5Ce'
      });
    default:
      return intl.formatMessage({
        defaultMessage: 'Added', id: 'rIAJlE'
      });
  }
};

export const intlLensSyncMessage = (intl, errorType) => {
  switch (errorType) {
    case LENS_ERROR_TYPE.DEVICE_RESERVED_ERROR:
      return intl.formatMessage({
        defaultMessage: 'This device is already being used in another user account or organization. Please revise the duplicate device ID.', id: 'DgQ04K',
      });
    case LENS_ERROR_TYPE.GATEWAY_RESERVED_ERROR:
      return intl.formatMessage({
        defaultMessage: 'This gateway is already being used in another user account or organization. Please revise the duplicate gateway ID.', id: 'pu2GvL',
      });
    default:
      return intl.formatMessage({
        defaultMessage: 'LCP is not able to add the device at the moment, please try again later. If the issue persists, please contact the technical support team.', id: '8/SlDF'
      });
  }
};
