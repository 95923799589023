import { Tabs } from '@skf-internal/ui-components-react-legacy';
import DashboardLubricantManufacturersView from 'containers/DashboardLubricantManufacturersView';
import DashboardLubricantsView from 'containers/DashboardLubricantsView';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  useHistory, useLocation
} from 'react-router-dom';

const tabRouteMap = [
  '/dashboard/lubricants-and-manufacturers/lubricants',
  '/dashboard/lubricants-and-manufacturers/lubricant-manufacturers',
];

const TabsLubricantsAndManufacturers = () => {
  const history = useHistory();
  const location = useLocation();
  const activeTab = tabRouteMap.findIndex((_, index) => location.pathname.includes(tabRouteMap[index]));
  const intl = useIntl();

  const handleTabClick = (_e, index) => {
    history.push(tabRouteMap[index]);
  };

  return (
    <Tabs
      feSelected={activeTab}
      feItems={[
        {
          children: <DashboardLubricantsView history={history} />,
          id: 'DashboardLubricantsAndLubricantManufacturersView#DashboardLubricantsView',
          label: intl.formatMessage({
            defaultMessage: 'Lubricants', id: 'Z6afvc',
          }),
        },
        {
          children: <DashboardLubricantManufacturersView history={history} />,
          id: 'DashboardLubricantsAndLubricantManufacturersView#DashboardLubricantManufacturersView',
          label: intl.formatMessage({
            defaultMessage: 'Manufacturers', id: '8mr1Hh',
          }),
        },
      ]}
      onClick={handleTabClick}
      feType="compressed"
    />
  );
};

export default TabsLubricantsAndManufacturers;
