import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { LocalForm } from 'react-redux-form';
import { Prompt } from 'react-router-dom';

const PromptForm = ({
  initialState,
  onChange,
  onSubmit,
  children,
  ...rest
}) => {
  const intl = useIntl();
  const mounted = useRef(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const handleBeforeSubmit = () => {
    setIsSubmitting(true);
  };

  const handleSubmitFailed = () => {
    setIsBlocking(false);
  };

  const handleSubmit = async values => {
    await onSubmit(values);
  };

  return (
    <LocalForm
      initialState={initialState}
      onChange={modelValue => {
        if (
          Object.keys(initialState).some(
            key => modelValue[key] !== initialState[key]
          )
        ) {
          setIsBlocking(true);
        }
        else {
          setIsBlocking(false);
        }

        if (onChange) {
          onChange(modelValue);
        }
      }}
      onBeforeSubmit={handleBeforeSubmit}
      onSubmit={handleSubmit}
      onSubmitFailed={handleSubmitFailed}
      {...rest}
    >
      <Prompt
        when={isBlocking}
        message={intl.formatMessage({
          defaultMessage:
            'You have unsaved changes. Are you sure you want discard your changes?',
          id: 'A8dvQJ',
        })}
      />
      {children && children(isSubmitting)}
    </LocalForm>
  );
};

PromptForm.propTypes = {
  initialState: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.func,
};

export default PromptForm;
