import { Link as FeLink, Text } from '@skf-internal/ui-components-react-legacy';
import SLoader from 'components/SLoader';
import TemplatePersistingNotification from 'components/TemplatePersistingNotification';
import Menu from 'containers/AdminSuperMenu';
import 'es6-shim';
import { colors } from 'helpers/constants';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as adminActions from 'redux/modules/admin';
import styled, { css } from 'styled-components';

const buttonStyle = css`
  background-color: ${({ primary }) => (primary ? colors.blue : colors.lightGray)};
  color: ${({ primary }) => (primary ? colors.white : colors.white)};
  display: block;
  font-size: 1.125rem;
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  border: 0;
  width: ${({ fullwidth }) => (fullwidth ? '100%' : '10rem')};
  margin: ${({ fullwidth }) => (fullwidth ? 0 : '1rem')};
  ${({ margin }) => margin && `margin: ${margin};`}
  cursor: pointer;
  @media (max-width: 784px) {
    width: 100%;
    // margin: 0 0 0 0;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const RootElement = styled.div`
  flex: 1 1 0%;

  button {
    float: right;
  }
`;

const Content = styled.div`
  margin: auto;
  display: flex;
  flex: 1 1 0%;
  width: 1280px;
  max-width: 100%;
  padding-bottom: 1rem;

  @media only screen and (max-width: 784px) {
    display: block;
  }
`;

const GrayBox = styled.div`
  background-color: ${colors.lightestGray};
  flex: 10 10 0%;
  padding: 1rem;
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 1280px;
  overflow: hidden;
  a {
    display: block;
    width: 50%;
    min-width: 50%;
    padding-bottom: 50px;
    text-align: center;
  }
  a:hover {
    background-color: ${colors.lighterGray};
  }
  @media(max-width:576px) {
    display: block;

    a {
      width: 100%;
    }
  }
`;

const AgeLink = styled(Link)`
  ${buttonStyle}
  text-align: center;
  padding: 5px 0;
`;

const ItemTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  thead tr,
  tbody tr {
    background-color: white;
    border-bottom: 1px solid #ebf0f0;
  }

  td,
  th {
    text-align: left;
    padding: 0.3rem 1rem;
    white-space: normal;
    min-width: 50px;
  }

  .wider {
    min-width: 70px;
  }

  .fixedWidth {
    white-space: nowrap;
    word-break: unset;
  }
`;

function mapState(state) {
  const { admin } = state;
  return {
    admin
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...adminActions }, dispatch)
  };
}

class AdminSuperDashboardView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.data = {
      states: [
        'status', 'alarmLevel', 'country', 'city', 'company'
      ],
      ages: [
        { value: 'PT3H', text: '3 Hours' },
        { value: 'P1D', text: '1 Day' },
        { value: 'P3D', text: '3 Days' },
        { value: 'P7D', text: '7 days' },
        { value: 'P14D', text: '14 Days' },
        { value: 'P1M', text: '1 Month' },
        { value: 'P3M', text: '3 Months' },
      ]
    };
  }

  componentDidMount() {
    this.props.actions.loadPlatformSummary();
  }

  render() {
    if (this.props.admin.loading) {
      return <SLoader />;
    }
    const params = queryString.parse(this.props.location.search);
    const age = params.age || 'PT3H';
    return (
      <RootElement>
        {/* TODO: Remove this once the Super Admin UI has been updated to use Ferris */}
        <TemplatePersistingNotification feType="notification">
          <Text>
            We are aware that there are visual issues. The Super Admin UI will get upgraded to new
            {' '}
            <FeLink as="a" href="https://skf.invisionapp.com/dsm/ab-skf/4-web-applications" target="_blank" rel="noopener noreferrer">SKF Web application design</FeLink>
            {' '}
            at some point.
            {' '}
            If you find one or more features that are unusable due to the visuals please submit a ticket.
          </Text>
        </TemplatePersistingNotification>
        <Menu />
        <GrayBox>

          <Content>
            <h2>Platform stats</h2>
          </Content>
          {this.props.admin.data.platform && (
            <>
              <Content>
                <h3>{`${this.props.admin.data.platform.totals.organizations} organizations have ${this.props.admin.data.platform.totals.gateways}  gateways with ${this.props.admin.data.platform.totals.devices} devices registerd in the platform `}</h3>
              </Content>
              <Content>
                <h3>History(added to platform)</h3>
              </Content>
              <ColumnsContainer>
                <ItemTable tableborder="0">
                  <colgroup>
                    <col style={{ width: '25%' }}/>
                    <col style={{ width: '25%' }}/>
                    <col style={{ width: '25%' }}/>
                    <col style={{ width: '25%' }}/>

                  </colgroup>
                  <thead>
                    <tr>
                      <th>Periode</th>
                      <th>Organizations</th>
                      <th>Gateways</th>
                      <th>devices</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(this.props.admin.data.platform.addedHistory.organizations).map(t => {
                      return (
                        <tr>
                          <td>{t[0]}</td>
                          <td>{this.props.admin.data.platform.addedHistory.organizations[t[0]]}</td>
                          <td>{this.props.admin.data.platform.addedHistory.gateways[t[0]]}</td>
                          <td>{this.props.admin.data.platform.addedHistory.devices[t[0]]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </ItemTable>
              </ColumnsContainer>
            </>
          )}
          <Content>
            <h2>Platform trends</h2>
          </Content>
          <Content>
            {this.data.ages.map(({ value, text }, i) => (
              <AgeLink
                key={`age-${i}-${value}`}
                primary={age === value}
                className="brand"
                to={`/admin-super/dashboard?age=${value}`}
              >
                {text}
              </AgeLink>
            ))}

          </Content>
          <ColumnsContainer>
            {this.data.states.map((state, i) => (
              <Link key={`state-${i}-${state}`} className="brand" to={`/admin-super/dashboard/detail/${state}?age=${age}`}>
                <p>{state.toUpperCase()}</p>
                <img src={`${process.env.REACT_APP_ADMIN_SUPER_ENDPOINT}/admin/all-devices/graphs?metric=${state}&width=500&height=200&age=-${age}`} alt={state}/>
              </Link>
            ))}
          </ColumnsContainer>
        </GrayBox>
      </RootElement>
    );
  }
}

AdminSuperDashboardView.propTypes = {
  location: PropTypes.any,
  admin: PropTypes.any

};

AdminSuperDashboardView.propTypes = {
  actions: PropTypes.object,
};

export default connect(mapState, mapDispatch)(AdminSuperDashboardView);
