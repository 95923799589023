import { useMemo } from 'react';

import useDeviceWithData from './useDeviceWithData';

const useDevicesWithData = devices => {
  // Add data to devices and status information
  const [devicesWithData, deviceStatusCount] = useMemo(() => {
    // Map to ALERT_LEVELS: [OK, OFF, WARNING, ALERT, DEVICE_NO_CONNECTION]
    const deviceStatusCountNew = [0, 0, 0, 0, 0];

    // Fill missing device data
    const deviceList = devices.map(device => {
      const deviceWithData = useDeviceWithData(device);
      deviceStatusCountNew[deviceWithData.alertLevel] += 1;

      return deviceWithData;
    });

    return [deviceList, deviceStatusCountNew];
  }, [devices]);

  return [devicesWithData, deviceStatusCount];
};

export default useDevicesWithData;
