export const cloneDevice = ({
  deviceConfig, deviceData, history, ...rest
}) => ({
  deviceConfig: { ...deviceConfig },
  deviceData: { ...deviceData },
  history: { ...history },
  ...rest,
});

export const cloneDevices = devices => devices.map(cloneDevice);
