import {
  Breakpoints,
  Button,
  Confirm,
  Dialog,
  Flexbox,
  Heading, Search, Spacer,
  Spacings,
  Text
} from '@skf-internal/ui-components-react-legacy';
import ActionButtonAdd from 'components/ActionButtonAdd';
import DialogContentUploadDeviceKeys from 'components/DialogContentUploadDeviceKeys';
import DialogContentUploadDevices from 'components/DialogContentUploadDevices';
import SLoader from 'components/SLoader';
import TableDevices from 'components/TableDevices';
import TemplateSection from 'components/TemplateSection';
import {
  APP_ID, DEMO_MODE, ROUTE_DASHBOARD_DEVICES
} from 'helpers/constants';
import { intlFill } from 'helpers/intlMethods';
import { AuthShape, DeviceShape } from 'helpers/propTypes';
import { alarmNumberToStatus } from 'helpers/ValueMapper';
import useDevicesWithData from 'hooks/useDevicesWithData';
import useFuse from 'hooks/useFuse';
import PropTypes from 'prop-types';
import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import {
  FormattedMessage, useIntl
} from 'react-intl';
import CsvDownload from 'react-json-to-csv';
import { connect } from 'react-redux';
import {
  Route, Switch as RouterSwitch, useHistory
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as authActions from 'redux/modules/auth';
import * as csvTemplateActions from 'redux/modules/csv-template';
import * as deviceActions from 'redux/modules/device';

function mapState(state) {
  const {
    device: { devices, loaded: devicesLoaded, loading: devicesLoading },
    auth,
    csvTemplate: { deviceKeysTemplate, devicesTemplate },
    ui: { width }
  } = state;

  return {
    auth,
    devices,
    devicesLoaded,
    devicesLoading,
    deviceKeysTemplate,
    devicesTemplate,
    width,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(
      { ...authActions, ...deviceActions, ...csvTemplateActions },
      dispatch
    ),
  };
}

const DashboardDevicesView = ({
  actions,
  auth,
  devices,
  devicesLoaded,
  devicesLoading,
  deviceKeysTemplate,
  devicesTemplate,
  width
}) => {
  const intl = useIntl();
  const history = useHistory();
  const [devicesWithData] = useDevicesWithData(devices);
  const { hits, onSearch, onReset } = useFuse(devicesWithData, {
    keys: ['assetTag', 'assetName', 'deviceId', 'productId', 'lastGatewayId'],
    matchAllOnEmptyQuery: true,
    shouldSort: false,
    threshold: 0,
    ignoreLocation: true
  });
  const [showUploadDeviceKeysDialog, setShowUploadDeviceKeysDialog] = useState(false);
  const [showUploadDevicesDialog, setShowUploadDevicesDialog] = useState(false);
  const deviceKeysUploadFileInput = useRef(null);
  const devicesUploadFileInput = useRef(null);

  useEffect(() => {
    if (!devicesLoaded) {
      actions.loadDevices();
    }

    if (!deviceKeysTemplate) {
      actions.getSignedDeviceKeysCsvTemplateUrl();
    }

    if (!devicesTemplate) {
      actions.getSignedDevicesCsvTemplateUrl();
    }

    if (!auth.loaded) {
      actions.getAccount();
    }
  }, []);

  // Generate csv data
  const csvData = useMemo(() => {
    return devicesWithData.map(device => {
      let empty;

      if (device.deviceConfig.emptyingTime === 0) {
        empty = 'Off';
      }
      else if (device.deviceConfig.emptyingTime !== DEMO_MODE) {
        empty = device.empty;

        if (device.empty === 1) {
          empty += 'week';
        }
        else {
          empty += 'weeks';
        }
      }

      return {
        'Asset tag': device.assetTag,
        'Asset name': device.assetName,
        Location: device.location,
        Application: device.application,
        DeviceId: device.productId,
        Lubricant: device.deviceConfig.lubricant,
        Status: alarmNumberToStatus(device.deviceData.alarmNumber),
        Fill: intlFill(intl, device.deviceData.lubricantLeft),
        Empty: empty,
        'Cartridge size': device.deviceConfig.cartridgeSize,
        'Dispense setting': device.deviceConfig.emptyingTime,
        'Last Gateway Id': device.lastGatewayId
      };
    });
  }, [devicesWithData]);

  const handleDeviceRowClick = deviceId => {
    history.push(`${ROUTE_DASHBOARD_DEVICES}/${encodeURIComponent(deviceId)}`);
  };

  const handleUploadDeviceKeysDialogOpen = () => {
    setShowUploadDeviceKeysDialog(true);
  };

  const handleUploadDeviceKeysDialogClose = () => {
    setShowUploadDeviceKeysDialog(false);
  };

  const handleUploadDeviceKeysDialogCancel = () => {
    setShowUploadDeviceKeysDialog(false);
  };

  const handleDeviceKeysUpload = () => {
    const reader = new FileReader();

    reader.onload = data => {
      actions.uploadDeviceKeys(data.target.result);
    };

    if (deviceKeysUploadFileInput.current.files.length > 0) {
      reader.readAsText(deviceKeysUploadFileInput.current.files[0]);
      setShowUploadDeviceKeysDialog(false);
    }
  };

  const handleUploadDevicesDialogOpen = () => {
    setShowUploadDevicesDialog(true);
  };

  const handleUploadDevicesDialogClose = () => {
    setShowUploadDevicesDialog(false);
  };

  const handleUploadDevicesDialogCancel = () => {
    setShowUploadDevicesDialog(false);
  };

  const handleDevicesUpload = () => {
    const reader = new FileReader();

    reader.onload = data => {
      actions.uploadDevices(data.target.result);
    };

    if (devicesUploadFileInput.current.files.length > 0) {
      reader.readAsText(devicesUploadFileInput.current.files[0]);
      setShowUploadDevicesDialog(false);
    }
  };

  if (devicesLoading) {
    return <SLoader />;
  }

  return (
    <RouterSwitch>
      <Route exact path={ROUTE_DASHBOARD_DEVICES}>
        <Dialog
          feAppId={APP_ID}
          feDisplayCloseButton={true}
          feOnClose={() => {
            handleUploadDevicesDialogClose();
          }}
          open={showUploadDevicesDialog}
          title={(
            <FormattedMessage
              defaultMessage="Upload {thing}"
              id="XS9yXk"
              values={{
                thing: <FormattedMessage
                  defaultMessage="devices"
                  id="wRUXwz"
                />
              }}
            />
          )}
        >
          <Confirm
            feButtons={{
              cancel: {
                children: <FormattedMessage defaultMessage="Cancel" id="47FYwb" />,
                onClick: handleUploadDevicesDialogCancel,
              },
              confirm: {
                children: <FormattedMessage defaultMessage="Upload" id="p4N05H" />,
                onClick: handleDevicesUpload,
              },
            }}
          >
            <DialogContentUploadDevices
              devicesTemplateUrl={devicesTemplate}
              fileInputRef={devicesUploadFileInput}
            />
          </Confirm>
        </Dialog>
        <Dialog
          feAppId={APP_ID}
          feDisplayCloseButton={true}
          feOnClose={() => {
            handleUploadDeviceKeysDialogClose();
          }}
          open={showUploadDeviceKeysDialog}
          title={(
            <FormattedMessage
              defaultMessage="Upload {thing}"
              id="XS9yXk"
              values={{
                thing: <FormattedMessage
                  defaultMessage="device keys"
                  id="Dzalil"
                />
              }}
            />
          )}
        >
          <Confirm
            feButtons={{
              cancel: {
                children: <FormattedMessage defaultMessage="Cancel" id="47FYwb" />,
                onClick: handleUploadDeviceKeysDialogCancel,
              },
              confirm: {
                children: <FormattedMessage defaultMessage="Upload" id="p4N05H" />,
                onClick: handleDeviceKeysUpload,
              },
            }}
          >
            <DialogContentUploadDeviceKeys
              deviceKeysTemplateUrl={deviceKeysTemplate}
              fileInputRef={deviceKeysUploadFileInput}
            />
          </Confirm>
        </Dialog>
        <TemplateSection>
          <Heading as="h2"><FormattedMessage defaultMessage="Devices" id="+20FW7" /></Heading>
          <Spacer feSpacing={Spacings.Md} />
          <Flexbox feGap={Spacings.Md} feFlexDirection="column">
            <Text>
              <FormattedMessage
                defaultMessage="Devices are for instance lubricators, lubrication pumps or any other type of hardware that is supplying lubricant to the asset. In this view you can monitor and manage them."
                id="2QYJEY"
              />
            </Text>
          </Flexbox>
        </TemplateSection>
        <TemplateSection>
          <Heading as="h3">
            <FormattedMessage defaultMessage="{thing} table" values={{ thing: <FormattedMessage defaultMessage="Devices" id="+20FW7" /> }} id="CZDCWN" />
          </Heading>
          <Spacer feSpacing={Spacings.Md} />
          <Flexbox
            feGap={Spacings.Md}
            feJustifyContent="space-between"
            feFlexDirection={width >= Breakpoints.Desktop ? 'row' : 'column'}
            feAlignItems={width >= Breakpoints.Desktop ? 'center' : 'flex-start'}
            feFlexWrap="wrap"
          >
            <Flexbox>
              <Search
                size={48}
                onChange={onSearch}
                feResetButton={{
                  onClick: onReset
                }}
                placeholder={(intl.formatMessage({ defaultMessage: 'Search...', id: '0BUTMv' }))}
                feHint={(
                  intl.formatMessage(
                    {
                      defaultMessage: 'Search by {list}',
                      id: 'UlWvQl',
                    },
                    {
                      list: intl.formatList([
                        intl.formatMessage({
                          defaultMessage: 'Asset tag', id: 'hx0GrK',
                        }),
                        intl.formatMessage({
                          defaultMessage: 'Asset name', id: 'H2dli1',
                        }),
                        intl.formatMessage({
                          defaultMessage: 'Device ID', id: 'f9m4vq',
                        }),
                        intl.formatMessage({
                          defaultMessage: 'Gateway', id: 'pMcQck',
                        }),
                      ], { type: 'conjunction' })
                    }
                  )
                )}
                feLabel="Search by Asset tag, Asset name, Device ID, and Gateway"
                feHideLabel
              />
            </Flexbox>
            <Flexbox
              feGap={Spacings.Md}
              feFlexDirection={width >= Breakpoints.Tablet ? 'row' : 'column'}
              feJustifyContent="flex-end"
              feAlignItems={width >= Breakpoints.Tablet ? 'center' : 'left'}
            >
              <CsvDownload
                title={intl.formatMessage({ defaultMessage: 'Download summary as CSV', id: 'fF3F2z' })}
                className="button button--secondary button--sm"
                data={csvData}
              >
                <span>
                  <FormattedMessage
                    id="BpMDzn"
                    defaultMessage="Download summary"
                  />
                </span>
              </CsvDownload>
              {auth && auth.deviceAdmin && (
                <Button
                  onClick={() => {
                    handleUploadDeviceKeysDialogOpen();
                  }}
                  feSize="sm"
                  style={{ marginRight: 0 }}
                >
                  <FormattedMessage
                    id="GX7E+t"
                    defaultMessage="Upload keys"
                  />
                </Button>
              )}
              <Button
                onClick={() => {
                  handleUploadDevicesDialogOpen();
                }}
                feSize="sm"
                style={{ marginRight: 0 }}
              >
                <FormattedMessage
                  id="rp/+En"
                  defaultMessage="Upload devices"
                />
              </Button>
              <ActionButtonAdd
                to={`${ROUTE_DASHBOARD_DEVICES}/add-device`}
                text={<FormattedMessage defaultMessage="Add device" id="kFWJpj" />}
              />
            </Flexbox>
          </Flexbox>
        </TemplateSection>
        <TableDevices
          devices={hits.map(hit => hit.item)}
          onTableBodyRowClick={handleDeviceRowClick}
        />
      </Route>
    </RouterSwitch>
  );
};

DashboardDevicesView.propTypes = {
  actions: PropTypes.shape({
    getAccount: PropTypes.func.isRequired,
    loadDevices: PropTypes.func.isRequired,
    uploadDeviceKeys: PropTypes.func.isRequired,
    uploadDevices: PropTypes.func.isRequired,
    getSignedDeviceKeysCsvTemplateUrl: PropTypes.func.isRequired,
    getSignedDevicesCsvTemplateUrl: PropTypes.func.isRequired,
  }),
  auth: AuthShape.isRequired,
  devices: PropTypes.arrayOf(DeviceShape).isRequired,
  devicesLoaded: PropTypes.bool,
  devicesLoading: PropTypes.bool,
  deviceKeysTemplate: PropTypes.string,
  devicesTemplate: PropTypes.string,
  width: PropTypes.number,
};

export default connect(mapState, mapDispatch)(DashboardDevicesView);
