import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { string } from 'yup';
import { toUpper } from 'lodash';
import {
  Button, Flexbox, Grid, GridItem, Spacings
} from '@skf-internal/ui-components-react-legacy';

import PromptForm from 'components/PromptForm';
import ControlError from 'components/ControlError';
import ControlButton from 'components/ControlButton';
import ControlTextField from 'components/ControlTextField';
import ControlValidationError from 'components/ControlValidationError';
import { FormType, LubricantManufacturerShape } from 'helpers/propTypes';
import { LUBRICANT_MANUFACTURER_NAME_SKF } from 'helpers/constants';

const MIN_LENGTH_LUBRICANT_MANUFACTURER = 1;

const FormLubricantManufacturer = ({
  initialState, cancel, submitBtnText, onSubmit, formType, manufacturers
}) => {
  const intl = useIntl();

  const validationSchema = {
    // Field-level validators
    name: {
      isNotLongEnough: value => string().min(MIN_LENGTH_LUBRICANT_MANUFACTURER).isValidSync(value),
      ...(formType === 'add' && {
        isAlreadyAdded: value => (
          !manufacturers.some(manufacturer => manufacturer.name === value)
          && toUpper(value) !== LUBRICANT_MANUFACTURER_NAME_SKF // Do not allow any variation of "SKF".
        )
      })
    },
    '': {
      // Form-level validator
      hasChanges: values => {
        return Object.keys(values).some(key => values[key] !== initialState[key]);
      },
    }
  };

  return (
    <PromptForm
      model="lubricantManufacturer"
      initialState={initialState}
      onSubmit={onSubmit}
      validators={validationSchema}
    >
      {isSubmitting => (
        <>
          <Grid>
            <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
              <ControlTextField
                feLabel={intl.formatMessage({
                  defaultMessage: 'Name',
                  id: 'HAlOn1'
                })}
                id="name"
                model=".name"
                placeholder="Motor 1"
                hint={intl.formatMessage({
                  defaultMessage: 'Manufacturers are global, please review the list of manufacturers before creating your own.',
                  id: 'niirag'
                })}
                required
              />
              <ControlError
                model=".name"
                messages={{
                  isNotLongEnough: () => (
                    <ControlValidationError
                      validator="isNotLongEnough"
                      values={{ field: 'Name', length: MIN_LENGTH_LUBRICANT_MANUFACTURER }}
                    />
                  ),
                  ...(formType === 'add' && {
                    isAlreadyAdded: value => (
                      <ControlValidationError
                        validator="isAlreadyAdded"
                        values={{ value }}
                      />
                    ),
                  })
                }}
              />
            </GridItem>
            <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
              <Flexbox feJustifyContent="flex-end" feGap={Spacings.Xs}>
                {cancel && (
                <Button type="button" feType="secondary" onClick={cancel.onClick}>
                  {cancel.text}
                </Button>
                )}
                <ControlButton model="lubricantManufacturer" id="submit" type="submit" disabled={isSubmitting || { valid: false }}>
                  {submitBtnText}
                </ControlButton>
              </Flexbox>
            </GridItem>
          </Grid>
        </>
      )}
    </PromptForm>
  );
};

FormLubricantManufacturer.defaultProps = {
  initialState: {
    name: '',
  },
  submitBtnText: (
    <FormattedMessage
      defaultMessage="Add {thing}"
      id="SziwLc"
      values={{ thing: <FormattedMessage defaultMessage="manufacturer" id="RPkvDY" /> }}
    />
  ),
  formType: 'add',
};

FormLubricantManufacturer.propTypes = {
  formType: FormType,
  initialState: PropTypes.shape({
    name: PropTypes.string,
  }),
  cancel: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  manufacturers: PropTypes.arrayOf(LubricantManufacturerShape),
  submitBtnText: PropTypes.element,
  onSubmit: PropTypes.func.isRequired,
};

export default FormLubricantManufacturer;
