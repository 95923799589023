import {
  Heading, Spacer, Spacings
} from '@skf-internal/ui-components-react-legacy';
import FormAsset from 'components/FormAsset';
import SLoader from 'components/SLoader';
import TemplateContent from 'components/TemplateContent';
import { AssetShape } from 'helpers/propTypes';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as assetActions from 'redux/modules/asset';

function mapState(state) {
  const { asset: { loading, loaded, assets } } = state;
  return {
    assets,
    loading,
    loaded,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(assetActions, dispatch),
    dispatch
  };
}

const AddAssetView = ({
  actions, assets, loading, loaded
}) => {
  useEffect(() => {
    if (!loaded) {
      actions.loadAssets();
    }
  }, []);

  const handleSubmit = values => {
    return actions.createAsset(values);
  };

  if (loading) {
    return (
      <SLoader />
    );
  }

  return (
    <TemplateContent
      heading={(
        <Heading as="h2">
          <FormattedMessage
            defaultMessage="Add {thing}"
            id="SziwLc"
            values={{ thing: <FormattedMessage defaultMessage="asset" id="kqj91u" /> }}
          />
        </Heading>
      )}
      linkBack={{
        to: '/dashboard/assets-and-devices/assets'
      }}
    >
      <Spacer feSpacing={Spacings.Xxl} />
      <FormAsset
        onSubmit={handleSubmit}
        assets={assets}
      />
    </TemplateContent>
  );
};

AddAssetView.propTypes = {
  actions: PropTypes.shape({
    loadAssets: PropTypes.func,
    createAsset: PropTypes.func,
  }),
  assets: PropTypes.arrayOf(AssetShape),
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
};

export default connect(
  mapState,
  mapDispatch
)(AddAssetView);
