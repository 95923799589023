import {
  Breakpoints,
  Button,
  Card,
  Flexbox,
  GridItem,
  Heading, Spacer,
  Spacings
} from '@skf-internal/ui-components-react-legacy';
import SLoader from 'components/SLoader';
import TemplateInsightItem, { HEIGHT_DEFAULT, HEIGHT_SMALL } from 'components/TemplateInsightItem';
import { cloneDevices } from 'helpers/cloning';
import { DEMO_MODE } from 'helpers/constants';
import { DeviceShape, FeedbackShape } from 'helpers/propTypes';
import { getGreaseUsage } from 'helpers/ValueMapper';
import iconTotalLubricators from 'images/icons/3-lubricators.png';
import iconRefill from 'images/icons/refill_lubricator.png';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from 'redux/modules/auth';

function mapState(state) {
  const {
    device: { devices, loading: devicesLoading, stats },
    auth: { feedback, loading: authLoading },
    ui: { width },
  } = state;

  const loading = devicesLoading || authLoading;

  return {
    devices,
    loading,
    feedback,
    stats,
    width,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...authActions }, dispatch),
  };
}

const TOO_MANY_DEVICES_THRESHOLD = 1000;

const DashboardViewInsights = ({
  actions, devices, loading, stats, feedback, width
}) => {
  const [emptyIn4Weeks, setEmptyIn4Weeks] = useState(0);

  useEffect(() => {
    if (!loading && devices.length > 0) {
      setEmptyIn4Weeks(
        cloneDevices(devices)
          .filter(device => device.deviceConfig && device.deviceData && (device.deviceConfig.emptyingTime > 0 && device.deviceConfig.emptyingTime !== DEMO_MODE))
          .reduce((result, device) => {
            const empty = getGreaseUsage(device);
            if (empty <= 4) {
              return result + 1;
            }
            return result;
          }, 0)
      );
    }
  }, [loading, devices]);

  if (loading) {
    return <SLoader />;
  }

  return (
    <>
      <GridItem feColspan={{ desktop: 12, tablet: 6, mobile: 6 }}>
        <TemplateInsightItem
          img={{
            src: iconTotalLubricators,
            height: devices.length > TOO_MANY_DEVICES_THRESHOLD ? HEIGHT_SMALL : HEIGHT_DEFAULT,
            alt: 'Total lubricators'
          }}
          number={devices.length}
          text={(
            <FormattedMessage
              defaultMessage="Total Devices"
              id="dvV8VD"
            />
          )}
        />
      </GridItem>
      <GridItem feColspan={{ desktop: 12, tablet: 6, mobile: 6 }}>
        <TemplateInsightItem
          img={{ src: iconRefill, alt: 'Refill within 4 weeks' }}
          number={emptyIn4Weeks}
          text={(
            <FormattedMessage
              defaultMessage="Refill within 4 weeks"
              id="mEMf0p"
            />
          )}
        />
      </GridItem>
      <GridItem feColspan={{ desktop: 12, tablet: 3, mobile: 6 }}>
        <TemplateInsightItem
          number={`${stats.greaseThisMonth || '--'} g`}
          text={(
            <FormattedMessage
              defaultMessage="Consumed this month"
              id="C33C+I"
            />
          )}
        />
      </GridItem>
      <GridItem feColspan={{ desktop: 12, tablet: 3, mobile: 6 }}>
        <TemplateInsightItem
          number={`${stats.greaseThisYear || '--'} g`}
          text={(
            <FormattedMessage
              defaultMessage="Consumed this year"
              id="hu4k/Q"
            />
          )}
        />
      </GridItem>
      {(!feedback || !feedback.basicStats) && (
        <GridItem feColspan={{ desktop: 12, tablet: 6, mobile: 12 }}>
          <Card>
            <Heading as="h4">
              <FormattedMessage
                defaultMessage="Are these insights helpful?"
                id="N5dy3j"
              />
            </Heading>
            <Spacer feSpacing={Spacings.Md} />
            <Flexbox feGap={Spacings.Sm} feFlexDirection={width >= Breakpoints.Desktop ? 'column-reverse' : 'row'}>
              <Button
                alt={(
                  <FormattedMessage
                    defaultMessage="No"
                    id="oUWADl"
                  />
                )}
                feType="secondary"
                feSize="sm"
                onClick={() => {
                  actions.submitFeedback({ type: 'basicStats', value: 'dislike' });
                }}
              >
                <FormattedMessage
                  defaultMessage="No"
                  id="oUWADl"
                />
              </Button>
              <Button
                alt={(
                  <FormattedMessage
                    defaultMessage="Yes"
                    id="a5msuh"
                  />
                )}
                feType="primary"
                feSize="sm"
                onClick={() => {
                  actions.submitFeedback({ type: 'basicStats', value: 'like' });
                }}
              >
                <FormattedMessage
                  defaultMessage="Yes"
                  id="a5msuh"
                />
              </Button>
            </Flexbox>
          </Card>
        </GridItem>
      )}
    </>
  );
};

DashboardViewInsights.propTypes = {
  actions: PropTypes.shape({
    submitFeedback: PropTypes.func,
  }),
  devices: PropTypes.arrayOf(DeviceShape),
  loading: PropTypes.bool,
  stats: PropTypes.object,
  feedback: FeedbackShape,
  width: PropTypes.number,
};

export default connect(mapState, mapDispatch)(DashboardViewInsights);
