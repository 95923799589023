import SLoader from 'components/SLoader';
import Menu from 'containers/AdminSuperMenu';
import 'es6-shim';
import { colors } from 'helpers/constants';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from 'redux/modules/admin';
import styled from 'styled-components';

const RootElement = styled.div`
  flex: 1 1 0%;

  button {
    float: right;
  }
`;

const Content = styled.div`
  margin: auto;
  display: flex;
  flex: 1 1 0%;
  width: 1280px;
  max-width: 100%;
  padding-bottom: 1rem;

  @media only screen and (max-width: 784px) {
    display: block;
  }
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 auto;
  width: 1280px;

  @media(max-width:576px) {
    display: block;
  }
`;

const GrayBox = styled.div`
  background-color: ${colors.lightestGray};
  flex: 10 10 0%;
  padding: 1rem;
`;

const ItemTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  thead tr,
  tbody tr {
    background-color: white;
    border-bottom: 1px solid #ebf0f0;
  }

  td,
  th {
    text-align: left;
    padding: 0.3rem 1rem;
    white-space: normal;
    min-width: 50px;
  }

  .wider {
    min-width: 70px;
  }

  .fixedWidth {
    white-space: nowrap;
    word-break: unset;
  }
`;

function mapState(state) {
  const { admin } = state;
  return {
    admin
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...adminActions }, dispatch)
  };
}

class AdminSuperDashboardOrganizationsUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    this.props.actions.loadOrganizationsUsers(params.org);
  }

  render() {
    if (this.props.admin.loading) {
      return <SLoader />;
    }
    return (
      <RootElement>
        <Menu />
        <GrayBox>
          <div>
            <Content>
              {this.props.admin.data.users && this.props.admin.data.users.length !== 0 && (
                <h2>
                  {`${this.props.admin.data.users[0]['custom:company']} (${this.props.admin.data.users[0].sub}) users` }
                </h2>
              )}
              {this.props.admin.data.users && this.props.admin.data.users.length === 0 && (
                <h2>No users found for this organization</h2>
              )}

            </Content>
            <ColumnsContainer>
              {this.props.admin.data.users && this.props.admin.data.users.length !== 0 && (
                <ItemTable tableborder="0">
                  <colgroup>
                    <col style={{ width: '13%' }}/>
                    <col style={{ width: '13%' }}/>
                    <col style={{ width: '13%' }}/>
                    <col style={{ width: '13%' }}/>
                    <col style={{ width: '12%' }}/>
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Organization ID</th>
                      <th>Country</th>
                      <th>City</th>
                      <th>Given name</th>
                      <th>Family name</th>
                      <th>Position</th>
                      <th>Plant</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ItemList item={this.props.admin.data.users}/>
                  </tbody>
                </ItemTable>
              )}

            </ColumnsContainer>
          </div>
        </GrayBox>
      </RootElement>
    );
  }
}

const ItemList = ({ item }) => {
  if (item) {
    return [...item].map(event => <DataItem event={event} />);
  }
  return [];
};

const DataItem = ({ event }) => {
  return (
    <tr>
      <td>{event.email}</td>
      <td>{event.sub}</td>
      <td>{event['custom:country']}</td>
      <td>{event['custom:city']}</td>
      <td>{event.given_name}</td>
      <td>{event.family_name}</td>
      <td>{event['custom:position']}</td>
      <td>{event['custom:plant']}</td>

    </tr>
  );
};

DataItem.propTypes = {
  event: PropTypes.object,
};

AdminSuperDashboardOrganizationsUsers.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.any
};

AdminSuperDashboardOrganizationsUsers.propTypes = {
  actions: PropTypes.object,
};

export default connect(mapState, mapDispatch)(AdminSuperDashboardOrganizationsUsers);
