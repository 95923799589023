import SLoader from 'components/SLoader';
import Menu from 'containers/AdminSuperMenu';
import 'es6-shim';
import { colors } from 'helpers/constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from 'redux/modules/admin';
import styled from 'styled-components';

const RootElement = styled.div`
  flex: 1 1 0%;

  button {
    float: right;
  }
`;

const Content = styled.div`
  margin: auto;
  display: flex;
  flex: 1 1 0%;
  width: 1280px;
  max-width: 100%;
  padding-bottom: 1rem;

  @media only screen and (max-width: 784px) {
    display: block;
  }
`;

const ItemTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  thead tr,
  tbody tr {
    background-color: white;
    border-bottom: 1px solid #ebf0f0;
  }

  td,
  th {
    text-align: left;
    padding: 0.3rem 1rem;
    white-space: normal;
    min-width: 50px;
  }

  .wider {
    min-width: 70px;
  }

  .fixedWidth {
    white-space: nowrap;
    word-break: unset;
  }
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 1280px;
  overflow: hidden;
  a {
    display: block;
    width: 50%;
    min-width: 50%;
    padding-bottom: 50px;
    text-align: center;
  }
  a:hover {
    background-color: ${colors.lighterGray};
  }
  @media(max-width:576px) {
    display: block;

    a {
      width: 100%;
    }
  }
`;

const GrayBox = styled.div`
  background-color: ${colors.lightestGray};
  flex: 10 10 0%;
  padding: 1rem;
`;

function mapState(state) {
  const { admin } = state;
  return {
    admin
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...adminActions }, dispatch)
  };
}

class AdminSuperDashboardOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.params = queryString.parse(this.props.location.search);
    this.props.actions.loadOrganizationsUsers(this.params.org);
    this.props.actions.loadOrganization(this.params.org);
    this.props.actions.loadOrganizationsDevices(this.params.org);
    this.props.actions.loadOrganizationsGateways(this.params.org);
  }

  render() {
    if (this.props.admin.loading) {
      return <SLoader />;
    }
    let createdAt = '--';
    if (this.props.admin.data.org) {
      this.dateFormat = 'lll';
      const time = moment(this.props.admin.data.org.createdAt);
      createdAt = time.format(this.dateFormat);
    }
    return (
      <RootElement>
        <Menu />
        <GrayBox>
          <div>
            <Content>
              <h2>Organization</h2>
            </Content>
            <Content>
              {this.props.admin.data.org && (
                <ul>
                  <li>{`Company: ${this.props.admin.data.org.company}`}</li>
                  <li>{`Country: ${this.props.admin.data.org.country}`}</li>
                  <li>{`City: ${this.props.admin.data.org.city}`}</li>
                  <li>{`Name: ${this.props.admin.data.org.name}`}</li>
                  <li>{`Created at: ${createdAt}`}</li>
                </ul>
              )}
            </Content>

            {this.props.admin.data.org && this.params && (
              <>
                <Content>
                  <h2>Users</h2>
                </Content>
                <ColumnsContainer>
                  <ItemTable tableborder="0">
                    <colgroup>
                      <col style={{ width: '12%' }}/>
                      <col style={{ width: '12%' }}/>
                      <col style={{ width: '12%' }}/>
                      <col style={{ width: '12%' }}/>
                      <col style={{ width: '12%' }}/>
                      <col style={{ width: '12%' }}/>
                      <col style={{ width: '12%' }}/>
                      <col style={{ width: '12%' }}/>
                    </colgroup>
                    <thead>
                      <tr>
                        <th>Email</th>
                        <th>Country</th>
                        <th>City</th>
                        <th>Given name</th>
                        <th>Family name</th>
                        <th>Position</th>
                        <th>Plant</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.admin.data.users && Object.entries(this.props.admin.data.users).map(t => {
                        return (
                          <tr>
                            <td>{this.props.admin.data.users[t[0]].email}</td>
                            <td>{this.props.admin.data.users[t[0]]['custom:country']}</td>
                            <td>{this.props.admin.data.users[t[0]]['custom:city']}</td>
                            <td>{this.props.admin.data.users[t[0]].given_name}</td>
                            <td>{this.props.admin.data.users[t[0]].family_name}</td>
                            <td>{this.props.admin.data.users[t[0]]['custom:position']}</td>
                            <td>{this.props.admin.data.users[t[0]]['custom:plant']}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </ItemTable>
                </ColumnsContainer>
                <Content>
                  <h2>Gateways</h2>
                </Content>
                <ColumnsContainer>
                  <ItemTable tableborder="0">
                    <colgroup>
                      <col style={{ width: '16%' }}/>
                      <col style={{ width: '16%' }}/>
                      <col style={{ width: '16%' }}/>
                      <col style={{ width: '16%' }}/>
                      <col style={{ width: '16%' }}/>
                      <col style={{ width: '16%' }}/>

                    </colgroup>
                    <thead>
                      <tr>
                        <th>gatewayId</th>
                        <th>name</th>
                        <th>Credit created</th>
                        <th>Credit expires</th>
                        <th>Credit updated</th>
                        <th>Credit expires email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.admin.data.gateways && Object.entries(this.props.admin.data.gateways).map(t => {
                        return (
                          <tr>
                            <td>{this.props.admin.data.gateways[t[0]].gatewayId}</td>
                            <td>{this.props.admin.data.gateways[t[0]].name}</td>
                            <td>{moment(this.props.admin.data.gateways[t[0]].creditCreatedAt).format(this.dateFormat)}</td>
                            <td>{moment(this.props.admin.data.gateways[t[0]].creditExpiresAt).format(this.dateFormat)}</td>
                            <td>{moment(this.props.admin.data.gateways[t[0]].updatedAt).format(this.dateFormat)}</td>
                            <td>{moment(this.props.admin.data.gateways[t[0]].creditExpiryEmailSentAt).format(this.dateFormat)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </ItemTable>
                </ColumnsContainer>
                <Content>
                  <h2>Devices</h2>
                </Content>
                <ColumnsContainer>
                  <ItemTable tableborder="0">
                    <colgroup>
                      <col style={{ width: '14%' }}/>
                      <col style={{ width: '14%' }}/>
                      <col style={{ width: '14%' }}/>
                      <col style={{ width: '14%' }}/>
                      <col style={{ width: '14%' }}/>
                      <col style={{ width: '14%' }}/>
                      <col style={{ width: '14%' }}/>
                    </colgroup>
                    <thead>
                      <tr>
                        <th>Device ID</th>
                        <th>Product ID</th>
                        <th>Asset Tag</th>
                        <th>Application</th>
                        <th>Asset Name</th>
                        <th>Location</th>
                        <th>updated at</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.admin.data.devices && Object.entries(this.props.admin.data.devices).map(t => {
                        return (
                          <tr>
                            <td>{this.props.admin.data.devices[t[0]].deviceId}</td>
                            <td>{this.props.admin.data.devices[t[0]].productId}</td>
                            <td>{this.props.admin.data.devices[t[0]].assetTag}</td>
                            <td>{this.props.admin.data.devices[t[0]].application}</td>
                            <td>{this.props.admin.data.devices[t[0]].assetName}</td>
                            <td>{this.props.admin.data.devices[t[0]].location}</td>
                            <td>{moment(this.props.admin.data.devices[t[0]].updatedAt).format(this.dateFormat)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </ItemTable>
                </ColumnsContainer>
              </>
            )}
          </div>
        </GrayBox>
      </RootElement>
    );
  }
}

AdminSuperDashboardOrganization.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.any
};

AdminSuperDashboardOrganization.propTypes = {
  actions: PropTypes.object,
};

export default connect(mapState, mapDispatch)(AdminSuperDashboardOrganization);
