import { LUBRICANTS, ROUTE_DASHBOARD_LUBRICANTS, ROUTE_DASHBOARD_MANUFACTURERS } from 'helpers/constants';
import messages from 'helpers/messages';
import { displayErrorMessage, displaySuccessMessage } from './notification';
import { redirectTo } from './redirect';

// LUBRICANTS
const LOAD_LUBRICANTS = 'myrina/lubricants/LOAD_LUBRICANTS';
const LOAD_LUBRICANTS_SUCCESS = 'myrina/lubricants/LOAD_SUCCESS_LUBRICANTS';
const LOAD_LUBRICANTS_FAIL = 'myrina/lubricants/LOAD_FAIL_LUBRICANTS';

const ADD_LUBRICANT_SUCCESS = 'myrina/lubricants/ADD_LUBRICANT_SUCCESS';
const ADD_LUBRICANT_FAIL = 'myrina/lubricants/ADD_LUBRICANT_FAIL';

const DELETE_LUBRICANT_SUCCESS = 'myrina/lubricants/{lubricantId}/DELETE_LUBRICANT_SUCCESS';
const DELETE_LUBRICANT_FAIL = 'myrina/lubricants/{lubricantId}/DELETE_LUBRICANT_FAIL';

// LUBRICANT MANUFACTURERS
const LOAD_MANUFACTURERS = 'myrina/lubricant-manufacturers/LOAD_MANUFACTURERS';
const LOAD_MANUFACTURERS_SUCCESS = 'myrina/lubricant-manufacturers/LOAD_MANUFACTURERS_SUCCESS';
const LOAD_MANUFACTURERS_FAIL = 'myrina/lubricant-manufacturers/LOAD_MANUFACTURERS_FAIL';

const ADD_MANUFACTURER_SUCCESS = 'myrina/lubricant-manufacturers/ADD_MANUFACTURER_SUCCESS';
const ADD_MANUFACTURER_FAIL = 'myrina/lubricant-manufacturers/ADD_MANUFACTURER_FAIL';

const UPDATE_MANUFACTURER_SUCCESS = 'myrina/lubricant-manufacturers/{name}/UPDATE_MANUFACTURER_SUCCESS';
const UPDATE_MANUFACTURER_FAIL = 'myrina/lubricant-manufacturers/{name}/UPDATE_MANUFACTURER_FAIL';

const DELETE_MANUFACTURER_SUCCESS = 'myrina/lubricant-manufacturers/DELETE_MANUFACTURERS_SUCCESS';
const DELETE_MANUFACTURER_FAIL = 'myrina/lubricant-manufacturers/DELETE_MANUFACTURERS_FAIL';

// LUBRICANT DEVICES
const LOAD_DEVICES_WITH_LUBRICANT_SUCCESS = 'myrina/lubricants/{lubricantId}/devices/LOAD_DEVICES_WITH_LUBRICANT_SUCCESS';
const LOAD_DEVICES_WITH_LUBRICANT_FAIL = 'myrina/lubricants/{lubricantId}/devices/LOAD_DEVICES_WITH_LUBRICANT_FAIL';

const initialState = {
  manufacturers: {
    loaded: false,
    loading: true,
    items: []
  },
  lubricants: {
    loaded: false,
    loading: true,
    items: []
  },
};

export default function lubricant(state = initialState, action = {}) {
  switch (action.type) {
    // LUBRICANTS
    case LOAD_LUBRICANTS: {
      return {
        ...state,
        manufacturers: {
          ...state.manufacturers,
        },
        lubricants: {
          ...state.lubricants,
          loading: true,
          loaded: false,
        }
      };
    }
    case LOAD_LUBRICANTS_SUCCESS: {
      return {
        ...state,
        manufacturers: {
          ...state.manufacturers,
        },
        lubricants: {
          loading: false,
          loaded: true,
          items: action.result,
        }
      };
    }
    case LOAD_LUBRICANTS_FAIL: {
      return {
        ...state,
        manufacturers: {
          ...state.manufacturers,
        },
        lubricants: {
          items: [],
          loading: false,
          loaded: true,
          error: action.error,
        }
      };
    }
    case ADD_LUBRICANT_SUCCESS: {
      return {
        ...state,
        manufacturers: {
          ...state.manufacturers,
        },
        lubricants: {
          ...state.lubricants,
          loading: false,
          loaded: true,
        }
      };
    }
    // LUBRICANT MANUFACTURERS
    case LOAD_MANUFACTURERS: {
      return {
        ...state,
        manufacturers: {
          ...state.manufacturers,
          loading: true,
          loaded: false,
        },
        lubricants: {
          ...state.lubricants,
        }
      };
    }
    case LOAD_MANUFACTURERS_SUCCESS: {
      return {
        ...state,
        manufacturers: {
          loading: false,
          loaded: true,
          items: action.result,
        },
        lubricants: {
          ...state.lubricants,
        }
      };
    }
    case LOAD_MANUFACTURERS_FAIL: {
      return {
        ...state,
        manufacturers: {
          items: [],
          loading: false,
          loaded: true,
          error: action.error,
        },
        lubricants: {
          ...state.lubricants,
        }
      };
    }
    case ADD_MANUFACTURER_SUCCESS: {
      return {
        ...state,
        manufacturers: {
          ...state.manufacturers,
          loading: false,
          loaded: true,
        },
        lubricants: {
          ...state.lubricants,
        }
      };
    }
    case ADD_MANUFACTURER_FAIL: {
      return {
        ...state,
        manufacturers: {
          items: [...state.manufacturers.items],
          loading: false,
          loaded: true,
          error: action.error,
        },
        lubricants: {
          ...state.lubricants,
        }
      };
    }
    case DELETE_MANUFACTURER_SUCCESS: {
      return {
        ...state,
        manufacturers: {
          ...state.manufacturers,
          loading: false,
          loaded: true,
        },
        lubricants: {
          ...state.lubricants,
        }
      };
    }
    case DELETE_MANUFACTURER_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    }
    // LUBRICANT DEVICES
    case LOAD_DEVICES_WITH_LUBRICANT_SUCCESS: {
      return {
        ...state,
        manufacturers: {
          ...state.manufacturers,
        },
        lubricants: {
          loading: false,
          loaded: true,
          items: state.lubricants.items.map(lub => {
            if (action.result.length) {
              const lubricantType = action.result[0]['lubricantType|deviceId'].split('|')[0];

              if (lub.lubricantType === lubricantType) {
                return {
                  ...lubricant,
                  count: action.result.length,
                  devices: action.result,
                };
              }
            }

            return lub;
          })
        }
      };
    }
    case LOAD_DEVICES_WITH_LUBRICANT_FAIL: {
      return {
        ...state,
        manufacturers: {
          ...state.manufacturers,
        },
        lubricants: {
          items: [],
          loading: false,
          loaded: true,
          error: action.error,
        }
      };
    }
    default: {
      return state;
    }
  }
}

/**
 * Get lubricants without device data
 */
export function loadLubricants() {
  return {
    types: [LOAD_LUBRICANTS, LOAD_LUBRICANTS_SUCCESS, LOAD_LUBRICANTS_FAIL],
    promise: client => {
      return client.get('/customer/lubricants')
        .then(res => {
          return LUBRICANTS.concat(res);
        });
    }
  };
}

/**
 * Add a new lubricant
 *
 * @param {{ denomination: string, manufacturerName: string, lubricantType: string }} data
 */
export function createLubricant(data) {
  return {
    types: [LOAD_LUBRICANTS, ADD_LUBRICANT_SUCCESS, ADD_LUBRICANT_FAIL],
    promise: (client, dispatch) => {
      return client
        .post('/customer/lubricants', {
          headers: {
            'content-type': 'application/json',
          },
          data
        })
        .then(res => {
          dispatch(loadLubricants());
          dispatch(redirectTo(ROUTE_DASHBOARD_LUBRICANTS));
          dispatch(displaySuccessMessage(messages.successLubricantAdded));
          return res;
        })
        .catch(e => {
          dispatch(displayErrorMessage(messages.errorLubricantAdd));
          return e;
        });
    }
  };
}

export function deleteLubricant(denomination) {
  return {
    types: [LOAD_LUBRICANTS, DELETE_LUBRICANT_SUCCESS, DELETE_LUBRICANT_FAIL],
    promise: (client, dispatch) => {
      return client.del(`/customer/lubricants/${encodeURIComponent(denomination)}`)
        .then(res => {
          dispatch(loadLubricants());
          dispatch(redirectTo(ROUTE_DASHBOARD_LUBRICANTS));
          dispatch(displaySuccessMessage(messages.successLubricantDeleted));
          return res;
        })
        .catch(e => {
          dispatch(displayErrorMessage(messages.errorLubricantDelete));
          return e;
        });
    }
  };
}

/**
 * Get lubricant manufacturers
 */
export function loadLubricantManufacturers() {
  return {
    types: [LOAD_MANUFACTURERS, LOAD_MANUFACTURERS_SUCCESS, LOAD_MANUFACTURERS_FAIL],
    promise: client => {
      return client
        .get('/customer/lubricant-manufacturers');
    }
  };
}

/**
 * Create a lubricant manufacturer
 *
 * @param {{ name: String }} data Create data
 */
export function createLubricantManufacturer(data) {
  return {
    types: [LOAD_MANUFACTURERS, ADD_MANUFACTURER_SUCCESS, ADD_MANUFACTURER_FAIL],
    promise: (client, dispatch) => {
      return client.post('/customer/lubricant-manufacturers', {
        headers: {
          'content-type': 'application/json',
        },
        data
      }).then(res => {
        dispatch(loadLubricantManufacturers());
        dispatch(loadLubricants());
        dispatch(redirectTo(ROUTE_DASHBOARD_MANUFACTURERS));
        dispatch(displaySuccessMessage(messages.successLubricantManufacturerAdded));
        return res;
      }).catch(e => {
        dispatch(displayErrorMessage(messages.errorLubricantManufacturerAdd));
        return e;
      });
    }
  };
}

/**
 * Update a lubricant manufacturer
 *
 * @param {{ name: String }} data Update data
 */
export function updateLubricantManufacturer(currentName, data) {
  return {
    types: [LOAD_MANUFACTURERS, UPDATE_MANUFACTURER_SUCCESS, UPDATE_MANUFACTURER_FAIL],
    promise: (client, dispatch) => {
      return client.put(`/customer/lubricant-manufacturers/${encodeURIComponent(currentName)}`, {
        headers: {
          'content-type': 'application/json',
        },
        data
      }).then(res => {
        dispatch(loadLubricantManufacturers());
        dispatch(loadLubricants());
        dispatch(redirectTo(ROUTE_DASHBOARD_MANUFACTURERS));
        dispatch(displaySuccessMessage(messages.successLubricantManufacturerUpdated));
        return res;
      }).catch(e => {
        dispatch(displayErrorMessage(messages.errorLubricantManufacturerUpdate));
        return e;
      });
    }
  };
}

export function deleteLubricantManufacturer(name) {
  return {
    types: [LOAD_MANUFACTURERS, DELETE_MANUFACTURER_SUCCESS, DELETE_MANUFACTURER_FAIL],
    promise: (client, dispatch) => {
      return client.del(`/customer/lubricant-manufacturers/${encodeURIComponent(name)}`)
        .then(res => {
          dispatch(loadLubricantManufacturers());
          dispatch(loadLubricants());
          dispatch(redirectTo(ROUTE_DASHBOARD_MANUFACTURERS));
          dispatch(displaySuccessMessage(messages.successLubricantManufacturerDeleted));
          return res;
        })
        .catch(e => {
          dispatch(displayErrorMessage(messages.errorLubricantManufacturerDelete));
          return e;
        });
    }
  };
}
