import React from 'react';
import PropTypes from 'prop-types';
import { FontSizes, Text } from '@skf-internal/ui-components-react-legacy';

const TextLabel = ({ text }) => {
  return (
    <Text feFontSize={FontSizes.Xs}>{text}</Text>
  );
};

TextLabel.propTypes = {
  text: PropTypes.string
};

export default TextLabel;
