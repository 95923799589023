export default function clientMiddleware(client) {
  return store => next => action => {
    // Handle only client type request
    if (typeof action === 'function' || !action.promise) {
      return next(action);
    }

    const {
      promise,
      types,
      fallback,
      ...rest
    } = action;

    const [REQUEST, SUCCESS, FAILURE] = types;

    next({ ...rest, type: REQUEST });

    return promise(client, store.dispatch, store.getState)
      .then(result => {
        return next({ ...rest, result, type: SUCCESS });
      })
      .catch(error => {
        if (fallback) {
          return fallback(client, store.dispatch, store.getState)
            .then(result => {
              return next({ ...rest, result, type: SUCCESS });
            })
            .catch(fallbackError => {
              return next({ ...rest, error: fallbackError, type: FAILURE });
            });
        }

        return next({ ...rest, error, type: FAILURE });
      });
  };
}
