import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Link,
} from '@skf-internal/ui-components-react-legacy';

import TemplateDialogContent from 'components/TemplateDialogContent';

const DialogContentUploadAssets = ({ assetsTemplateUrl, fileInputRef }) => {
  return (
    <TemplateDialogContent
      content={[
        {
          subheading: <FormattedMessage defaultMessage="Upload a CSV file. Columns should be" id="ow5mJK" />,
          items: [
            {
              id: 'assetTag',
              children: <FormattedMessage defaultMessage="Column 1: Asset tag (e.g. 19-0025)" id="q4ADFr" />
            },
            {
              id: 'assetName',
              children: <FormattedMessage defaultMessage="Column 2: Asset name (e.g. ID Fan)" id="9D4/bT" />
            },
            {
              id: 'location',
              children: <FormattedMessage defaultMessage="Column 3: Lubrication point (e.g. DE, NDE)" id="KI7YAU" />
            },
            {
              id: 'application',
              children: <FormattedMessage defaultMessage="Column 4: Functional location (e.g. Pump)" id="1Z6mKP" />
            },
          ]
        }
      ]}
    >
      <Link as="a" href={assetsTemplateUrl} download="template.csv">
        <FormattedMessage defaultMessage="Download a template" id="Bu/sXA" />
      </Link>
      <input type="file" ref={fileInputRef} />
    </TemplateDialogContent>
  );
};

DialogContentUploadAssets.propTypes = {
  assetsTemplateUrl: PropTypes.string,
  fileInputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

export default DialogContentUploadAssets;
