import React from 'react';
import { FormattedMessage } from 'react-intl';

import TemplateDialogContent from 'components/TemplateDialogContent';
import { OrganizationUserShape } from 'helpers/propTypes';

const DialogContentDeleteOrganizationUser = ({ user }) => {
  if (user === undefined) {
    return null;
  }

  return (
    <TemplateDialogContent
      content={[
        {
          subheading: (
            <FormattedMessage
              defaultMessage="{thing} details"
              values={{ thing: <FormattedMessage defaultMessage="User" id="EwRIOm" /> }}
              id="2Q8Pzz"
            />
          ),
          items: [
            {
              id: 'email',
              children: (
                <FormattedMessage
                  defaultMessage="Email: {email}"
                  id="uPNlBw"
                  values={{ email: user.email }}
                />
              )
            },
            {
              id: 'userId',
              children: (
                <FormattedMessage
                  defaultMessage="User ID: {userId}"
                  id="8r7k51"
                  values={{ userId: user.sub || user.email }}
                />
              )
            },
          ]
        }
      ]}
    />
  );
};

DialogContentDeleteOrganizationUser.defaultProps = {
  user: {
    email: '--',
  }
};

DialogContentDeleteOrganizationUser.propTypes = {
  user: OrganizationUserShape
};

export default DialogContentDeleteOrganizationUser;
