import React from 'react';
import PropTypes from 'prop-types';
import {
  Link
} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  Icons,
  Link as FeLink
} from '@skf-internal/ui-components-react-legacy';

const ActionButtonAdd = ({ to, text, disabled }) => {
  return (
    <Link to={to}>
      <FeLink
        as="button"
        type="button"
        feIcon={{
          feIcon: Icons.Plus,
          position: 'left'
        }}
        disabled={disabled}
      >
        {text}
      </FeLink>
    </Link>
  );
};

ActionButtonAdd.propTypes = {
  text: <FormattedMessage defaultMessage="Add" id="2/2yg+" />
};

ActionButtonAdd.propTypes = {
  to: PropTypes.string,
  text: PropTypes.node,
  disabled: PropTypes.bool,
};

export default ActionButtonAdd;
