import React from 'react';
import { FormattedMessage } from 'react-intl';

import TemplateDialogContent from 'components/TemplateDialogContent';
import { GatewayShape } from 'helpers/propTypes';

const DialogContentDeleteGateway = ({ gateway }) => {
  if (gateway === undefined) {
    return null;
  }

  return (
    <TemplateDialogContent
      content={[
        {
          subheading: (
            <FormattedMessage
              defaultMessage="{thing} details"
              values={{ thing: <FormattedMessage defaultMessage="Gateway" id="pMcQck" /> }}
              id="2Q8Pzz"
            />
          ),
          items: [
            {
              id: 'gatewayId',
              children: (
                <FormattedMessage
                  defaultMessage="Gateway ID: {gatewayId}"
                  id="0r4nHm"
                  values={{ gatewayId: gateway.gatewayId }}
                />
              )
            },
            {
              id: 'name',
              children: (
                <FormattedMessage
                  defaultMessage="Name: {name}"
                  id="sNtwjA"
                  values={{ name: gateway.name }}
                />
              )
            },
          ]
        }
      ]}
    />
  );
};

DialogContentDeleteGateway.defaultProps = {
  gateway: {
    gatewayId: '--',
    name: '--',
  }
};

DialogContentDeleteGateway.propTypes = {
  gateway: GatewayShape
};

export default DialogContentDeleteGateway;
