import { Flexbox, Grid, GridItem } from '@skf-internal/ui-components-react-legacy';
import ControlButton from 'components/ControlButton';
import ControlError from 'components/ControlError';
import ControlTextField from 'components/ControlTextField';
import ControlValidationError from 'components/ControlValidationError';
import PromptForm from 'components/PromptForm';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { string } from 'yup';

const FormInviteUsers = ({ initialState, emails, onSubmit }) => {
  const intl = useIntl();

  const validationSchema = {
    // Field-level validators
    email: {
      isRequired: value => string().required().isValidSync(value),
      isNotValid: value => string().email().isValidSync(value),
      hasBeenInvited: value => !emails.includes(value),
    },
    '': {
      // Form-level validator
      hasChanges: values => {
        return Object.keys(values).some(
          key => values[key] !== initialState[key]
        );
      },
    },
  };

  return (
    <PromptForm
      model="user"
      onSubmit={onSubmit}
      initialState={initialState}
      validators={validationSchema}
    >
      {isSubmitting => (
        <>
          <Grid>
            <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
              <ControlTextField
                feLabel={intl.formatMessage({
                  defaultMessage: 'Email',
                  id: 'sy+pv5'
                })}
                id="email"
                model=".email"
                placeholder="alice@example.com"
                type="email"
                validateOn="blur"
              />
              <ControlError
                model=".email"
                messages={{
                  isRequired: <ControlValidationError validator="isRequired" />,
                  isNotValid: value => (
                    <ControlValidationError
                      validator="isNotValid"
                      values={{ value, thing: <FormattedMessage defaultMessage="email" id="yUVl6+" /> }}
                    />
                  ),
                  hasBeenInvited: value => (
                    <ControlValidationError
                      validator="hasBeenInvited"
                      values={{ value }}
                    />
                  ),
                }}
              />
            </GridItem>
            <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
              <Flexbox feJustifyContent="flex-end">
                <ControlButton
                  model="user"
                  id="submit"
                  type="submit"
                  disabled={isSubmitting || { valid: false }}
                >
                  <FormattedMessage
                    defaultMessage="Send invitation"
                    id="83nTSd"
                  />
                </ControlButton>
              </Flexbox>
            </GridItem>
          </Grid>
        </>
      )}
    </PromptForm>
  );
};

FormInviteUsers.defaultProps = {
  initialState: {
    email: '',
  },
};

FormInviteUsers.propTypes = {
  initialState: PropTypes.shape({
    email: PropTypes.string,
  }),
  emails: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func.isRequired,
};

export default FormInviteUsers;
