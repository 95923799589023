import React from 'react';
import { FormattedMessage } from 'react-intl';

import TemplateDialogContent from 'components/TemplateDialogContent';
import { LubricantManufacturerShape } from 'helpers/propTypes';

const DialogContentDeleteLubricantManufacturer = ({ manufacturer }) => {
  return (
    <TemplateDialogContent
      content={[
        {
          subheading: (
            <FormattedMessage
              defaultMessage="{thing} details"
              values={{ thing: <FormattedMessage defaultMessage="Lubricant manufacturer" id="s2YCQl" /> }}
              id="2Q8Pzz"
            />
          ),
          items: [
            {
              id: 'name',
              children: (
                <FormattedMessage
                  defaultMessage="Name: {name}"
                  id="sNtwjA"
                  values={{ name: manufacturer.name }}
                />
              )
            },
          ]
        }
      ]}
    />
  );
};

DialogContentDeleteLubricantManufacturer.defaultProps = {
  manufacturer: {
    name: '--',
  }
};

DialogContentDeleteLubricantManufacturer.propTypes = {
  manufacturer: LubricantManufacturerShape,
};

export default DialogContentDeleteLubricantManufacturer;
