import {
  Button,
  Colors,
  Flexbox,
  Grid,
  GridItem,
  Heading,
  Loader,
  Spacings,
  Text
} from '@skf-internal/ui-components-react-legacy';
import ControlButton from 'components/ControlButton';
import ControlError from 'components/ControlError';
import ControlSelectField from 'components/ControlSelectField';
import ControlTextField from 'components/ControlTextField';
import ControlValidationError from 'components/ControlValidationError';
import PromptForm from 'components/PromptForm';
import { client } from 'helpers/ApiClient';
import { cartridgeItems, DeviceType } from 'helpers/constants';
import { intlEmptySetting } from 'helpers/intlMethods';
import {
  AssetShape,
  DeviceShape,
  FormType,
  IntlShape,
  LubricantShape
} from 'helpers/propTypes';
import { asOptionItem } from 'helpers/selectOptions';
import { emptySettings } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { actions } from 'react-redux-form';
import { number, string } from 'yup';

// "productId" (Device ID) is something like "61GC72"
const DEVICE_ID_REGEX = /^[0-9A-Z]{6,}$/;

/**
 * FormDevice component
 *
 * Needs to be a `React.Component` because `react-redux-form` does
 * not support binding form dispatch events on functional components.
 */
class FormDevice extends Component {
  constructor(props) {
    super(props);

    const asset = props.assets.find(
      a => a.assetTag === props.initialState.assetTag
    );
    const initDeviceType = props.formType !== 'add' ? props.initialState.deviceType : DeviceType.LUBRICATOR_V1;
    this.state = {
      assetLocationItems: asset.locations.map(location => asOptionItem({ location }, 'location', 'location')),
      settingItems: this.getEmptyingSetting(initDeviceType),
      disableSetting: props.formType === 'add',
      isFetchingDeviceType: false,
      fetchingDeviceTypeError: '',
    };

    this.validationSchema = {
      deviceId: {
        isRequired: value => string().required().isValidSync(value),
        isValidExample: value => string().matches(DEVICE_ID_REGEX).isValidSync(value),
        ...(props.formType === 'add' && {
          isAlreadyAdded: value => !props.devices.some(device => device.productId === value),
        }),
      },
      'deviceConfig.cartridgeSize': {
        isRequired: value => number().required().isValidSync(value),
      },
      'deviceConfig.lubricant': {
        isRequired: value => string().required().isValidSync(value),
      },
      'deviceConfig.emptyingTime': {
        isRequired: value => number().required().isValidSync(value),
      },
      '': {
        // Form-level validator
        hasChanges: values => {
          return Object.keys(values).some(
            key => values[key] !== props.initialState[key]
          );
        },
      },
    };
  }

  componentWillUnmount() {
    this.formDispatch(actions.reset('device'));
  }

  getEmptyingSetting(deviceType = DeviceType.LUBRICATOR_V1) {
    const nbMonths = deviceType === DeviceType.LUBRICATOR_V2 ? 24 : 12;
    return emptySettings(nbMonths).map(setting => ({
      value: setting.value,
      label: intlEmptySetting(this.props.intl, setting.value, { setting }),
    }));
  }

  handleAssetTagChange(assetTag) {
    const asset = this.props.assets.find(a => a.assetTag === assetTag);

    if (asset) {
      this.formDispatch(actions.change('device.assetName', asset.assetName));
      this.formDispatch(
        actions.change('device.application', asset.application)
      );
      this.setState(
        {
          assetLocationItems: asset.locations.map(location => asOptionItem({ location }, 'location', 'location')),
        },
        () => {
          const { value } = this.state.assetLocationItems[0];
          this.formDispatch(actions.change('device.location', value));
        }
      );
    }
  }

  async handleDeviceIdBlur(event) {
    const deviceId = event.target.value;
    this.setState(state => ({
      ...state,
      fetchingDeviceTypeError: '',
    }));
    if (this.validationSchema.deviceId.isValidExample(deviceId)) {
      try {
        this.setState(state => ({
          ...state,
          isFetchingDeviceType: true,
        }));
        const response = await client.get(
          `/customer/device-type/${deviceId}`
        );
        this.setState(state => ({
          ...state,
          settingItems: this.getEmptyingSetting(
            response.deviceType
          ),
          disableSetting: false,
        }));
      }
      catch (exception) {
        this.setState(state => ({
          ...state,
          fetchingDeviceTypeError: exception.body.error,
          disableSetting: true
        }));
      }
      finally {
        this.setState(state => ({
          ...state,
          isFetchingDeviceType: false,
        }));
        this.formDispatch(actions.reset('device.deviceConfig.emptyingTime'));
      }
    }
    else if (!this.state.disableSetting) {
      this.setState(state => ({
        ...state,
        settingItems: this.getEmptyingSetting(),
        disableSetting: true,
        isFetchingDeviceType: false,
      }));
      this.formDispatch(actions.reset('device.deviceConfig.emptyingTime'));
    }
  }

  /**
   * "Asset name" (`assetName`) and "Functional location" (`application`) are
   * `readOnly` fields so those should not be sent as part of the request body
   * to the backend.
   */
  handleSubmit({
    deviceId: productId,
    assetTag,
    location,
    deviceConfig: { cartridgeSize, emptyingTime, lubricant },
  }) {
    return this.props.onSubmit({
      productId,
      assetTag,
      location,
      deviceConfig: {
        cartridgeSize: Number(cartridgeSize),
        emptyingTime: Number(emptyingTime),
        lubricant,
      },
    });
  }

  render() {
    const assetTagItems = this.props.assets.map(asset => ({
      value: asset.assetTag,
      label: asset.assetTag,
      disabled: !asset.locations || asset.locations.length === 0,
    }));
    const lubricantItems = this.props.lubricants.map(lubricant => ({
      value: lubricant.denomination,
      label: `${lubricant.manufacturerName}: ${lubricant.denomination}`,
    }));

    return (
      <PromptForm
        model="device"
        onSubmit={values => {
          this.handleSubmit(values);
        }}
        getDispatch={dispatch => {
          this.formDispatch = dispatch;
        }}
        initialState={this.props.initialState}
        validators={this.validationSchema}
      >
        {isSubmitting => (
          <>
            <Grid>
              <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
                <Heading as="h3">
                  <FormattedMessage defaultMessage="Asset" id="WKCp0D" />
                </Heading>
              </GridItem>
              <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
                <ControlSelectField
                  feLabel={this.props.intl.formatMessage({
                    defaultMessage: 'Tag',
                    id: '18HJlm',
                  })}
                  model=".assetTag"
                  id="assetTag"
                  items={assetTagItems}
                  onChange={assetTag => {
                    this.handleAssetTagChange(assetTag);
                  }}
                  required
                />
              </GridItem>
              <GridItem feColspan={{ desktop: 6, tablet: 12, mobile: 12 }}>
                <ControlTextField
                  feLabel={this.props.intl.formatMessage({
                    defaultMessage: 'Name',
                    id: 'HAlOn1',
                  })}
                  id="assetName"
                  model=".assetName"
                  disabled
                  title={this.props.intl.formatMessage({
                    defaultMessage:
                      'Asset name cannot be changed via device add or edit.',
                    id: 'yBej/Y',
                  })}
                  required
                />
              </GridItem>
              <GridItem feColspan={{ desktop: 6, tablet: 12, mobile: 12 }}>
                <ControlTextField
                  feLabel={this.props.intl.formatMessage({
                    defaultMessage: 'Functional location',
                    id: 'lOOR5y',
                  })}
                  id="application"
                  model=".application"
                  disabled
                  title={this.props.intl.formatMessage({
                    defaultMessage:
                      'Functional location cannot be changed via device add or edit.',
                    id: 'eqf0SL',
                  })}
                  required
                />
              </GridItem>
              <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
                <ControlSelectField
                  feLabel={this.props.intl.formatMessage({
                    defaultMessage: 'Lubrication point',
                    id: '5ri2Ns',
                  })}
                  model=".location"
                  id="location"
                  items={this.state.assetLocationItems}
                  required
                />
              </GridItem>
              <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
                <Heading as="h3">
                  <FormattedMessage defaultMessage="Device" id="c3VJQo" />
                </Heading>
              </GridItem>
              <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
                <ControlTextField
                  feLabel={this.props.intl.formatMessage({
                    defaultMessage: 'Device ID',
                    id: 'f9m4vq',
                  })}
                  id="deviceId"
                  model=".deviceId"
                  hint={this.props.intl.formatMessage({
                    defaultMessage:
                      'You can find the Device ID printed on the lubricator.',
                    id: '3RuI/D',
                  })}
                  placeholder="61GC72"
                  disabled={
                    this.props.disabledFields
                    && this.props.disabledFields.includes('deviceId')
                  }
                  title={
                    this.props.disabledFields
                    && this.props.disabledFields.includes('deviceId')
                      ? this.props.intl.formatMessage({
                        defaultMessage:
                            'Device ID cannot be changed after creation.',
                        id: 'vO9r1M',
                      })
                      : ''
                  }
                  required
                  onBlur={event => this.handleDeviceIdBlur(event)}
                />
                {this.state.isFetchingDeviceType && <Loader feSize="sm" />}
                {this.state.fetchingDeviceTypeError && (
                  <Text feColor={Colors.RedBase}>
                    <FormattedMessage
                      defaultMessage="Unable to find the device, please make sure that the device ID is correct. If the error persists, contact our customer support."
                      id="eiifX6"
                    />
                  </Text>
                )}
                <ControlError
                  model=".deviceId"
                  messages={{
                    isRequired: (
                      <ControlValidationError validator="isRequired" />
                    ),
                    isValidExample: value => (
                      <ControlValidationError
                        validator="isValidExample"
                        values={{
                          value,
                          thing: (
                            <FormattedMessage defaultMessage="ID" id="qlcuNQ" />
                          ),
                          example: '61GC72',
                        }}
                      />
                    ),
                    ...(this.props.formType === 'add' && {
                      isAlreadyAdded: value => (
                        <ControlValidationError
                          validator="isAlreadyAdded"
                          values={{ value }}
                        />
                      ),
                    }),
                  }}
                />
              </GridItem>
              <GridItem feColspan={{ desktop: 4, tablet: 12, mobile: 12 }}>
                <ControlSelectField
                  feLabel={this.props.intl.formatMessage({
                    defaultMessage: 'Cartridge size',
                    id: 'yHfuIB',
                  })}
                  hint={this.props.intl.formatMessage({
                    defaultMessage:
                      'Numbers presented are in millileters (ml).',
                    id: 'oibBgk',
                  })}
                  model=".deviceConfig.cartridgeSize"
                  id="deviceConfig.cartridgeSize"
                  items={cartridgeItems}
                  required
                  disabled={this.props.formType !== 'add'}
                />
                <ControlError
                  model=".deviceConfig.cartridgeSize"
                  messages={{
                    isRequired: (
                      <ControlValidationError validator="isRequired" />
                    ),
                  }}
                />
              </GridItem>
              <GridItem feColspan={{ desktop: 4, tablet: 12, mobile: 12 }}>
                <ControlSelectField
                  feLabel={this.props.intl.formatMessage({
                    defaultMessage: 'Lubricant',
                    id: 'xzBmxi',
                  })}
                  model=".deviceConfig.lubricant"
                  id="deviceConfig.lubricant"
                  items={lubricantItems}
                  required
                />
                <ControlError
                  model=".deviceConfig.lubricant"
                  messages={{
                    isRequired: (
                      <ControlValidationError validator="isRequired" />
                    ),
                  }}
                />
              </GridItem>
              <GridItem
                feColspan={{ desktop: 4, tablet: 12, mobile: 12 }}
                title={
                  this.state.disableSetting
                  && this.props.intl.formatMessage({
                    defaultMessage:
                      'You need to set a device id before changing the setting.',
                    id: 'GWlIit',
                  })
                }
              >
                <ControlSelectField
                  feLabel={`${this.props.intl.formatMessage({
                    defaultMessage: 'Dispense setting',
                    id: 'MGWzwA',
                  })}`}
                  hint={this.props.intl.formatMessage({
                    defaultMessage:
                      'Set a device off or the dispense frequency.',
                    id: 'hm5gca',
                  })}
                  model=".deviceConfig.emptyingTime"
                  id="deviceConfig.emptyingTime"
                  items={this.state.settingItems}
                  disabled={this.state.disableSetting}
                  required
                />
                <ControlError
                  model=".deviceConfig.emptyingTime"
                  messages={{
                    isRequired: (
                      <ControlValidationError validator="isRequired" />
                    ),
                  }}
                />
              </GridItem>
              <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
                <Flexbox feJustifyContent="flex-end" feGap={Spacings.Md}>
                  {this.props.cancel && (
                    <Button
                      type="button"
                      feType="secondary"
                      onClick={this.props.cancel.onClick}
                    >
                      {this.props.cancel.text}
                    </Button>
                  )}
                  <ControlButton
                    model="device"
                    id="submit"
                    type="submit"
                    disabled={isSubmitting || this.state.disableSetting || { valid: false }}
                  >
                    {this.props.submitBtnText}
                  </ControlButton>
                </Flexbox>
              </GridItem>
            </Grid>
          </>
        )}
      </PromptForm>
    );
  }
}

FormDevice.defaultProps = {
  submitBtnText: (
    <FormattedMessage
      defaultMessage="Add {thing}"
      id="SziwLc"
      values={{
        thing: <FormattedMessage defaultMessage="device" id="eY2JfQ" />,
      }}
    />
  ),
  formType: 'add',
};

FormDevice.propTypes = {
  formType: FormType,
  initialState: PropTypes.shape({
    assetTag: PropTypes.string,
    assetName: PropTypes.string,
    location: PropTypes.string,
    application: PropTypes.string,
    deviceConfig: PropTypes.shape({
      emptyingTime: PropTypes.number,
      cartridgeSize: PropTypes.number,
      lubricant: PropTypes.string,
    }),
    deviceId: PropTypes.string,
    deviceType: PropTypes.string,
  }).isRequired,
  intl: IntlShape,
  disabledFields: PropTypes.arrayOf(PropTypes.string),
  assets: PropTypes.arrayOf(AssetShape),
  devices: PropTypes.arrayOf(DeviceShape),
  lubricants: PropTypes.arrayOf(LubricantShape),
  onSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  submitBtnText: PropTypes.element,
};

export default injectIntl(FormDevice);
