import {
  Button, Flexbox, Grid, GridItem, Spacer, Spacings, Tag
} from '@skf-internal/ui-components-react-legacy';
import Label from '@skf-internal/ui-components-react-legacy/dist/cjs/common/components/label/Label';
import ControlButton from 'components/ControlButton';
import ControlError from 'components/ControlError';
import ControlInputHidden from 'components/ControlInputHidden';
import ControlInputText from 'components/ControlInputText';
import ControlTextField from 'components/ControlTextField';
import ControlValidationError from 'components/ControlValidationError';
import PromptForm from 'components/PromptForm';
import { AssetShape, FormType, IntlShape } from 'helpers/propTypes';
import { isEqual, without } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { actions, LocalForm } from 'react-redux-form';
import { array, string } from 'yup';

const MIN_LENGTH_ASSET_TAG = 3;
const MIN_LENGTH_ASSET_NAME = 3;
const MIN_LENGTH_APPLICATION = 3;
const MIN_LENGTH_LOCATION = 2;
const MAX_LENGTH_LOCATION = 47;
const MIN_LENGTH_LOCATIONS = 1;
const LOCATION_REGEX = /^[^,]+$/;

/**
 * FormAsset component
 *
 * Needs to be a `React.Component` because `react-redux-form` does
 * not support binding form dispatch events on functional components.
 */
class FormAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: props.initialState.locations,
    };

    this.locationsValidationSchema = {
      // Field-level validators
      newLocation: {
        isNotShortEnough: value => string().max(MAX_LENGTH_LOCATION).isValidSync(value),
        isNotLongEnough: value => string().min(MIN_LENGTH_LOCATION).isValidSync(value),
        isNotValidExample: value => string().matches(LOCATION_REGEX).isValidSync(value),
      }
    };

    this.validationSchema = {
      // Field-level validators
      assetTag: {
        isRequired: value => string().required().isValidSync(value),
        isNotLongEnough: value => string().min(MIN_LENGTH_ASSET_TAG).isValidSync(value),
        ...(props.formType === 'add' && {
          isAlreadyAdded: value => !props.assets.some(asset => asset.assetTag === value)
        })
      },
      assetName: {
        isRequired: value => string().required().isValidSync(value),
        isNotLongEnough: value => string().min(MIN_LENGTH_ASSET_NAME).isValidSync(value),
      },
      application: {
        isRequired: value => string().required().isValidSync(value),
        isNotLongEnough: value => string().min(MIN_LENGTH_APPLICATION).isValidSync(value),
      },
      locations: {
        isRequired: value => array().required().isValidSync(value),
        isNotLongEnough: value => array().min(MIN_LENGTH_LOCATIONS).isValidSync(value),
      },
      '': {
        // Form-level validator
        hasChanges: values => {
          return Object.keys(values).some(key => !isEqual(values[key], props.initialState[key]));
        },
      }
    };
  }

  handleLubricationPointAdd(values) {
    if (values.newLocation && !this.state.locations.includes(values.newLocation)) {
      // Reset add form
      this.addLocationsFormDispatch(actions.reset('locations'));

      // Update the state
      this.setState(prevState => ({
        locations: [...prevState.locations, values.newLocation],
      }), () => {
        // Append added lubrication point to asset form
        this.assetFormDispatch(actions.change('asset.locations', this.state.locations));
      });
    }
    return false;
  }

  handleLubricationPointRemove(location) {
    // Update the state
    this.setState(prevState => ({
      ...prevState,
      locations: without(prevState.locations, location)
    }), () => {
      // Remove the lubrication point from the form
      this.assetFormDispatch(actions.change('asset.locations', this.state.locations));
    });
  }

  handleSubmit(values) {
    return this.props.onSubmit(values);
  }

  /**
   * Bind `LocalForm` dispatch to `FormAsset` component
   *
   * @param {object} dispatch
   */
  attachAddLocationsFormDispatch(dispatch) {
    this.addLocationsFormDispatch = dispatch;
  }

  /**
   * Bind `LocalForm` dispatch to `FormAsset` component
   *
   * @param {object} dispatch
   */
  attachFormAssetDispatch(dispatch) {
    this.assetFormDispatch = dispatch;
  }

  render() {
    return (
      <>
        <Label feRequired htmlFor="location">
          <FormattedMessage defaultMessage="Lubrication points" id="2zeqaC" />
        </Label>
        {this.state.locations.length > 0 && <Spacer feSpacing={Spacings.Sm} />}
        <Flexbox feGap={Spacings.Sm} feFlexWrap="wrap">
          {this.state.locations.map(location => {
            const disabled = this.props.locationsInUse.has(location);

            return (
              <Tag
                key={`lubrication_point-${location}`}
                feRemoveButton={{
                  onClick: () => this.handleLubricationPointRemove(location)
                }}
                feDisabled={disabled}
                title={disabled ? (
                  this.props.intl.formatMessage({
                    defaultMessage: 'Cannot delete a lubrication point that is in use by one or more devices.',
                    id: 'nVUmJM'
                  })
                ) : ''}
              >
                {location}
              </Tag>
            );
          })}
        </Flexbox>
        <Spacer feSpacing={Spacings.Sm} />
        <LocalForm
          model="locations"
          onSubmit={values => this.handleLubricationPointAdd(values)}
          getDispatch={formDispatch => this.attachAddLocationsFormDispatch(formDispatch)}
          initialState={{ newLocation: '' }}
          validators={this.locationsValidationSchema}
        >
          <Flexbox feFlexDirection="column" style={{ width: '100%' }}>

            <Flexbox feAlignItems="flex-end" feFlexWrap="nowrap" feGap={Spacings.Sm}>
              <Flexbox feFlexDirection="column" style={{ width: '100%' }}>
                <ControlInputText
                  id="location"
                  label={<FormattedMessage defaultMessage="Lubrication points" id="2zeqaC" />}
                  hideLabel
                  model=".newLocation"
                  hint={this.props.intl.formatMessage({
                    defaultMessage: 'Enter a Lubrication point that identifies a part of an asset.',
                    id: 'lZaax1'
                  })}
                  placeholder="Main Bearing"
                />
              </Flexbox>

              <div style={{ alignSelf: 'start' }}>
                <ControlButton model="locations" id="submit" type="submit" feType="secondary" disabled={{ valid: false }}>
                  <FormattedMessage defaultMessage="Add" id="2/2yg+" />
                </ControlButton>

              </div>

            </Flexbox>
            <Spacer feSpacing={Spacings.Sm} />
            <ControlError
              model=".newLocation"
              messages={{
                isNotShortEnough: () => (
                  <ControlValidationError
                    validator="isNotShortEnough"
                    values={{ field: 'Lubrication point', length: MAX_LENGTH_LOCATION }}
                  />
                ),
                isNotLongEnough: () => (
                  <ControlValidationError
                    validator="isNotLongEnough"
                    values={{ field: 'Lubrication point', length: MIN_LENGTH_LOCATION }}
                  />
                ),
                isNotValidExample: value => (
                  <ControlValidationError
                    validator="isNotValidExample"
                    values={{
                      value,
                      thing: (
                        <FormattedMessage defaultMessage="Lubrication point" id="5ri2Ns" />
                      ),
                      example: <FormattedMessage defaultMessage="ABC (no commas)" id="CNW6xG" />
                    }}
                  />
                )
              }}
              show={field => field.touched && !field.focus && this.state.locations.length === 0}
            />
          </Flexbox>

        </LocalForm>
        <Spacer feSpacing={Spacings.Xl} />
        <PromptForm
          model="asset"
          onSubmit={values => this.handleSubmit(values)}
          getDispatch={formDispatch => this.attachFormAssetDispatch(formDispatch)}
          initialState={this.props.initialState}
          validators={this.validationSchema}
        >
          {isSubmitting => (
            <>
              <ControlInputHidden model=".locations" id="locations" name="locations[]" />
              <Grid>
                <GridItem feColspan={{ desktop: 6, tablet: 12, mobile: 12 }}>
                  <ControlTextField
                    feLabel={this.props.intl.formatMessage({
                      defaultMessage: 'Tag',
                      id: '18HJlm'
                    })}
                    id="assetTag"
                    model=".assetTag"
                    placeholder="LS-01"
                    hint={this.props.intl.formatMessage({
                      defaultMessage: 'Enter a Tag to identify an asset',
                      id: 'fKbkp1'
                    })}
                    disabled={this.props.disabledFields && this.props.disabledFields.includes('assetTag')}
                    title={
                  this.props.disabledFields
                  && this.props.disabledFields.includes('assetTag')
                    ? this.props.intl.formatMessage({
                      defaultMessage: 'Asset tag cannot be changed after creation.',
                      id: '6An0aK'
                    })
                    : ''
                }
                    required
                  />
                  <ControlError
                    model=".assetTag"
                    messages={{
                      isRequired: <ControlValidationError validator="isRequired" />,
                      isNotLongEnough: () => (
                        <ControlValidationError
                          validator="isNotLongEnough"
                          values={{ field: 'Asset tag', length: MIN_LENGTH_ASSET_TAG }}
                        />
                      ),
                      ...(this.props.formType === 'add' && {
                        isAlreadyAdded: value => (
                          <ControlValidationError
                            validator="isAlreadyAdded"
                            values={{ value }}
                          />
                        ),
                      })
                    }}
                  />
                </GridItem>
                <GridItem feColspan={{ desktop: 6, tablet: 12, mobile: 12 }}>
                  <ControlTextField
                    feLabel={this.props.intl.formatMessage({
                      defaultMessage: 'Name',
                      id: 'HAlOn1'
                    })}
                    id="assetName"
                    model=".assetName"
                    placeholder="Lubrication System 1"
                    hint={this.props.intl.formatMessage({
                      defaultMessage: 'Enter a Name that describes the asset',
                      id: '5XwsOe'
                    })}
                    required
                  />
                  <ControlError
                    model=".assetName"
                    messages={{
                      isRequired: <ControlValidationError validator="isRequired" />,
                      isNotLongEnough: () => (
                        <ControlValidationError
                          validator="isNotLongEnough"
                          values={{ field: 'Asset name', length: MIN_LENGTH_ASSET_NAME }}
                        />
                      ),
                    }}
                  />
                </GridItem>
                <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
                  <ControlTextField
                    feLabel={this.props.intl.formatMessage({
                      defaultMessage: 'Functional location',
                      id: 'lOOR5y'
                    })}
                    id="application"
                    model=".application"
                    placeholder="Stockholm Windfarm 2"
                    hint={this.props.intl.formatMessage({
                      defaultMessage: 'Enter a Functional location that describes where the asset exists physically',
                      id: 'ffb/tm'
                    })}
                    required
                  />
                  <ControlError
                    model=".application"
                    messages={{
                      isRequired: <ControlValidationError validator="isRequired" />,
                      isNotLongEnough: () => (
                        <ControlValidationError
                          validator="isNotLongEnough"
                          values={{ field: 'Functional location', length: MIN_LENGTH_APPLICATION }}
                        />
                      ),
                    }}
                  />
                </GridItem>
                <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
                  <Flexbox feJustifyContent="flex-end" feGap={Spacings.Md}>
                    {this.props.cancel && (
                    <Button type="button" feType="secondary" onClick={this.props.cancel.onClick}>
                      {this.props.cancel.text}
                    </Button>
                    )}
                    <ControlButton model="asset" id="submit" type="submit" disabled={isSubmitting || { valid: false }}>
                      {this.props.submitBtnText}
                    </ControlButton>
                  </Flexbox>
                </GridItem>
              </Grid>
            </>
          )}
        </PromptForm>
      </>
    );
  }
}

FormAsset.defaultProps = {
  locationsInUse: new Set(),
  initialState: {
    application: undefined,
    assetTag: undefined,
    assetName: undefined,
    locations: [],
  },
  submitBtnText: (
    <FormattedMessage
      defaultMessage="Add {thing}"
      id="SziwLc"
      values={{ thing: <FormattedMessage defaultMessage="asset" id="kqj91u" /> }}
    />
  ),
  formType: 'add',
};

FormAsset.propTypes = {
  formType: FormType,
  assets: PropTypes.arrayOf(AssetShape),
  locationsInUse: PropTypes.object,
  initialState: PropTypes.shape({
    application: PropTypes.string,
    assetTag: PropTypes.string,
    assetName: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.string),
  }),
  disabledFields: PropTypes.arrayOf(PropTypes.string),
  cancel: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  onSubmit: PropTypes.func.isRequired,
  submitBtnText: PropTypes.element,
  intl: IntlShape
};

export default injectIntl(FormAsset);
