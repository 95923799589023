import { DEMO_MODE } from './constants';

/**
 * Emptying time settings generation according to a number of month
 */
// eslint-disable-next-line import/prefer-default-export
export const emptySettings = (nbMonths = 12) => [
  {
    value: 0,
    intl: {
      id: 'OvzONl',
      defaultMessage: 'Off',
    },
  },
  {
    value: 1,
    intl: {
      id: 'TBovrx',
      defaultMessage: 'Month',
    },
  },
  ...[...Array(nbMonths - 1)].map((_, i) => ({
    value: i + 2,
    intl: {
      id: 'AxDOiG',
      defaultMessage: 'Months',
    }
  })),
  {
    value: DEMO_MODE,
    intl: {
      id: 'TPv/Zv',
      defaultMessage: 'Demo',
    }
  },
];
