import React from 'react';
import {
  Redirect, Route, Switch as RouterSwitch
} from 'react-router-dom';

import TabsLubricantsAndManufacturers from 'components/TabsLubricantsAndManufacturers';
import AddLubricantView from 'containers/AddLubricantView';
import AddLubricantManufacturerView from 'containers/AddLubricantManufacturerView';
import LubricantManufacturerDetailsView from 'containers/LubricantManufacturerDetailsView';
import { ROUTE_DASHBOARD_LUBRICANTS_AND_MANUFACTURERS, ROUTE_DASHBOARD_LUBRICANTS, ROUTE_DASHBOARD_MANUFACTURERS } from 'helpers/constants';

const DashboardLubricantsAndLubricantManufacturersView = () => {
  return (
    <RouterSwitch>
      <Route exact path={ROUTE_DASHBOARD_LUBRICANTS_AND_MANUFACTURERS}>
        <Redirect to={ROUTE_DASHBOARD_LUBRICANTS} />
      </Route>
      <Route exact path={`${ROUTE_DASHBOARD_LUBRICANTS}/add-lubricant`} component={AddLubricantView} />
      <Route exact path={`${ROUTE_DASHBOARD_MANUFACTURERS}/add-lubricant-manufacturer`} component={AddLubricantManufacturerView} />
      <Route exact path={`${ROUTE_DASHBOARD_MANUFACTURERS}/:manufacturerName/add-lubricant`} component={AddLubricantView} />
      <Route exact path={`${ROUTE_DASHBOARD_MANUFACTURERS}/:manufacturerName`} component={LubricantManufacturerDetailsView} />
      <Route path={ROUTE_DASHBOARD_LUBRICANTS_AND_MANUFACTURERS}>
        <TabsLubricantsAndManufacturers />
      </Route>
    </RouterSwitch>
  );
};

export default DashboardLubricantsAndLubricantManufacturersView;
