import React from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { IntlShape } from 'helpers/propTypes';
import TemplatePersistingNotification from 'components/TemplatePersistingNotification';
import {
  Flexbox, Link, Spacer, Text
} from '@skf-internal/ui-components-react-legacy';

const LCPUpdateNotification = ({ intl }) => {
  const text = intl.formatMessage({
    defaultMessage: 'We are excited to announce that we will be soon releasing a new version of our connected lubricator platform, '
    + 'which will enhance and improve your experience with your SKF TLDD products. '
    + 'The new platform is called SKF Lubrication Cloud and will offer a sleek and intuitive design, '
    + 'improved performance and security.',
    id: 'wXuHl5'
  });
  const link = intl.formatMessage({
    defaultMessage: 'For more information please visit: {siteLink}', id: 'p+J0dq',
  }, {
    siteLink: (
      <Link as="a" href="https://www.skf.com/group/products/lubrication-management/lubrication-cloud" style={{ display: 'box' }}>
        https://www.skf.com/
      </Link>
    ),
  });
  return (
    <TemplatePersistingNotification feType="notification">
      <Flexbox style={{ flexDirection: 'column' }}>
        {text}
        <Spacer />
        <Text>
          {link}
        </Text>
      </Flexbox>
    </TemplatePersistingNotification>
  );
};

LCPUpdateNotification.propTypes = {
  intl: IntlShape
};

export default compose(injectIntl)(LCPUpdateNotification);
