import upperFirst from 'lodash/upperFirst';
import lowerCase from 'lodash/lowerCase';

/**
 * Turn any object into a HTML `<option>` object
 * See: https://www.w3schools.com/tags/tag_option.asp
 *
 * @param {object} obj
 * @param {string} valueKey
 * @param {string} labelKey
 */
export const asOptionItem = (obj, valueKey, labelKey) => ({ value: obj[valueKey], label: obj[labelKey] });

/**
 * Turn any object into a HTML `<option>` object and capitalize the label
 * See: https://www.w3schools.com/tags/tag_option.asp
 *
 * @param {object} obj
 * @param {string} valueKey
 * @param {string} labelKey
 */
export const asCapitalizedOptionItem = (obj, valueKey, labelKey) => {
  if (valueKey === labelKey) {
    return asOptionItem({
      [valueKey]: obj[valueKey],
      [`${valueKey}.label`]: upperFirst(lowerCase(obj[labelKey])),
    }, valueKey, `${valueKey}.label`);
  }

  return asOptionItem({ [valueKey]: obj[valueKey], [labelKey]: upperFirst(lowerCase(obj[labelKey])) }, valueKey, labelKey);
};
