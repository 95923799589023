import { Tabs } from '@skf-internal/ui-components-react-legacy';
import DashboardAssetsAndDevicesView from 'containers/DashboardAssetsAndDevicesView';
import DashboardGatewaysView from 'containers/DashboardGatewaysView';
import DashboardLubricantsAndLubricantManufacturersView from 'containers/DashboardLubricantsAndLubricantManufacturersView';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

const tabRouteMap = [
  '/dashboard/assets-and-devices',
  '/dashboard/gateways',
  '/dashboard/lubricants-and-manufacturers',
];

const TabsDashboard = () => {
  const history = useHistory();
  const location = useLocation();
  const activeTab = tabRouteMap.findIndex((_, index) => location.pathname.includes(tabRouteMap[index]));
  const intl = useIntl();

  const handleTabClick = (_e, index) => {
    history.push(tabRouteMap[index]);
  };

  return (
    <Tabs
      feSelected={activeTab}
      feItems={[
        {
          children: <DashboardAssetsAndDevicesView />,
          label: intl.formatMessage({
            defaultMessage: 'Assets and devices', id: '/IuulJ',
          }),
        },
        {
          children: <DashboardGatewaysView />,
          label: intl.formatMessage({
            defaultMessage: 'Gateways', id: 'jtdQHR',
          }),
        },
        {
          children: <DashboardLubricantsAndLubricantManufacturersView />,
          label: intl.formatMessage({
            defaultMessage: 'Lubricants', id: 'Z6afvc',
          }),
        },
      ]}
      onClick={handleTabClick}
      feType="expanded"
      feEnclosed
    />
  );
};

export default TabsDashboard;
