const LOAD_META = 'datalake/metadata/LOAD_META';
const LOAD_META_SUCCESS = 'datalake/metadata/LOAD_META_SUCCESS';
const LOAD_META_FAIL = 'datalake/metadata/LOAD_META_FAIL';

const initialState = {
  metaFields: {},
};

export default function metadata(state = initialState, action = {}) {
  let metaFields;

  switch (action.type) {
    case LOAD_META:
      return {
        ...state,
        loading: true,
      };
    case LOAD_META_SUCCESS:
      metaFields = state.metaFields;
      metaFields[action.result.domain] = action.result.metadata;

      return {
        ...state,
        loading: false,
        loaded: true,
        metaFields,
      };
    case LOAD_META_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    default:
      return state;
  }
}

export function loadMetaDomain(domain) {
  return {
    types: [LOAD_META, LOAD_META_SUCCESS, LOAD_META_FAIL],
    promise: (client, _dispatch, getState) => {
      const { intl: { language } } = getState();
      return client.get(`/metadata/domains/${domain}?lang=${language}`, { isAccessToken: false })
        .then(res => ({
          metadata: res,
          domain,
        }));
    },
    fallback: client => {
      return client.get(`/metadata/domains/${domain}?lang=en`, { isAccessToken: false })
        .then(res => ({
          metadata: res,
          domain,
        }));
    },
  };
}
