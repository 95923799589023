import {
  Table,
  Text
} from '@skf-internal/ui-components-react-legacy';
import SLoader from 'components/SLoader';
import { intAlarmDescription, intlLastDataReceivedAt } from 'helpers/intlMethods';
import { DeviceHistoryShape } from 'helpers/propTypes';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { alarmNumberToAlertLevel } from '../../helpers/ValueMapper';
import IconStatus from '../IconStatus';

const TableDeviceHistory = ({
  history, loading
}) => {
  const renderTableDeviceHistoryBody = () => {
    const intl = useIntl();
    return history.map(({
      alarm, date
    }) => {
      const pingLastReceivedAtStr = intlLastDataReceivedAt(intl, moment, date);
      const details = intAlarmDescription(intl, alarm);
      const state = alarmNumberToAlertLevel(alarm);
      return (
        {
          cells: [
            {
              children: (
                <Text level={details} title={`${details} (${alarm})`}>
                  <IconStatus level={state} />
                  &nbsp;
                  &nbsp;
                  {details}
                </Text>
              )
            },
            {
              children: pingLastReceivedAtStr,
            },
          ],
        }
      );
    }).reverse();
  };

  const deviceHistoryTableHead = [
    {
      cells: [
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Status" id="tzMNF3" />,
          scope: 'col',
        },
        {
          as: 'th',
          children: <FormattedMessage defaultMessage="Time" id="ug01Mk" />,
          scope: 'col',
        }
      ]
    }
  ];

  if (loading) {
    return <SLoader />;
  }

  if (!history || history.length === 0) {
    return (
      <Text>
        <FormattedMessage defaultMessage="No {thing} or failed to load {thing}." id="EemuAN" values={{ thing: <FormattedMessage defaultMessage="history" id="UQWuJy" /> }} />
      </Text>
    );
  }

  return (
    <Table
      caption="Device history"
      feCompact={true}
      feBody={renderTableDeviceHistoryBody()}
      feHead={deviceHistoryTableHead}
      feType="static"
    />
  );
};

TableDeviceHistory.propTypes = {
  loading: PropTypes.bool,
  history: PropTypes.arrayOf(DeviceHistoryShape),
};

export default TableDeviceHistory;
