import React from 'react';
import { FormattedMessage } from 'react-intl';

import TemplateDialogContent from 'components/TemplateDialogContent';
import { AssetShape } from 'helpers/propTypes';

const DialogContentDeleteAsset = ({ asset }) => {
  return (
    <TemplateDialogContent
      content={[
        {
          subheading: (
            <FormattedMessage
              defaultMessage="{thing} details"
              values={{ thing: <FormattedMessage defaultMessage="Assets" id="d1uESJ" /> }}
              id="2Q8Pzz"
            />
          ),
          items: [
            {
              id: 'tag',
              children: (
                <FormattedMessage
                  defaultMessage="Tag: {tag}"
                  id="lUuJVX"
                  values={{ tag: asset.assetTag }}
                />
              )
            },
            {
              id: 'name',
              children: (
                <FormattedMessage
                  defaultMessage="Name: {name}"
                  id="sNtwjA"
                  values={{ name: asset.assetName }}
                />
              ),
            },
          ]
        }
      ]}
    />
  );
};

DialogContentDeleteAsset.defaultProps = {
  asset: {
    assetTag: '--',
    assetName: '--',
  }
};

DialogContentDeleteAsset.propTypes = {
  asset: AssetShape
};

export default DialogContentDeleteAsset;
