import React from 'react';
import PropTypes from 'prop-types';
import NotificationSystem from 'react-notification-system-redux';
import { Colors, FontSizes, Spacings } from '@skf-internal/ui-components-react-legacy';

const styles = {
  NotificationItem: { // Override the notification item base styles
    DefaultStyle: { // Applied to every notification, regardless of the notification level
      color: Colors.Black,
      marginTop: Spacings.Md,
      borderRadius: 0,
      fontSize: FontSizes.Sm,
    },
    success: { // Applied only to the success notification items
      backgroundColor: 'rgb(205 231 217)',
      border: '1px solid rgb(101 181 138)',
    },
    error: { // Applied only to the error notification items
      backgroundColor: 'rgb(255 242 229)',
      border: '1px solid rgb(254 182 110)',
    },
    warning: { // Applied only to the warning notification items
      backgroundColor: 'rgb(255 242 229)',
      border: '1px solid rgb(254 182 110)',
    },
    info: { // Applied only to the info notification items
      backgroundColor: 'rgb(231 238 250)',
      border: '1px solid rgb(52 107 219)',
    },
  },
  Title: {
    DefaultStyle: {
      fontSize: FontSizes.Md,
      margin: `0 0 ${Spacings.Sm} 0`,
      padding: 0,
    },
    success: { // Applied only to the success notification items
      color: Colors.Black,
    },
    error: { // Applied only to the error notification items
      color: Colors.Black,
    },
    warning: { // Applied only to the warning notification items
      color: Colors.Black,
    },
    info: { // Applied only to the info notification items
      color: Colors.Black,
    }
  },
};

const Notifications = ({ notifications }) => {
  return (
    <NotificationSystem notifications={notifications} style={styles} />
  );
};

Notifications.propTypes = {
  notifications: PropTypes.array,
};

export default Notifications;
