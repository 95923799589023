import {
  Heading, Spacer, Spacings
} from '@skf-internal/ui-components-react-legacy';
import FormGateway from 'components/FormGateway';
import SLoader from 'components/SLoader';
import TemplateContent from 'components/TemplateContent';
import { GatewayShape } from 'helpers/propTypes';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as gatewayActions from 'redux/modules/gateway';

function mapState(state) {
  const {
    gateway: {
      gateways,
      loading,
      loaded,
    }
  } = state;

  return {
    gateways,
    loading,
    loaded,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(gatewayActions, dispatch),
    dispatch
  };
}

const AddGatewayView = ({
  actions, gateways, loading, loaded
}) => {
  useEffect(() => {
    if (!loaded) {
      actions.loadGateways();
    }
  }, []);

  const handleSubmit = values => {
    return actions.createGateway(values);
  };

  if (loading) {
    return (
      <SLoader />
    );
  }

  return (
    <TemplateContent
      heading={(
        <Heading as="h2">
          <FormattedMessage
            defaultMessage="Add {thing}"
            id="SziwLc"
            values={{ thing: <FormattedMessage defaultMessage="gateway" id="MbLp8E" /> }}
          />
        </Heading>
      )}
      linkBack={{
        to: '/dashboard/gateways'
      }}
    >
      <Spacer feSpacing={Spacings.Xxl} />
      <FormGateway
        onSubmit={handleSubmit}
        gateways={gateways}
      />
    </TemplateContent>
  );
};

AddGatewayView.propTypes = {
  actions: PropTypes.shape({
    loadGateways: PropTypes.func,
    createGateway: PropTypes.func,
  }),
  gateways: PropTypes.arrayOf(GatewayShape),
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
};

export default connect(
  mapState,
  mapDispatch
)(AddGatewayView);
