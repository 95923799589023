import { createIntl, createIntlCache } from '@formatjs/intl';

import { getCurrentLanguageCode, setCurrentLanguageCode } from 'storage/localStorage';
import {
  LOCALES, LANGUAGE_CODES, DEFAULT_LANGUAGE_CODE
} from 'helpers/constants';

const LOAD = 'myrina/intl/LOAD';
const LOAD_SUCCESS = 'myrina/intl/LOAD_SUCCESS';
const LOAD_FAIL = 'myrina/intl/LOAD_FAIL';

const SET_INTL_INSTANCE = 'myrina/intl/SET_INTL_INSTANCE';

const getDefaultLanguage = () => {
  // read local storage for language preference
  let languageCode = getCurrentLanguageCode();

  if (!languageCode) {
    // check that browser is set to a supported locale
    languageCode = LANGUAGE_CODES[navigator.language];

    if (!languageCode) {
      // any locales we have no translations for should use default
      languageCode = DEFAULT_LANGUAGE_CODE;
    }

    // update local storage
    setCurrentLanguageCode(languageCode);
  }

  return languageCode;
};

/**
 * Get locale from language code
 *
 * @returns locale name such as `en`
 */
const getLocaleFromLanguageCode = (languageCode = LANGUAGE_CODES[navigator.language] || DEFAULT_LANGUAGE_CODE) => {
  return LOCALES[languageCode];
};

const initialState = {
  loaded: false,
  loading: true,
  locale: getLocaleFromLanguageCode(),
  language: getDefaultLanguage(),
  translations: {},
  instance: {},
};

export default function intl(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS: {
      const { locale, language, translations } = action.result;
      return {
        ...state,
        loading: false,
        loaded: true,
        locale,
        language,
        translations
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case SET_INTL_INSTANCE: {
      return {
        ...state,
        instance: action.instance,
      };
    }
    default:
      return state;
  }
}

const cache = createIntlCache();

/**
 * Create instance using the Imperative API
 *
 * @returns IntlShape
 */
export function setInstance(locale, translations) {
  const instance = createIntl(
    {
      locale,
      messages: translations,
    },
    cache
  );

  return {
    type: SET_INTL_INSTANCE,
    instance,
  };
}

export function loadTranslations(language) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client, dispatch) => {
      return client.get(`/translations/${language}.json`, { local: true })
        .then(translations => {
          const locale = LOCALES[language];

          dispatch(setInstance(locale, translations));

          return {
            translations,
            locale,
            language,
          };
        });
    }
  };
}
