import {
  Icon,
  IconColors,
  Icons,
  Text
} from '@skf-internal/ui-components-react-legacy';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const IconConnection = ({
  rssi,
  lsnr,
  min
}) => {
  const intl = useIntl();

  if (typeof rssi !== 'number') {
    // No connection
    return (
      <Text title={intl.formatMessage({ defaultMessage: 'No connection', id: 'NP4I0A' })}>
        <FormattedMessage defaultMessage="--" id="thiOfy" />
      </Text>
    );
  }

  if (typeof lsnr !== 'number') {
    return getInnacurateConnectionIcon(rssi, min, intl);
  }
  return getAccurateConnectionIcon({ rssi, lsnr }, min, intl);
};

/**
 * This method uses an old logic that attempts to assume the connection strength
 * only using the RSSI. This gives inaccurate assumption on the signal strength
 * and can be confusing.
 *
 * Only added to accomodate for older data that only contains RSSI value.
 */
const getInnacurateConnectionIcon = (rssi, min, intl) => {
  if (rssi >= min.excellent.signalStrength) {
    return (
      <Text title={intl.formatMessage({ defaultMessage: 'Excellent Signal', id: 'e/CGxB' })}>
        <Icon feIcon={Icons.Connection4} feColor={IconColors.Green} />
      </Text>
    );
  }
  if (rssi >= min.good.signalStrength) {
    return (
      <Text title={intl.formatMessage({ defaultMessage: 'Good Signal', id: 'lRkBCJ' })}>
        <Icon feIcon={Icons.Connection3} feColor={IconColors.Green} />
      </Text>
    );
  }
  if (rssi >= min.fair.signalStrength) {
    return (
      <Text title={intl.formatMessage({ defaultMessage: 'Fair Signal', id: 'gXjSEj' })}>
        <Icon feIcon={Icons.Connection2} feColor={IconColors.Yellow} />
      </Text>
    );
  }
  return (
    <Text title={intl.formatMessage({ defaultMessage: 'Weak Signal', id: 'XQiCSD' })}>
      <Icon feIcon={Icons.Connection1} feColor={IconColors.Yellow} />
    </Text>
  );
};

/**
 * This function will give a proper representation of the connection using both RSSI and LSNR.
 * It outputs either good, fair or weak icons depending on the values and thresholds.
 * Fair signal is used as default representation if the values do not fall in any of the thresholds.
 * @param {object} { rssi, lsnr } signal information of LORA network
 * @param {object} min minimum thresholds for signal. Only good and weak thresholds are needed
 * @returns Icon for the connection strength
 */
const getAccurateConnectionIcon = ({ rssi, lsnr }, min, intl) => {
  if (rssi >= min.excellent.rssi && lsnr >= min.excellent.lsnr) {
    return (
      <Text title={intl.formatMessage({ defaultMessage: 'Excellent Signal', id: 'e/CGxB' })}>
        <Icon feIcon={Icons.Connection4} feColor={IconColors.Green} />
      </Text>
    );
  }
  if (rssi >= min.good.rssi && lsnr >= min.good.lsnr) {
    return (
      <Text title={intl.formatMessage({ defaultMessage: 'Good Signal', id: 'lRkBCJ' })}>
        <Icon feIcon={Icons.Connection3} feColor={IconColors.Green} />
      </Text>
    );
  }
  if (rssi <= min.weak.rssi || lsnr <= min.weak.lsnr) {
    return (
      <Text title={intl.formatMessage({ defaultMessage: 'Weak Signal', id: 'XQiCSD' })}>
        <Icon feIcon={Icons.Connection1} feColor={IconColors.Yellow} />
      </Text>
    );
  }
  return (
    <Text title={intl.formatMessage({ defaultMessage: 'Fair Signal', id: 'gXjSEj' })}>
      <Icon feIcon={Icons.Connection2} feColor={IconColors.Yellow} />
    </Text>
  );
};

const SIGNAL_THRESHOLDS_SHAPE = {
  signalStrength: PropTypes.number,
  rssi: PropTypes.number,
  lsnr: PropTypes.number
};

IconConnection.propTypes = {
  rssi: PropTypes.number,
  lsnr: PropTypes.number,
  min: PropTypes.shape({
    excellent: PropTypes.shape(SIGNAL_THRESHOLDS_SHAPE),
    good: PropTypes.shape(SIGNAL_THRESHOLDS_SHAPE),
    fair: PropTypes.shape(SIGNAL_THRESHOLDS_SHAPE),
    weak: PropTypes.shape(SIGNAL_THRESHOLDS_SHAPE)
  })
};

export default IconConnection;
