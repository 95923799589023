/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';

import { Control } from 'react-redux-form';

const ControlInputHidden = ({
  model, name, id, required
}) => {
  return (
    <Control.custom
      model={model}
      id={id}
      required={required}
      hidden
      controlProps={{
        name,
      }}
    />
  );
};

ControlInputHidden.propTypes = {
  model: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool
};

export default ControlInputHidden;
