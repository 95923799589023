import { createStore as _createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import * as Sentry from '@sentry/react';
import clientMiddleware from './middleware/clientMiddleware';
import storageMiddleware from './middleware/storageMiddleware';
import authMiddleware from './middleware/authMiddleware';
import reducer from './modules/reducer';

export default function createStore(client, type = 'user') {
  // Sync dispatched route actions to the history
  const reduxRouterMiddleware = routerMiddleware(/* history */);
  const middleware = [
    authMiddleware(type),
    clientMiddleware(client),
    storageMiddleware(),
    reduxRouterMiddleware,
    thunk
  ];

  // Use default compose
  let composeEnhancers = compose;

  // For development environment
  if (process.env.NODE_ENV !== 'production') {
    // Use redux devtools if available
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    // Only add logger if redux devtools is not available
    if (!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      middleware.push(logger);
    }
  }

  const finalCreateStore = composeEnhancers(
    applyMiddleware(...middleware),
    Sentry.createReduxEnhancer({})
  )(_createStore);

  const store = finalCreateStore(reducer);

  if (module.hot) {
    module.hot.accept('./modules/reducer', () => {
      store.replaceReducer(require('./modules/reducer')); // eslint-disable-line
    });
  }

  client.setStore(store);

  return store;
}
