import {
  success as successNotification,
  error as errorNotification,
  warning as warningNotification,
  info as infoNotification,
} from 'react-notification-system-redux';

const baseOptions = {
  position: 'tr',
  dismissible: 'click',
  autoDismiss: 5,
};

export const success = message => successNotification({
  ...baseOptions,
  title: 'Success!',
  message,
  level: 'success',
});

export const error = message => errorNotification({
  ...baseOptions,
  title: 'Error!',
  message,
  level: 'error',
});

export const warning = message => warningNotification({
  ...baseOptions,
  title: 'Warning',
  message,
  level: 'warning',
});

export const info = message => infoNotification({
  ...baseOptions,
  title: 'Info',
  message,
  level: 'info',
});
