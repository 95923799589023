import { useMemo } from 'react';

const useGatewayWithStatusData = gateways => {
  // Add data to devices and status information
  const gatewayStatusCount = useMemo(() => {
    // Map to GATEWAY_STATUSES: [OFFLINE, ONLINE]
    const gatewayStatusCountNew = [0, 0, 0, 0, 0];

    gateways.forEach(gateway => {
      gatewayStatusCountNew[gateway.status] += 1;
      return gatewayStatusCountNew;
    });

    return gatewayStatusCountNew;
  }, [gateways]);

  return gatewayStatusCount;
};

export default useGatewayWithStatusData;
