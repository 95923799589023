import {
  Heading, Spacer, Spacings, Text
} from '@skf-internal/ui-components-react-legacy';
import FormLubricant from 'components/FormLubricant';
import SLoader from 'components/SLoader';
import TemplateContent from 'components/TemplateContent';
import { LubricantManufacturerShape, LubricantShape } from 'helpers/propTypes';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, useParams, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as lubricantActions from 'redux/modules/lubricant';

function mapState(state) {
  const {
    lubricant: {
      lubricants: { items: lubricants, loading: loadingLubricants, loaded: loadedLubricants }, manufacturers: { items: manufacturers, loading: loadingManufacturers, loaded: loadedManufacturers }
    },
  } = state;

  return {
    lubricants,
    loadingLubricants,
    loadedLubricants,
    manufacturers,
    loadingManufacturers,
    loadedManufacturers,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(lubricantActions, dispatch),
    dispatch
  };
}

const AddLubricantView = ({
  actions, lubricants, manufacturers, loadingLubricants, loadedLubricants, loadingManufacturers, loadedManufacturers
}) => {
  const { manufacturerName } = useParams();

  useEffect(() => {
    if (!loadedManufacturers) {
      actions.loadLubricantManufacturers();
    }
    if (!loadedLubricants) {
      actions.loadLubricants();
    }
  }, []);

  const manufacturerItems = useMemo(() => {
    return manufacturers.map(({ name }) => ({
      value: name,
      label: name,
    }));
  }, [manufacturers]);

  const handleSubmit = values => {
    if (lubricants.some(lubricant => lubricant.denomination === values.denomination)) {
      // TODO: Display error notification that user needs to use some other name
      return undefined;
    }
    return actions.createLubricant(values);
  };

  if (loadingManufacturers || loadingLubricants) {
    return <SLoader />;
  }

  if (manufacturerItems.length === 0) {
    return (
      <TemplateContent
        heading={(
          <Heading as="h2">
            <FormattedMessage
              defaultMessage="Add {thing}"
              id="SziwLc"
              values={{ thing: <FormattedMessage defaultMessage="lubricant" id="muUpZ3" /> }}
            />
          </Heading>
        )}
        linkBack={{
          to: '/dashboard/lubricants-and-manufacturers/lubricants'
        }}
      >
        <Spacer feSpacing={Spacings.Xxl} />
        <Text>
          <FormattedMessage
            defaultMessage="You need to {link} before you can add a lubricant."
            id="Xmhraj"
            values={{ link: <Link to="/dashboard/lubricants-and-manufacturers/lubricant-manufacturers/add-lubricant-manufacturer"><FormattedMessage defaultMessage="add a lubricant manufacturer" id="VKRxu2" /></Link> }}
          />
        </Text>
      </TemplateContent>
    );
  }

  const initialState = {
    manufacturerName: manufacturerName ? decodeURIComponent(manufacturerName) : manufacturerItems[0].value,
    lubricantType: 'grease',
    denomination: '',
  };

  return (
    <TemplateContent
      heading={(
        <Heading as="h2">
          <FormattedMessage
            defaultMessage="Add {thing}"
            id="SziwLc"
            values={{ thing: <FormattedMessage defaultMessage="lubricant" id="muUpZ3" /> }}
          />
        </Heading>
      )}
      linkBack={{
        to: '/dashboard/lubricants-and-manufacturers/lubricants'
      }}
    >
      <Spacer feSpacing={Spacings.Xxl} />
      <FormLubricant
        initialState={initialState}
        onSubmit={handleSubmit}
        manufacturerItems={manufacturerItems}
        lubricants={lubricants}
      />
    </TemplateContent>
  );
};

AddLubricantView.propTypes = {
  actions: PropTypes.shape({
    loadLubricants: PropTypes.func,
    loadLubricantManufacturers: PropTypes.func,
    createLubricant: PropTypes.func,
  }),
  lubricants: PropTypes.arrayOf(LubricantShape),
  loadingLubricants: PropTypes.bool,
  loadedLubricants: PropTypes.bool,
  manufacturers: PropTypes.arrayOf(LubricantManufacturerShape),
  loadingManufacturers: PropTypes.bool,
  loadedManufacturers: PropTypes.bool,
};

export default withRouter(connect(mapState, mapDispatch)(AddLubricantView));
