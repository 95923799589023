import {
  Flexbox, Grid, GridItem, Spacings
} from '@skf-internal/ui-components-react-legacy';
import ControlButton from 'components/ControlButton';
import ControlError from 'components/ControlError';
import ControlSelectField from 'components/ControlSelectField';
import ControlTextField from 'components/ControlTextField';
import ControlValidationError from 'components/ControlValidationError';
import PromptForm from 'components/PromptForm';
import { lubricantTypeItems, LUBRICANT_TYPES } from 'helpers/constants';
import { intlLubricantType } from 'helpers/intlMethods';
import { ControlSelectFieldItemShape, FormType, LubricantShape } from 'helpers/propTypes';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { string } from 'yup';

const MIN_LENGTH_DENOMINATION = 3;
const MAX_LENGTH_DESCRIPTION = 200;

const FormLubricant = ({
  formType, initialState, manufacturerItems, lubricants, onSubmit, submitBtnText
}) => {
  const intl = useIntl();
  const validationSchema = {
    manufacturerName: {
      isRequired: value => string().required().isValidSync(value),
    },
    lubricantType: {
      isRequired: value => string().required().isValidSync(value),
    },
    denomination: {
      isRequired: value => string().required().isValidSync(value),
      isNotLongEnough: value => string().min(MIN_LENGTH_DENOMINATION).isValidSync(value),
      ...(formType === 'add' && {
        isAlreadyAdded: value => !lubricants.some(lubricant => lubricant.denomination === value)
      })
    },
    description: {
      isNotShortEnough: value => string().max(MAX_LENGTH_DESCRIPTION).isValidSync(value)
    }
  };

  const lubricantTypeItemsIntl = lubricantTypeItems ? lubricantTypeItems.map(type => ({
    value: type.value,
    label: intlLubricantType(intl, type.value),
  })) : null;

  return (
    <PromptForm
      model="lubricant"
      initialState={initialState}
      onSubmit={onSubmit}
      validators={validationSchema}
    >
      {isSubmitting => (
        <>
          <Grid>
            <GridItem feColspan={{ desktop: 6, tablet: 6, mobile: 12 }}>
              <ControlSelectField
                feLabel={intl.formatMessage({
                  defaultMessage: 'Manufacturer',
                  id: 'VI/+Qx'
                })}
                model=".manufacturerName"
                id="manufacturerName"
                items={manufacturerItems}
                required
              />
              <ControlError
                model=".manufacturerName"
                messages={{
                  isRequired: <ControlValidationError validator="isRequired" />,
                }}
              />
            </GridItem>
            <GridItem feColspan={{ desktop: 6, tablet: 6, mobile: 12 }}>
              <ControlSelectField
                feLabel={intl.formatMessage({
                  defaultMessage: 'Type',
                  id: '+U6ozc'
                })}
                model=".lubricantType"
                id="lubricantType"
                items={lubricantTypeItemsIntl}
                required
              />
              <ControlError
                model=".lubricantType"
                messages={{
                  isRequired: <ControlValidationError validator="isRequired" />,
                }}
              />
            </GridItem>
            <GridItem feColspan={{ desktop: 6, tablet: 12, mobile: 12 }}>
              <ControlTextField
                feLabel={intl.formatMessage({
                  defaultMessage: 'Name',
                  id: 'HAlOn1'
                })}
                model=".denomination"
                id="denomination"
                placeholder="My custom lubricant"
                hint={intl.formatMessage({
                  defaultMessage: 'Enter a lubricant name',
                  id: 'RU8TeK',
                })}
                required
              />
              <ControlError
                model=".denomination"
                messages={{
                  isRequired: <ControlValidationError validator="isRequired" />,
                  isNotLongEnough: () => (
                    <ControlValidationError
                      validator="isNotLongEnough"
                      values={{ field: 'Name', length: MIN_LENGTH_DENOMINATION }}
                    />
                  ),
                  ...(formType === 'add' && {
                    isAlreadyAdded: value => (
                      <ControlValidationError
                        validator="isAlreadyAdded"
                        values={{ value }}
                      />
                    ),
                  })
                }}
              />
            </GridItem>
            <GridItem feColspan={{ desktop: 6, tablet: 12, mobile: 12 }}>
              <ControlTextField
                feLabel={intl.formatMessage({
                  defaultMessage: 'Description',
                  id: 'Q8Qw5B'
                })}
                model=".description"
                id="description"
                placeholder="Lubricant description"
                hint={intl.formatMessage({
                  defaultMessage: 'Enter a description',
                  id: 'QAVYIG',
                })}
                required
              />
              <ControlError
                model=".description"
                messages={{
                  isNotShortEnough: () => (
                    <ControlValidationError
                      validator="isNotShortEnough"
                      values={{ field: 'Description', length: MAX_LENGTH_DESCRIPTION }}
                    />
                  )
                }}
              />
            </GridItem>
            <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}>
              <Flexbox feJustifyContent="flex-end" feGap={Spacings.Md}>
                <ControlButton model="lubricant" id="submit" type="submit" disabled={isSubmitting || { valid: false }}>
                  {submitBtnText}
                </ControlButton>
              </Flexbox>
            </GridItem>
          </Grid>
        </>
      )}
    </PromptForm>
  );
};

FormLubricant.defaultProps = {
  initialState: {
    manufacturerName: '',
    lubricantType: LUBRICANT_TYPES.Grease,
    denomination: '',
  },
  submitBtnText: (
    <FormattedMessage
      defaultMessage="Add {thing}"
      id="SziwLc"
      values={{ thing: <FormattedMessage defaultMessage="lubricant" id="muUpZ3" /> }}
    />
  ),
  formType: 'add',
};

FormLubricant.propTypes = {
  formType: FormType,
  initialState: PropTypes.shape({
    manufacturerName: PropTypes.string,
    lubricantType: PropTypes.oneOf(lubricantTypeItems.map(lt => lt.value)),
    denomination: PropTypes.string,
  }),
  manufacturerItems: PropTypes.arrayOf(ControlSelectFieldItemShape).isRequired,
  lubricants: PropTypes.arrayOf(LubricantShape).isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitBtnText: PropTypes.element
};

export default FormLubricant;
