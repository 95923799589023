import {
  Card,
  Heading
} from '@skf-internal/ui-components-react-legacy';
import FormInviteUsers from 'components/FormInviteUsers';
import SLoader from 'components/SLoader';
import TemplateContent from 'components/TemplateContent';
import TemplateSection from 'components/TemplateSection';
import { OrganizationUserShape } from 'helpers/propTypes';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from 'redux/modules/auth';
import * as organizationActions from 'redux/modules/organization';

function mapState(state) {
  const {
    organization: { users: { data, loading, loaded } },
  } = state;

  return {
    loading,
    loaded,
    users: data,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(
      { ...authActions, ...organizationActions },
      dispatch
    ),
  };
}

const InviteUserView = ({
  actions, users, loading, loaded
}) => {
  useEffect(() => {
    if (!loaded) {
      actions.loadOrganizationUsers();
    }
  }, []);

  const handleSubmit = values => {
    if (users.find(user => user.email === values.email)) {
      return undefined;
    }

    return actions.invite(values);
  };

  if (loading) {
    return <SLoader />;
  }

  return (
    <Card>
      <TemplateContent
        heading={(
          <Heading as="h2">
            <FormattedMessage
              defaultMessage="Invite user"
              id="/4GN+O"
            />
          </Heading>
        )}
        linkBack={{
          to: '/organization'
        }}
      >
        <TemplateSection>
          <FormInviteUsers emails={users.map(user => user.email)} onSubmit={handleSubmit} />
        </TemplateSection>
      </TemplateContent>
    </Card>
  );
};

InviteUserView.propTypes = {
  actions: PropTypes.shape({
    invite: PropTypes.func,
    loadOrganizationUsers: PropTypes.func,
  }),
  users: PropTypes.arrayOf(OrganizationUserShape),
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
};

export default connect(mapState, mapDispatch)(InviteUserView);
