import SLoader from 'components/SLoader';
import { AuthShape } from 'helpers/propTypes';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as authActions from 'redux/modules/auth';

function mapState({ auth }) {
  return {
    auth
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}

const AuthHandlerView = ({ actions, auth, history }) => {
  useEffect(() => {
    if (!auth.loaded) {
      actions.getAccount();
    }
  }, []);

  useEffect(() => {
    if (!auth.loading) {
      if (auth.email) {
        history.push('/dashboard');
      }
      else {
        window.location.href = `${process.env.REACT_APP_SSO_URL}/logout?app=${process.env.REACT_APP_SSO_APPKEY}`;
      }
    }
  }, [auth.loading]);

  return (
    <SLoader />
  );
};

AuthHandlerView.propTypes = {
  actions: PropTypes.shape({
    getAccount: PropTypes.func,
  }),
  auth: AuthShape,
  history: PropTypes.object.isRequired,
};

export default withRouter(connect(mapState, mapDispatch)(AuthHandlerView));
